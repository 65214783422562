import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import screenfull from '@/components/my-screen';
import { debounce } from '@/utils/debounce';
import { queryTurn, startLottery, stopLottery } from '@/api/lottery';
import editFormatMixin from '@/views/editFormat/editFormatMixin';
import lotteryMixin from '@/views/tool/lotteryMixin';
import { mapGetters } from "vuex";
export default {
  name: 'StartLottery',
  components: {
    screenfull: screenfull
  },
  mixins: [editFormatMixin, lotteryMixin],
  data: function data() {
    return {
      // 定时器
      timeId: null,
      // 摇号 规则
      rand: {},
      // 摇号列表
      lottery: [],
      // 根据个数改变样式
      heightClass: 'height10',
      // 轮次信息
      list: {},
      // 摇号按钮
      startButton: false,
      // 下一轮摇号
      is_next: false,
      // 判断本组是否有下一轮
      is_rand_next: false,
      // 剩余摇号人数
      laveNumber: 0,
      //  第轮次
      roundNumber: 0,
      // 最后一组了，没有组了
      is_group: true,
      styleObject: {},
      //  防止多次点击停止
      stopLotteryButton: false,
      is_stay_user: 0,
      classOptionC: {
        step: 0.2,
        // 数值越大速度滚动越快
        limitMoveNum: 3,
        // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false,
        // 是否开启鼠标悬停stop
        direction: 1,
        // 0向下 1向上 2向左 3向右
        openWatch: true,
        // 开启数据实时监控刷新dom
        singleHeight: 0,
        // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0,
        // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000,
        // 单步运动停止的时间(默认值1000ms)
        autoPlay: true // 自动播放
      },
      isAllEnd: false //停止摇号时 赋值 摇号是否全部摇完了
    };
  },
  computed: _objectSpread({}, mapGetters(['roles'])),
  // 初始化
  created: function created() {
    this.initStartLottery();
  },
  mounted: function mounted() {
    // 绑定enter事件
    this.enterKeyup();
  },
  destroyed: function destroyed() {
    // 销毁enter事件
    this.enterKeyupDestroyed();
  },
  watch: {
    // 监听轮次变化，刷新副屏轮次数据
    roundNumber: function roundNumber(newData, oldData) {
      if (this.rand.is_sub_screen === 1) {
        var round_nums = [];
        for (var i = 0; i < this.editFormatList.sub_screenDatas.length; i++) {
          for (var a = 0; a < this.editFormatList.sub_screenDatas[i].rounds.length; a++) {
            if (round_nums.indexOf(newData - this.editFormatList.sub_screenDatas[i].rounds[a]) === -1) {
              round_nums.push(newData - this.editFormatList.sub_screenDatas[i].rounds[a]);
            }
          }
        }
        if (round_nums.length) this.doGetRound(round_nums, newData);
      }
    }
  },
  methods: {
    // 继承& 不继承
    getNumberAdd: function getNumberAdd(index) {
      var indexVal = 0;
      if (this.rand.type_round_code === 1) {
        indexVal = (this.roundNumber - 1) * this.rand.rand_count + index;
      } else {
        indexVal = index;
      }
      if (this.rand.type_group_code === 1) {
        indexVal += this.list.max_code;
      }
      return indexVal;
    },
    // 身份证
    getCard: function getCard(card, show_card) {
      // 隐藏身份证中间
      if (show_card === 1) {
        if (card.length > 15) {
          return card.replace(/^(.{4})(?:\d+)(.{4})$/, '$1******$2');
        } else {
          return card.replace(/^(.{1})(?:\d+)(.{1})$/, '$1******$2');
        }
      }
      // 只显示最后六个号码
      else {
        return card.substring(card.length - 6);
      }
    },
    nickName: function nickName() {
      var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var names = str.split(';');
      var newName = '';
      names.forEach(function (name) {
        if (!!name) {
          if (name.length < 3) {
            newName = newName + name[0] + '*';
          }
          if (name.length > 2) {
            newName = newName + name[0] + new Array(name.length - 2).fill('*').join('') + name[name.length - 1];
          }
          if (names.length > 1) newName = newName + ';';
        }
      });
      return newName;
    },
    //  手机正则
    getTel: function getTel(tel) {
      if (tel != null) {
        var reg = /^(\d{3})\d{4}(\d{4})$/;
        return tel.replace(reg, '$1****$2');
      }
    },
    // 取消摇号
    handleCancel: function handleCancel() {
      // 关闭定时器
      window.clearInterval(this.timeId);
      // 页面数据 又变成开始摇号
      this.lottery.forEach(function (item) {
        item.name = '****';
        item.code = '****';
        item.tel = '****';
        item.card = '****';
      });

      //   取消过后又变成开始摇号
      this.startButton = false;
    },
    // 上一轮 跳转历史页面
    handleLastRound: function handleLastRound() {
      // 获取 url 数据
      var query = JSON.parse(JSON.stringify(this.$route.query));
      var data = {
        id: query.id,
        group_id: query.group_id,
        round: this.list.this_round
      };
      this.$router.push({
        name: 'StartLotteryHistory',
        query: data
      });
    },
    // 点击 下一轮摇号
    handleNextRound: debounce(function () {
      var _this = this;
      if (this.getTimeOk && this.getTimeOk > new Date().getTime() - 1000) {
        return false;
      }
      var query = JSON.parse(JSON.stringify(this.$route.query));

      // 查看批次
      queryTurn({
        id: query.id,
        group_id: query.group_id,
        round: query.round
      }).then(function (res) {
        if (res.status === 200) {
          _this.list = res.data.list;
          // 本组是否结束
          _this.is_rand_next = res.data.list.end;
          if (_this.is_rand_next) {
            _this.$confirm('本组摇号已结束！', '提醒', {
              confirmButtonText: _this.suText,
              cancelButtonText: '返回首页',
              closeOnClickModal: false,
              closeOnPressEscape: false,
              showClose: false,
              type: 'warning'
            }).then(function () {
              // if (this.list.not_user_count === 0 && this.list.end) {
              //   this.nextStartLottery()
              // } else {
              //   if (!this.list.all_end) {
              //     this.sealStartLottery()
              //   }
              // }
              if (_this.isAllEnd) {
                //是否全部摇号完毕 全部摇号完毕页面不做任何修改
                return;
              }
              _this.sealStartLottery();
            }).catch(function () {
              _this.$router.push({
                name: 'Lottery',
                query: {
                  id: _this.$route.query.id
                }
              });
            });
          } else {
            // 点击下一轮摇号
            _this.nextStartLottery();
          }
        }
      });
    }, 200, true),
    // 开始接口
    sealStartLottery: function sealStartLottery(type) {
      var _this2 = this;
      // 获取 url 数据
      var query = JSON.parse(JSON.stringify(this.$route.query));
      var data = {
        id: query.id,
        group_id: query.group_id,
        round: this.list.max_round,
        type: type
      };

      // 判断是否上一次完成
      if (this.lotterySetTimeout) {
        return;
      }
      var loading;
      // 超过800ms就显示遮罩层
      this.lotterySetTimeout = setTimeout(function () {
        loading = _this2.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }, 800);
      startLottery(data).then(function (res) {
        var _res$data = res.data,
          list = _res$data.list,
          _res$data$list = _res$data.list,
          rand = _res$data$list.rand,
          not_user_list = _res$data$list.not_user_list,
          not_rand_user_list = _res$data$list.not_rand_user_list;
        // 摇号规则
        _this2.rand = rand;
        _this2.list = list;
        _this2.laveNumber = list.not_user_count;
        _this2.is_stay_user = rand.is_stay_user;
        // 判断是不是最后一轮
        if (list.group_id === 0) {
          _this2.$message({
            type: 'success',
            message: '最后一组了，全部摇完了'
          });
          _this2.is_group = false;
          return;
        } else {
          // 改变参数 分组id
          query.group_id = list.group_id;
        }

        // 改变参数 轮次
        if (!list.end) {
          query.round = list.max_round + 1;
          _this2.roundNumber = list.max_round + 1;
        }
        _this2.$router.push({
          path: _this2.$route.path,
          query: query
        }); // 更新路由

        // 组装页面摇号 个数
        // if (list.not_user_count > 0) {

        _this2.lottery.forEach(function (item) {
          item.name = '****';
          item.code = '****';
          item.tel = '****';
          item.card = '****';
        });
        if (rand.rand_count - _this2.lottery.length > 0) {
          var number = rand.rand_count - _this2.lottery.length;
          for (var i = 0; i < number; i++) {
            _this2.lottery.push({
              name: '****',
              code: '****',
              tel: '****',
              card: '****'
            });
          }
        }

        // 页面个数 高度
        if (rand.rand_count > 10) {
          _this2.h = 100;
        } else {
          _this2.h = 200;
        }

        // 组装未摇号数据
        if (not_rand_user_list !== null) {
          var nameArray = [];
          var telArray = [];
          var codeArray = [];
          var cardArray = [];
          not_rand_user_list.forEach(function (item) {
            nameArray.push(item.name);
            telArray.push(item.tel);
            codeArray.push(item.code);
            cardArray.push(item.card);
          });
          _this2.nameArray = nameArray;
          _this2.telArray = telArray;
          _this2.codeArray = codeArray;
          _this2.cardArray = cardArray;
        }

        // 下一轮 数据
        _this2.is_next = false;
        // }
      }).finally(function (res) {
        var _loading;
        clearTimeout(_this2.lotterySetTimeout);
        _this2.lotterySetTimeout = null;
        (_loading = loading) === null || _loading === void 0 || _loading.close();
      });
    },
    // 把下轮开始摇号封装起来
    nextStartLottery: function nextStartLottery() {
      var _this3 = this;
      // 有预设失败的
      if (this.list.is_preinstall) {
        this.$alert('摇号人数异常无法继续摇号，请修复异常。', '提示', {
          confirmButtonText: '好的',
          callback: function callback(action) {}
        });
        return;
      }
      var query = JSON.parse(JSON.stringify(this.$route.query));
      var data = {
        id: query.id,
        group_id: query.group_id,
        round: this.list.max_round === 0 ? this.list.max_round + 1 : this.list.max_round
      };
      queryTurn(data).then(function (res) {
        var _res$data2 = res.data,
          list = _res$data2.list,
          _res$data2$list = _res$data2.list,
          rand = _res$data2$list.rand,
          not_user_list = _res$data2$list.not_user_list;
        if (list.end) {
          _this3.$confirm('本组摇号已结束！', '提醒', {
            confirmButtonText: _this3.suText,
            cancelButtonText: '返回首页',
            closeOnClickModal: false,
            closeOnPressEscape: false,
            showClose: false,
            type: 'warning'
          }).then(function () {
            if (_this3.isAllEnd) {
              //是否全部摇号完毕 全部摇号完毕页面不做任何修改
              return;
            }
            _this3.sealStartLottery();
          }).catch(function () {
            _this3.$router.push({
              name: 'Lottery',
              query: {
                id: _this3.$route.query.id
              }
            });
          });
          if (list.not_user_count === 0) {
            _this3.handleStopLottery();
          }
          return;
        }

        // 摇号规则
        _this3.rand = rand;
        _this3.list = list;
        _this3.laveNumber = list.not_user_count;
        _this3.is_stay_user = rand.is_stay_user;
        query.group_id = list.group_id; // 改变参数
        query.round = list.max_round + 1; // 改变参数
        // 获取当前轮次  加+1
        _this3.roundNumber = list.max_round + 1;
        _this3.$router.push({
          path: _this3.$route.path,
          query: query
        }); // 更新路由

        // 组装页面摇号 个数
        if (rand.rand_count > 0) {
          _this3.lottery.forEach(function (item) {
            item.name = '****';
            item.code = '****';
            item.tel = '****';
            item.card = '****';
          });

          // 页面个数 高度
          if (rand.rand_count > 10) {
            _this3.heightClass = 'height20';
          } else {
            _this3.heightClass = 'height10';
          }

          // 下一轮 数据
          _this3.is_next = false;
        }
      });
    },
    // 初始化 页面数据 查询轮次接口
    initStartLottery: function initStartLottery() {
      var _this4 = this;
      var query = JSON.parse(JSON.stringify(this.$route.query));
      var data = {
        id: query.id,
        group_id: query.group_id,
        round: query.round
      };

      // 查看批次
      queryTurn(data).then(function (res) {
        if (res.status === 200) {
          var _res$data3 = res.data,
            list = _res$data3.list,
            _res$data3$list = _res$data3.list,
            not_user_list = _res$data3$list.not_user_list,
            rand = _res$data3$list.rand,
            not_rand_user_list = _res$data3$list.not_rand_user_list;

          // 摇号规则
          _this4.rand = rand;
          _this4.styleObject = {
            background: "url(".concat(_this4.imgUrl + rand.logo, ")100%, no-repeat"),
            backgroundSize: 'cover',
            backgroundPosition: 'center, center',
            height: '100vh'
          };
          _this4.list = list;

          // 还要多少未摇号人数
          _this4.laveNumber = list.not_user_count;
          _this4.is_stay_user = rand.is_stay_user;
          // 放回页面上用的轮次
          _this4.roundNumber = list.max_round === 0 ? list.max_round + 1 : list.max_round;

          // 本组是否结束
          _this4.is_rand_next = list.end;

          // 改变参数
          query.round = list.max_round === 0 ? list.max_round + 1 : list.max_round;
          query.group_id = list.group_id;
          // 更新路由
          _this4.$router.push({
            path: _this4.$route.path,
            query: query
          });
          if (query.round === list.max_round) {
            _this4.is_next = true;
          } else {
            _this4.is_next = false;
          }
          if (list.all_end) {
            _this4.isAllEnd = true;
            _this4.is_group = false;
          } else {
            _this4.isAllEnd = false;
            _this4.is_group = true;
          }

          // 判断当前号 已摇
          if (list.max_round !== 0) {
            // 覆盖数据
            if (list.max_round_list.length < rand.rand_count) {
              _this4.lottery = list.max_round_list;
            } else {
              _this4.lottery = list.max_round_list;
            }
          } else {
            // 组装页面摇号 个数
            if (rand.rand_count > 0) {
              for (var i = 0; i < rand.rand_count; i++) {
                _this4.lottery.push({
                  name: '****',
                  code: '****',
                  tel: '****',
                  card: '****'
                });
              }
            }
          }

          // 组装未摇号数据
          if (not_rand_user_list !== null) {
            var nameArray = [];
            var telArray = [];
            var codeArray = [];
            var cardArray = [];
            not_rand_user_list.forEach(function (item) {
              nameArray.push(item.name);
              telArray.push(item.tel);
              codeArray.push(item.code);
              cardArray.push(item.card);
            });
            _this4.nameArray = nameArray;
            _this4.telArray = telArray;
            _this4.codeArray = codeArray;
            _this4.cardArray = cardArray;
          }

          // 页面个数 高度
          if (rand.rand_count > 10) {
            _this4.heightClass = 'height20';
          } else {
            _this4.heightClass = 'height10';
          }
        }
      });
    },
    // 点击开始摇号
    handleStartLottery: debounce(function () {
      var _this5 = this;
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      if (this.getTimeOk > new Date().getTime() - 1000) {
        return false;
      } else {
        this.getTimeOk = new Date().getTime();
      }
      // 有预设失败的
      if (this.list.is_preinstall) {
        this.$alert('摇号人数异常无法继续摇号，请修复异常。', '提示', {
          confirmButtonText: '好的',
          callback: function callback(action) {}
        });
        return;
      }
      if (this.list.not_user_count === 0 && this.list.end) {
        this.nextStartLottery();
        return;
      }

      // 关闭再开 避免多个定时器
      window.clearInterval(this.timeId);
      this.sealStartLottery(type);
      this.timeId = setInterval(function () {
        _this5.lottery.forEach(function (item) {
          item.name = _this5.nameArray[Math.floor(Math.random() * _this5.nameArray.length)];
          item.tel = _this5.telArray[Math.floor(Math.random() * _this5.telArray.length)];
          item.code = _this5.codeArray[Math.floor(Math.random() * _this5.codeArray.length)];
          item.card = _this5.cardArray[Math.floor(Math.random() * _this5.cardArray.length)];
        });
      }, 50);

      // 点击以后就显示结束按钮
      this.startButton = true;
    }, 200, true),
    // 点击结束摇号
    handleStopLottery: debounce(function () {
      var _this6 = this;
      if (this.getTimeOk > new Date().getTime() - 1000) {
        return false;
      } else {
        this.getTimeOk = new Date().getTime();
      }
      // 传递参数
      var data = {
        id: this.rand.id,
        group_id: this.list.group_id
      };
      //  防止多次点击停止
      this.stopLotteryButton = true;
      // 判断是否上一次完成
      if (this.lotterySetTimeout) {
        return;
      }
      var loading;
      // 超过800ms就显示遮罩层
      this.lotterySetTimeout = setTimeout(function () {
        loading = _this6.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }, 800);
      // 结束摇号放回结果 接口
      stopLottery(data).then(function (res) {
        if (res.status === 200) {
          var _res$data4 = res.data,
            list = _res$data4.list,
            newList = _res$data4.list.newList;

          //  点击停止摇号 获取剩余摇号人数 - 被摇号人数
          _this6.laveNumber = _this6.list.not_user_count - newList.length;

          //  当前组是否结束
          _this6.is_rand_next = list.end;

          // 所有 好全部摇完了
          if (list.all_end) {
            _this6.isAllEnd = true;
            _this6.suText = '确定';
          } else {
            _this6.isAllEnd = false;
            _this6.suText = '开始下一组摇号';
          }

          // 当 每页个数大于 摇号结果个数
          if (newList.length < _this6.rand.rand_count) {
            _this6.lottery = newList;
          } else {
            _this6.lottery = newList;
          }
          _this6.list = list;

          //	停止摇号打开下一轮摇号
          _this6.is_next = true;
          // 停止摇号过后 就不能显示取消按钮
          _this6.startButton = false;
          // 关闭定时器 停止摇号
          window.clearInterval(_this6.timeId);
          _this6.stopLotteryButton = false;
        }
      }).finally(function (res) {
        var _loading2;
        clearTimeout(_this6.lotterySetTimeout);
        _this6.lotterySetTimeout = null;
        (_loading2 = loading) === null || _loading2 === void 0 || _loading2.close();
      });
    }, 200, true),
    /**
     * 事件
     * @param event
     */
    enterKey: function enterKey(event) {
      // 获取当前路由名称
      // const componentName = this.$options.name;
      var code = event.keyCode ? event.keyCode : event.which ? event.which : event.charCode;
      // 回车事件
      if (+code === 13) {
        if (this.roles.includes("admin") || this.roles.includes("eventrand/randindex")) {
          // 下一轮摇号
          if (this.is_next) {
            this.handleNextRound();
          }
          // 停止摇号
          else if (this.startButton) {
            this.handleStopLottery();
          }
          // 开始摇号
          else {
            this.handleStartLottery(1);
          }
        }
      }
    },
    // 销毁监听事件
    enterKeyupDestroyed: function enterKeyupDestroyed() {
      document.removeEventListener("keyup", this.enterKey);
    },
    // 开始监听事件
    enterKeyup: function enterKeyup() {
      document.addEventListener("keyup", this.enterKey);
    }
  }
};