import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { asyncRoutes, constantRoutes } from '@/router';

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      if (tmp.meta && tmp.meta.isReturnRoles && tmp.meta.roles && roles) {
        tmp.meta.returnRoles = [];
        for (var l = 0; l < tmp.meta.roles.length; l++) {
          if (roles.includes(tmp.meta.roles[l])) tmp.meta.returnRoles.push(tmp.meta.roles[l]);
        }
      }
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes;
      if (roles.includes('admin')) {
        accessedRoutes = asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
      }
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};