//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { adminSetting } from '@/api/sincerityRegister';
export default {
  name: "linkSetup",
  props: {
    type: {
      type: Boolean,
      defalut: true
    },
    rule: {
      type: Object
    },
    audit: {
      type: Object
    },
    auditEventInfo: {
      type: Object,
      default: function _default() {
        return {
          "start_time": 0,
          "end_time": 0,
          "reg_time_show": 0,
          "zs_reg_time_show": 0,
          "reg_repeat_hour": 0,
          "reg_repeat_min": 0,
          // 报名交款
          "reg_price_start_time": 0,
          "reg_price_end_time": 0,
          "reg_price_time_show": 0,
          "zs_reg_price_time_show": 0,
          "reg_room_price": 0,
          "reg_car_price": 0,
          "reg_bus_price": 0,
          // 报名签约
          "reg_sign_start_time": 0,
          "reg_sign_end_time": 0,
          "reg_sign_time_show": 0,
          "zs_reg_sign_time_show": 0,
          "reg_is_sign_time": 0,
          "reg_sign_time_min": 0,
          // 认筹
          "ide_start_time": 161111,
          "ide_end_time": 545555,
          "ide_time_show": 0,
          "zs_ide_time_show": 0,
          "ide_repeat_hour": 0,
          "ide_repeat_min": 0,
          // 认筹交款
          "ide_price_start_time": 0,
          "ide_price_end_time": 0,
          "ide_price_time_show": 0,
          "zs_ide_price_time_show": 0,
          "ide_room_price": 0,
          "ide_car_price": 0,
          "ide_bus_price": 0,
          // 认筹签约
          "ide_sign_start_time": 0,
          "ide_sign_end_time": 0,
          "ide_sign_time_show": 0,
          "zs_ide_sign_time_show": 0,
          "ide_is_sign_time": 0,
          "ide_sign_time_min": 0,
          // 升筹
          "rai_start_time": 161111,
          "rai_end_time": 545555,
          "rai_time_show": 0,
          "zs_rai_time_show": 0,
          "rai_repeat_hour": 0,
          "rai_repeat_min": 0,
          // 升筹交款
          "rai_price_start_time": 0,
          "rai_price_end_time": 0,
          "rai_price_time_show": 0,
          "zs_rai_price_time_show": 0,
          "rai_room_price": 0,
          "rai_car_price": 0,
          "rai_bus_price": 0,
          // 升筹签约
          "rai_sign_start_time": 0,
          "rai_sign_end_time": 0,
          "rai_sign_time_show": 0,
          "zs_rai_sign_time_show": 0,
          "rai_is_sign_time": 0,
          "rai_sign_time_min": 0
        };
      }
    }
  },
  data: function data() {
    return {
      isM: false // 是手机 默认false
    };
  },
  mounted: function mounted() {},
  methods: {
    // saas 配置
    getSaasSetting: function getSaasSetting() {
      var _this = this;
      adminSetting().then(function (res) {
        _this.saasSetting = res.data;
        var _res$data = res.data,
          list = _res$data.list,
          audit = _res$data.audit;
        _this.audit = audit;
        _this.rule = list;
      });
    },
    // 验证
    handleEndTime: function handleEndTime(start, end) {
      return;
      if (this.auditEventInfo[start] > this.auditEventInfo[end]) {
        this.$message.warning('结束时间必须大于开始时间');
        this.auditEventInfo[end] = this.auditEventInfo[start] > new Date().getTime() ? this.auditEventInfo[start] + 1000 * 60 * 60 : new Date().getTime();
      }
    },
    // 环节表时间验证
    verify: function verify() {
      var flage = true;
      if (this.rule.is_identify) {
        //开启认筹环节认筹的填单开始时间，不允许早于报名的填单开始时间。
        if (this.auditEventInfo.ide_start_time < this.auditEventInfo.start_time) {
          flage = false;
          this.$message.warning('认筹的填单开始时间，不允许早于报名的填单开始时间');
        }
      } else if (this.rule.is_raising_funds && this.rule.is_identify) {
        if (this.auditEventInfo.rai_start_time < this.auditEventInfo.start_time) {
          this.$message.warning('升筹的填单开始时间，不允许早于报名的填单开始时间');
          flage = false;
        } else if (this.auditEventInfo.rai_start_time < this.auditEventInfo.ide_start_time) {
          this.$message.warning('升筹的填单开始时间，不允许早于认筹的填单开始时间');
          flage = false;
        }
      } else if (this.rule.is_raising_funds && !this.rule.is_identify) {
        if (this.auditEventInfo.rai_start_time < this.auditEventInfo.start_time) {
          this.$message.warning('升筹的填单开始时间，不允许早于报名的填单开始时间');
          flage = false;
        }
      }
      return flage;
    }
  }
};