import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { groupRoomVilla, roomVillaConfirm, roomVillaDelConfirm, RoomVillaRelation, villaEventExport } from '@/api/roomCar';
import mergeTable from './components/mergeTable';
import { debounce } from '@/utils/debounce';
import { mapGetters } from 'vuex';
import utils from "@/utils/utils";
export default {
  name: 'MergeBuilding',
  components: {
    mergeTable: mergeTable
  },
  data: function data() {
    return {
      tableData: [],
      dialogData: [],
      ids: []
    };
  },
  computed: _objectSpread({}, mapGetters(['customSetting'])),
  created: function created() {
    this.getTable();
  },
  methods: {
    // 清空关联
    handleReset: debounce(function (data) {
      var _this = this;
      roomVillaDelConfirm(data).then(function (res) {
        if (res.status === 200) {
          _this.$message({
            type: 'success',
            message: res.data.msg
          });
          _this.getTable();
        }
      });
    }, 200, true),
    // 保存
    handleSaveId: function handleSaveId(data) {
      var _this2 = this;
      var loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      roomVillaConfirm(data).then(function (res) {
        if (res.status === 200) {
          _this2.$message({
            type: 'success',
            message: res.data.msg
          });
          _this2.getTable();
          loading.close();
        }
      }).catch(function (err) {
        console.log(111);
        loading.close();
      });
    },
    // 全选&非全选
    handleItemCheck: function handleItemCheck(index) {
      var _this3 = this;
      // 存储 选中几个变量
      var num = 0;
      this.dialogData.group_room[index].rooms.forEach(function (item) {
        if (_this3.dialogData.group_room[index].is_check) {
          item.is_check = true;
          num = _this3.dialogData.group_room[index].rooms.length;
        } else {
          item.is_check = false;
          num = 0;
        }
      });

      // 赋值选中几个
      this.dialogData.group_room[index].check_room = num;
    },
    // 操作子列表选中
    handleInfoCheck: function handleInfoCheck(index) {
      // 存储 选中几个变量
      var num = 0;
      this.dialogData.group_room[index].rooms.forEach(function (item) {
        if (item.is_check) {
          num += 1;
        }
      });
      // 赋值选中几个
      this.dialogData.group_room[index].check_room = num;
      // 全部选中
      if (num === this.dialogData.group_room[index].rooms.length) {
        this.dialogData.group_room[index].is_check = true;
      } else {
        this.dialogData.group_room[index].is_check = false;
      }
    },
    // 导出房源
    handleExport: function handleExport() {
      var _this4 = this;
      //  loading
      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      // 导出接口
      villaEventExport().then(function (res) {
        if (res.status === 200) {
          _this4.$message({
            type: 'success',
            message: '导出成功'
          });

          // 下载
          // window.location.href = this.host + res.data.info
          utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
          loading.close();
        }
      });
    },
    // 房屋关联
    getDialogId: function getDialogId(data) {
      var _this5 = this;
      groupRoomVilla(data).then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          _this5.dialogData = list;
        }
      });
    },
    // 打开弹框调用接口
    handleMerge: function handleMerge(data) {
      var _this6 = this;
      groupRoomVilla(data).then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          list.leftLength = list.not_room_list == null ? 0 : list.not_room_list.length;
          list.rightLength = list.round_room_list == null ? 0 : list.round_room_list.length;
          _this6.dialogData = list;
        }
      });
    },
    // 获取数据
    getTable: function getTable() {
      var _this7 = this;
      RoomVillaRelation().then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          _this7.dealTable(list.event);
        }
      });
    },
    // 组装数据
    dealTable: function dealTable(tableData) {
      var getDate = []; // 存储新表格数据

      var firstIndex = 0; // 储存需要设置hover背景色的单元行(两层)

      tableData.forEach(function (item, index) {
        if (item.rounds && item.rounds.length) {
          item.rounds.forEach(function (subV, i, typeData) {
            var obj = {
              id: item.id,
              name: item.name,
              type: subV.name,
              index: getDate.length,
              num: index + 1,
              end_time: subV.end_time,
              start_time: subV.start_time,
              room_count: subV.room_count,
              event_id: subV.event_id,
              round_id: subV.id
            };
            if (i === 0) {
              obj.typeIndex = typeData.length;
              firstIndex = obj.index;
            } else {
              obj.firstIndex = firstIndex; // 存储第一行索引
            }
            getDate.push(obj);
          });
        }
      });
      this.tableData = getDate;
    }
  }
};