import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import { getVillaEventData, getVillaLogList, getVillaPreference, roomVillaInfo } from '@/api/roomCar';
import { adminParameter } from '@/api/public';
import attention from './components/attention';
import dataInfo from './components/dataInfo';
import logView from './components/log-view.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'BuildingInfo',
  components: {
    attention: attention,
    dataInfo: dataInfo,
    logView: logView
  },
  data: function data() {
    return {
      // tab  默认值
      activeName: 'info',
      // 用户信息
      room: {},
      // 产权人信息
      user_audit_owner: '',
      // 项目配置
      project: '',
      // 活动场次批次
      event: {
        is_round: false,
        is_batch: false,
        is_event: false
      },
      // 图片列表
      images: '',
      // 页面总高度
      appHeight: 0,
      // 顶部噶度
      topHeight: 0,
      // 配置项
      projectList: {},
      eventList: [],
      // 页面详情数据
      list: [],
      // 热度分析
      heat: {},
      // 正式活动
      formal_order: {},
      // 测试活动
      test_order: {},
      // 活动场次
      test_event: {},
      // 活动数据
      activityInfo: {
        valid_order: {}
      },
      // 测试活动第一个
      eventIn: 0,
      selectId: 0,
      // 日志数据
      log: [],
      // tab 下标
      tabIndex: '0',
      // 重置 活动数据 组件
      isData: false,
      // 是否取整
      preStatus: 0,
      // 单价
      dan: 0,
      // 总价
      zong: 0
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['customSetting', 'roles'])), {}, {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 350;
    }
  }),
  watch: {
    tabText: function tabText() {
      this.$nextTick(function () {
        this.topHeight = this.$refs.top.offsetHeight;
      });
    }
  },
  // 页面结束
  mounted: function mounted() {
    var _this = this;
    this.appHeight = this.$refs.app.offsetHeight;
    this.topHeight = this.$refs.top.offsetHeight;
    window.onresize = function () {
      _this.appHeight = _this.$refs.app.offsetHeight;
      _this.topHeight = _this.$refs.top.offsetHeight;
    };
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  created: function created() {
    var _this2 = this;
    moment.locale('zh-cn');
    this.getInfo();
    // 初始化 活动数据
    // this.getCreatedData()
    adminParameter().then(function (res) {
      var data = res.data,
        _res$data = res.data,
        event = _res$data.event,
        list = _res$data.list,
        event_id = _res$data.event_id;
      _this2.project = data;
      // 活动场次批次控制
      _this2.event = event;
      // 全局配置
      _this2.projectList = list;
      _this2.evenId = event_id;
      // 单价
      _this2.dan = list.room_info_price_prec;
      // 总价
      _this2.zong = list.room_info_total_price_prec;
      // 是否取整
      _this2.preStatus = list.room_info_prec_status;
    });
  },
  methods: {
    handleClick: function handleClick(tab, event) {
      var _this3 = this;
      this.tabIndex = tab.index;
      switch (tab.name) {
        case 'info':
          this.getInfo();
          break;
        case 'attention':
          this.getPreference();
          break;
        case 'activity':
          this.isData = false;
          this.$nextTick(function () {
            _this3.isData = true;
          });
          // this.getEventData()
          break;
        case 'log':
          this.getLogList({});
          break;
      }
    },
    handleSelect: function handleSelect(e) {
      this.selectId = e;
      this.getEventData();
    },
    // 改变数据 活动数据 正式 测试
    bindSwitch: function bindSwitch(type) {
      // 测试活动
      if (type === 0) {
        this.activityInfo = this.test_order;
      }
      // 正式活动
      else {
        console.log(this.formal_order);
        this.activityInfo = this.formal_order;
      }
    },
    // 获取详情数据
    getInfo: function getInfo() {
      var _this4 = this;
      // 详情数据
      roomVillaInfo({
        id: this.$route.query.id,
        event_id: this.selectId
      }).then(function (res) {
        if (res.status === 200) {
          var list = res.data.list;
          _this4.list = list;
          _this4.room = list.room;
          //  关联表格
          _this4.eventList = list.event;
          _this4.images = list.room.images;
        }
      });
    },
    // 关注情况
    getPreference: function getPreference() {
      var _this5 = this;
      getVillaPreference({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.status === 200) {
          var heat = res.data.heat;
          _this5.heat = heat;
        }
      });
    },
    //  获取 活动数据下拉框数据
    getCreatedData: function getCreatedData() {
      var _this6 = this;
      getVillaEventData({
        id: this.$route.query.id,
        event_id: this.selectId
      }).then(function (res) {
        if (res.status === 200) {
          var event_data = res.data.event_data;
          _this6.test_event = event_data.test_event;
          _this6.selectId = event_data.test_event[0].id;
          _this6.getEventData();
        }
      });
    },
    //  活动数据
    getEventData: function getEventData() {
      var _this7 = this;
      getVillaEventData({
        id: this.$route.query.id,
        event_id: this.selectId
      }).then(function (res) {
        if (res.status === 200) {
          var event_data = res.data.event_data;
          _this7.formal_order = event_data.formal_order;
          _this7.test_order = event_data.test_order;
          if (event_data.formal_order.invalid_count > 0 && JSON.stringify(event_data.test_order) !== '{}') {
            // 提交最新活动的id
            _this7.selectId = event_data.test_event[event_data.test_event.findIndex(function (d) {
              return d.id === _this7.evenId;
            })].id;
            // this.selectId = event_data.test_event[0].id;
          } else {
            _this7.selectId = '';
          }
          _this7.activityInfo = event_data.test_order;
        }
      });
    },
    // 日志列表
    getLogList: function getLogList(data) {
      var _this8 = this;
      data.id = this.$route.query.id;
      var loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      getVillaLogList(data).then(function (res) {
        if (res.status === 200) {
          var _this8$log;
          // const { log } = res.data
          //
          // if (log && log != null && log.length > 0) {
          //     this.log = log
          // } else {
          //     this.log = []
          // }
          var list = res.data.list;
          var log = [];
          var _dataInfo = {};
          list.forEach(function (item, index) {
            var times = moment(parseInt(item.created_time * 1000)).format('YYYY-MM-DD'),
              time = moment(parseInt(item.created_time * 1000)).format('YYYY-MM-DD HH:mm:ss');
            if (!_dataInfo[times]) {
              _dataInfo[times] = {
                times: times,
                day_name: moment(time).fromNow(),
                log_list: []
              };
            }
            _dataInfo[times].log_list.push(Object.assign(item, {
              key: index
            }));
          });
          Object.values(_dataInfo);
          Object.keys(_dataInfo).forEach(function (item) {
            log.push(_dataInfo[item]);
          });
          if (data.page > 1) (_this8$log = _this8.log).push.apply(_this8$log, log);else _this8.log = log;
          if (list.length === 100) {
            _this8.$nextTick(function () {
              _this8.$refs["logList"].isPageLoad = false;
            });
          }
        }
      }).finally(function (res) {
        loading.close();
      });
    },
    // 跳转修改页面
    handleEdit: function handleEdit() {
      this.$router.push({
        name: 'BuildingEdit',
        query: {
          id: this.room.id,
          round: this.event.is_round
        }
      });
    }
  }
};