import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { roomVillaDelList, roomVillaExport, syncCar, getCarBuild, roomVillaIndex, villaAreaList, carSetAllImg, cardelAllImg, syncRoom, getRoomCate, syncRoomCate } from '@/api/roomCar';
import { adminParameter, projectConfigure } from '@/api/public';
import { parameter } from '@/api/user';
import { mapGetters } from 'vuex';
import UploadExcel from '@/components/my-UploadExcel';
import { debounce } from '@/utils/debounce';
import moment from 'moment';
import utils from '@/utils/utils';
import { getProject, getProjectName, getpName } from '@/utils/auth';
import GMainUpload from '../../components/my-upload';
export default {
  name: 'Building',
  components: {
    UploadExcel: UploadExcel,
    GMainUpload: GMainUpload
  },
  data: function data() {
    return {
      // 提示框 导入
      isTips: false,
      isSync: false,
      isSyncType: false,
      // 页面高度
      appHeight: 0,
      // div高度
      topHeight: 0,
      //  选中的数量
      selectedNum: 0,
      // 第一个页
      page: 1,
      // 分页 一页几条
      pageSize: 10,
      // 分页 总数
      total: 0,
      // 导入路径
      url: '',
      // 楼栋
      areaList: [],
      // 楼层
      unitList: [],
      // 区域
      floorList: [],
      // 项目配置
      project: {},
      // 活动场次批次
      event: {
        is_round: false,
        is_batch: false,
        is_event: false
      },
      // 活动批次
      activity: {},
      // 全部配置
      projectList: {},
      is_search: false,
      //  客户列表
      tableData: [],
      // 表格loading
      tableLoading: false,
      // 场次列表
      sessionList: [],
      // 页面搜索
      search: {
        inxId: 'all',
        round_id: 0,
        batch: 0,
        is_not_sale: 0,
        is_not_tag: 0,
        is_not_round: 0,
        no_batch: 0,
        cate_id: 0,
        build_id: 0,
        no: '',
        special: '',
        tag_id: 0,
        unit_id: 0,
        floor_id: 0
      },
      buildList: [],
      buildTypeList: [],
      checkeBuildData: [],
      checkeBuildDataType: [],
      // 是否取整
      preStatus: 0,
      // 单价
      dan: 0,
      // 总价
      zong: 0,
      // 批量上传图片
      uploadImages: {
        show: false,
        imagesList: [],
        images: ""
      }
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['roles', 'customSetting'])), {}, {
    tableHeight: function tableHeight() {
      return this.appHeight - this.topHeight - 137;
    }
  }),
  mounted: function mounted() {
    var _this = this;
    var that = this;
    that.appHeight = this.$refs.app.offsetHeight;
    that.topHeight = this.$refs.top.offsetHeight;
    window.onresize = function () {
      that.appHeight = _this.$refs.app.offsetHeight;
      that.topHeight = _this.$refs.top.offsetHeight;
    };
  },
  // 注销window.onresize事件
  destroyed: function destroyed() {
    window.onresize = null;
  },
  // 初始化
  created: function created() {
    var query = this.$route.query;
    for (var key in query) {
      query[key] = parseInt(query[key]);
    }
    Object.assign(this.search, query);
    if (this.roles.includes('car/index') || this.roles.includes('admin')) {
      this.getTableList();
    }
    this.projectSetData();
    this.getVillaArea();
  },
  methods: {
    //
    selectChange: function selectChange(e, list) {
      var _this2 = this;
      this.search.unit_id = 0;
      this.search.floor_id = 0;
      this.unitList = [];
      this.floorList = [];
      list && list.forEach(function (item) {
        if (e == item.id) {
          item.unit.forEach(function (info) {
            if (info.name === '') {
              info.name = '无楼层';
            }
          });
          _this2.unitList = item.unit;
          _this2.floorList = [];
        }
      });
    },
    selectChangeFloor: function selectChangeFloor(e, list) {
      var _this3 = this;
      list && list.forEach(function (item) {
        if (e == item.id) {
          item.floor.forEach(function (info) {
            if (info.name === '') {
              info.name = '无区域';
            }
          });
          _this3.floorList = item.floor;
        }
      });
    },
    uploadSuccess: function uploadSuccess() {
      this.getTableList();
      this.isTips = false;
    },
    // 列表搜索事件
    getTableSearchList: function getTableSearchList() {
      this.page = 1;
      this.getTableList();
    },
    // 跳转终端显示
    handelTerminal: function handelTerminal() {
      this.$router.push({
        name: 'BuildTerminal'
      });
    },
    // 关联场次
    handelMerge: function handelMerge() {
      this.$router.push({
        name: 'MergeBuilding'
      });
    },
    //  获取车位区域
    getVillaArea: function getVillaArea() {
      var _this4 = this;
      villaAreaList().then(function (res) {
        var list = res.data.list;
        if (list.build != null) {
          list.build.forEach(function (item, index) {
            if (item.name === '') {
              item.name = '无楼栋';
            }
          });
        }
        list.room_cate.forEach(function (item, index) {
          if (item.name === '') {
            item.name = '无类型';
          }
        });
        _this4.areaList = list;
      });
    },
    // 客户详情
    handelDetails: function handelDetails(row) {
      this.$router.push({
        name: 'BuildingInfo',
        query: {
          id: row.id,
          round: this.event.is_round
        }
      });
    },
    // 修改客户
    handelEditUser: function handelEditUser(row) {
      this.$router.push({
        name: 'BuildingEdit',
        query: {
          id: row.id,
          round: this.event.is_round
        }
      });
    },
    // 导出模板
    handelModel: function handelModel() {
      var url = '';
      if (this.projectList.room_info_price_type === 1) {
        url = this.host + '/template/car/1.云销控-车位导入模板-一个现价.xlsx';
      } else if (this.projectList.room_info_price_type === 2) {
        url = this.host + '/template/car/1.云销控-车位导入模板-原价,折扣价.xlsx';
      } else {
        url = this.host + '/template/car/1.云销控-车位导入模板-原价,一次性,按揭.xlsx';
      }
      utils.download_files(url, "".concat(getpName(), "-\u8F66\u4F4D\u5BFC\u5165-").concat(moment().format('YYYYMMDDHHmmss'), ".xlsx"), {
        showMessage: true
      });
    },
    // 根据搜索条件导出数据
    handelExport: function handelExport() {
      var _this5 = this;
      //  loading
      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      //  活动id 场次id 获取
      var event_id = '';
      var round_id = '';
      var inxId = this.search.inxId;

      // 选择全部
      if (inxId === 'all') {
        event_id = 0;
        round_id = 0;
        //	活动场次
      } else {
        var project = this.activity.project;
        if (project[inxId].round === 1) {
          round_id = project[inxId].round_id;
        } else {
          event_id = project[inxId].id;
        }
      }
      switch (this.search.special) {
        case 'is_not_sale':
          this.search.is_not_sale = 1;
          this.search.is_not_tag = 0;
          this.search.is_not_round = 0;
          break;
        case 'is_not_tag':
          this.search.is_not_sale = 0;
          this.search.is_not_tag = 1;
          this.search.is_not_round = 0;
          break;
        case 'is_not_round':
          this.search.is_not_sale = 0;
          this.search.is_not_tag = 0;
          this.search.is_not_round = 1;
          break;
        case 'all':
          this.search.is_not_sale = 0;
          this.search.is_not_tag = 0;
          this.search.is_not_round = 0;
          break;
      }

      // 提交数据
      var data = {
        event_id: event_id,
        round_id: round_id,
        is_not_sale: this.search.is_not_sale,
        is_not_tag: this.search.is_not_tag,
        is_not_round: this.search.is_not_round,
        build_id: this.search.build_id,
        cate_id: this.search.cate_id,
        no: this.search.no,
        tag_id: this.search.tag_id,
        unit_id: this.search.unit_id,
        floor_id: this.search.floor_id
      };

      // 导出接口
      roomVillaExport(data).then(function (res) {
        if (res.status === 200) {
          _this5.$message({
            type: 'success',
            message: '导出成功'
          });

          // 下载
          // window.location.href = this.host + res.data.msg
          utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
          loading.close();
        }
      });
    },
    onCarPlan: function onCarPlan() {
      var path = this.$router.resolve({
        path: '/plane',
        query: {
          cate: 2
        }
      });
      window.open("".concat(path.href), '_blank');
    },
    syncRoomTypeShow: function syncRoomTypeShow() {
      var _this6 = this;
      var l = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      getRoomCate().then(function (res) {
        var _res$data;
        var _res$data$info = (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.info,
          roomCateList = _res$data$info.roomCateList;
        _this6.buildTypeList = roomCateList || [];
        _this6.isSyncType = true;
      }).finally(function () {
        l.close();
      });
    },
    syncCarShow: function syncCarShow() {
      var _this7 = this;
      var is = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (is) {
        var l = this.$loading({
          lock: true,
          text: '加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        getCarBuild().then(function (res) {
          var _res$data2;
          var _res$data$info2 = (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.info,
            buildList = _res$data$info2.buildList;
          _this7.buildList = buildList !== null && buildList !== void 0 ? buildList : [];
          _this7.isSync = is;
        }).finally(function () {
          l.close();
        });
      } else {
        this.isSync = is;
      }
    },
    submitSyncCar: function submitSyncCar() {
      var _this$checkeBuildData,
        _this8 = this;
      if (!((_this$checkeBuildData = this.checkeBuildData) !== null && _this$checkeBuildData !== void 0 && _this$checkeBuildData.length)) {
        this.$message.error('请选择楼栋后同步');
        return;
      }
      var loading = this.$loading({
        lock: true,
        text: '同步中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var params = {
        building_ids: this.checkeBuildData
      };
      syncCar(params).then(function (res) {
        utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
        _this8.$message.success(res.data.msg);
        _this8.checkeBuildData = [];
        _this8.getTableList();
      }).finally(function () {
        loading.close();
        _this8.syncCarShow();
      });
    },
    submitSyncRoomType: function submitSyncRoomType() {
      var _this$checkeBuildData2,
        _this9 = this;
      if (!((_this$checkeBuildData2 = this.checkeBuildDataType) !== null && _this$checkeBuildData2 !== void 0 && _this$checkeBuildData2.length)) {
        this.$message.error('请选择户型后同步');
        return;
      }
      var loading = this.$loading({
        lock: true,
        text: '同步中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var params = {
        room_cate_ids: this.checkeBuildDataType
      };
      syncRoomCate(params).then(function (res) {
        _this9.$message.success(res.data.msg);
        _this9.checkeBuildDataType = [];
        _this9.getTableList();
      }).finally(function () {
        loading.close();
        _this9.isSyncType = false;
      });
    },
    // 重置 form 表单
    resetForm: function resetForm() {
      // this.$refs['formData'].resetFields()
      this.search = {
        inxId: 'all',
        is_not_sale: 0,
        is_not_tag: 0,
        is_not_round: 0,
        build_id: 0,
        unit_id: 0,
        cate_id: 0,
        no: '',
        tag_id: 0
      };
      this.unitList = [];
      this.floorList = [];
      this.getTableList();
    },
    // // 批量删除
    // handleDelAll() {
    //   this.handelDelList(this.selectIds)
    // },
    // 批量操作
    handleAll: function handleAll(key) {
      var _this10 = this;
      if ((!this.selectIds || !this.selectIds.length) && (key === "delAll" || key === "uploadImage" || key === "delImage")) {
        this.$message({
          type: 'warning',
          message: '请选择你要操作的列表数据'
        });
        return;
      }
      // 删除列表数据
      if (key === "delAll") {
        this.handelDelList(this.selectIds);
      }
      // 上传图片
      else if (key === "uploadImage") {
        this.uploadImages = {
          show: true,
          imagesList: [],
          images: ""
        };
      }
      // 删除图片
      else if (key === "delImage") {
        this.$confirm('此操作不可复原，确认删除图片吗吗？', '提醒', {
          confirmButtonText: '确定',
          cancelButtonText: '关闭',
          type: 'warning'
        }).then(function () {
          var loading = _this10.$loading({
            lock: true,
            text: '加载中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          cardelAllImg({
            ids: _this10.selectIds
          }).then(function (res) {
            if (res.status === 200) {
              _this10.$message({
                type: 'success',
                message: res.data.msg
              });
              _this10.uploadImages = {
                show: false,
                imagesList: [],
                images: ""
              };
              _this10.getTableList();
            }
          }).catch(function (res) {
            _this10.$message({
              type: 'error',
              message: res.data.msg
            });
          }).finally(function (res) {
            loading.close();
          });
        });
      }
      // 确定上传图片
      else if (key === "submitImage") {
        var loading = this.$loading({
          lock: true,
          text: '努力上传中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        carSetAllImg({
          ids: this.selectIds,
          img_val: this.uploadImages.images
        }).then(function (res) {
          if (res.status === 200) {
            _this10.$message({
              type: 'success',
              message: res.data.msg
            });
            _this10.uploadImages = {
              show: false,
              imagesList: [],
              images: ""
            };
            _this10.getTableList();
          }
          loading.close();
        }).catch(function (res) {
          _this10.$message({
            type: 'error',
            message: res.data.msg
          });
        }).finally(function (res) {
          loading.close();
        });
      }
      // 上传成功的图片路径
      else {
        this.uploadImages.images = key.join(',');
      }
    },
    // 删除 用户列表
    handelDelList: function handelDelList(ids) {
      var _this11 = this;
      if (!ids || !ids.length) {
        this.$message({
          type: 'warning',
          message: '请选择你要删除的数据'
        });
        return;
      }
      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        roomVillaDelList({
          ids: ids
        }).then(function (res) {
          if (res.status === 200) {
            _this11.$message({
              type: 'success',
              message: res.data.msg
            });
            _this11.getTableList();
          }
        });
      });
    },
    // 获取table 数据
    getTableList: debounce(function () {
      var _this12 = this;
      //  活动id 场次id 获取
      var event_id = '';
      var round_id = '';
      var inxId = this.search.inxId;

      // 选择全部
      if (inxId === 'all') {
        event_id = 0;
        round_id = 0;
        //	活动场次
      } else {
        var project = this.activity.project;
        if (project[inxId].round === 1) {
          round_id = project[inxId].round_id;
        } else {
          event_id = project[inxId].id;
        }
      }
      switch (this.search.special) {
        case 'is_not_sale':
          this.search.is_not_sale = 1;
          this.search.is_not_tag = 0;
          this.search.is_not_round = 0;
          break;
        case 'is_not_tag':
          this.search.is_not_sale = 0;
          this.search.is_not_tag = 1;
          this.search.is_not_round = 0;
          break;
        case 'is_not_round':
          this.search.is_not_sale = 0;
          this.search.is_not_tag = 0;
          this.search.is_not_round = 1;
          break;
        case 'all':
          this.search.is_not_sale = 0;
          this.search.is_not_tag = 0;
          this.search.is_not_round = 0;
          break;
      }

      // 提交数据
      var data = {
        page: this.page,
        limit: this.pageSize,
        event_id: event_id,
        round_id: round_id,
        is_not_sale: this.search.is_not_sale,
        is_not_tag: this.search.is_not_tag,
        is_not_round: this.search.is_not_round,
        build_id: this.search.build_id,
        cate_id: this.search.cate_id,
        no: this.search.no,
        tag_id: this.search.tag_id,
        unit_id: this.search.unit_id,
        floor_id: this.search.floor_id
      };
      this.tableLoading = true;
      roomVillaIndex(data).then(function (res) {
        if (res.status === 200) {
          _this12.selectIds = [];
          var _res$data3 = res.data,
            list = _res$data3.list,
            count = _res$data3.count;
          // 表格数据
          _this12.tableData = list.room || [];
          // 总数
          _this12.total = count;
        }
      }).finally(function () {
        _this12.tableLoading = false;
      });
    }, 200, true),
    // table 选中的数据
    handleSelectionChange: function handleSelectionChange(row) {
      this.selectedNum = row.length;
      var array = [];
      row.forEach(function (item) {
        array.push(item.id);
      });
      this.selectIds = array;
    },
    // 修改 每页条数
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.getTableList();
    },
    // 翻页
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getTableList();
    },
    // 获取 项目配置 批次 活动
    projectSetData: function projectSetData() {
      var _this13 = this;
      adminParameter().then(function (res) {
        var data = res.data,
          _res$data4 = res.data,
          event = _res$data4.event,
          list = _res$data4.list;
        _this13.project = data;
        // 活动场次批次控制
        _this13.event = event;
        // 全局配置
        _this13.projectList = list;
        // 单价
        _this13.dan = list.room_info_price_prec;
        // 总价
        _this13.zong = list.room_info_total_price_prec;
        // 是否取整
        _this13.preStatus = list.room_info_prec_status;
      });
      projectConfigure().then(function (res) {
        var list = res.data.list;
        _this13.activity = list;
      });
    }
  }
};