import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import reportTitle from './components/reportTitle'; // 大标题
import reportSubtitle from './components/reportSubtitle'; // 小标题
import centerTitle from './components/centerTitle'; // 中心标题
import listItem from './components/listItem'; // 开盘小结 列表
import banner from './components/banner'; // 顶部banner
import card from './components/card'; // 卡片
import customTable from './components/customTable'; // 表格 自定义头部颜色
import customTablecopy from './components/customTableCopy'; // 表格 普通表格
import pieChart from './components/pieChart'; // 饼图
import histogramChart from './components/histogramChart'; // 横向柱状图
import processItem from './components/processItem'; // 时间轴
import customerArc from './components/customerArc'; // 扇形图表
import columnarChart from './components/columnarChart'; // 柱状图 对比
import footerCustom from './components/footer'; // 页面底部
import { activityReport } from '@/api/activityReport'; // 开盘报告接口
import { isNullNum, isNullPrice, tenThousandTrans, tenThousandTransCopy, isArray } from './util'; // 判空
import { mapState, mapActions, mapGetters } from 'vuex';
import htmlToPdf from '@/utils/htmlToPdf';
import moment from 'moment';
export default {
  name: 'ActiveReport',
  components: {
    reportTitle: reportTitle,
    reportSubtitle: reportSubtitle,
    centerTitle: centerTitle,
    listItem: listItem,
    banner: banner,
    card: card,
    customTable: customTable,
    customTablecopy: customTablecopy,
    pieChart: pieChart,
    histogramChart: histogramChart,
    processItem: processItem,
    customerArc: customerArc,
    columnarChart: columnarChart,
    footerCustom: footerCustom
  },
  data: function data() {
    return {
      // 数据请求完成（数据请求完成后，再渲染节点，避免触发不必要的渲染）
      reqFinish: false,
      // 渲染状态（全部图表渲染完成后，再绘制PDF）
      renderState: {},
      // 是否显示加载遮罩
      loading: true,
      // 基础数据 tabele 头部
      basicDataHead: [{
        label: '类型',
        prop: 'type',
        width: 60
      }, {
        label: '推案数量',
        prop: 'room_count',
        width: 80
      }, {
        label: '认购数量',
        prop: 'order_count',
        width: 80
      }, {
        label: '认购金额(万元)',
        prop: 'sum_price',
        width: 145
      }, {
        label: '成交均价',
        prop: 'avg_price',
        width: 125
      }],
      // 住宅销售详情 table 头部
      houseSaleDataHeadOne: [{
        label: '楼栋',
        prop: 'name'
      }, {
        label: '单元数',
        prop: 'unit_count'
      }, {
        label: '推案数量',
        prop: 'room_count',
        count: true
      }, {
        label: '认购数量',
        prop: 'order_count',
        count: true
      }, {
        label: '去化率',
        prop: 'Removalrate',
        count: true
      }, {
        label: '车位//同购比',
        prop: 'sync_count',
        width: 70
      }, {
        label: '成交均价//(万元/㎡)',
        prop: 'avg_price',
        width: 127,
        count: true
      }],
      houseSaleDataHeadTwo: [{
        label: '户型',
        prop: 'name',
        width: 65
      }, {
        label: '户型结构',
        prop: 'tag'
      }, {
        label: '户型面积(㎡)',
        prop: 'area_interval',
        width: 106
      }, {
        label: '推案数量',
        prop: 'room_count',
        count: true
      }, {
        label: '认购数量',
        prop: 'order_count',
        count: true
      }, {
        label: '去化率',
        prop: 'Removalrate',
        count: true
      }, {
        label: '车位//同购比',
        prop: 'sync_count',
        width: 70
      }, {
        label: '成交均价//(万元/㎡)',
        prop: 'avg_price',
        width: 100,
        count: true
      }],
      // 别墅销售详情 table 头部
      villaSaleDataHeadOne: [{
        label: '楼层',
        prop: 'build_name',
        width: 60
      }, {
        label: '区域',
        prop: 'floor_name',
        width: 85
      }, {
        label: '层级',
        prop: 'unit_name'
      }, {
        label: '推案数量',
        prop: 'room_count',
        count: true
      }, {
        label: '销售数量',
        prop: 'order_count',
        count: true
      }, {
        label: '去化率',
        prop: 'Removalrate',
        count: true
      }, {
        label: '成交均价//(万元/个)',
        prop: 'avg_price',
        width: 127,
        count: true
      }],
      villaSaleDataHeadTwo: [{
        label: '户型',
        prop: 'name',
        width: 65
      }, {
        label: '户型面积(㎡)',
        prop: 'area_interval',
        width: 106
      }, {
        label: '户型单价(元/㎡)',
        prop: 'price_interval',
        width: 127
      }, {
        label: '推案数量',
        prop: 'room_count',
        count: true
      }, {
        label: '认购数量',
        prop: 'order_count',
        count: true
      }, {
        label: '去化率',
        prop: 'Removalrate',
        count: true
      }, {
        label: '成交均价//(万元/个)',
        prop: 'avg_price',
        width: 127,
        count: true
      }],
      //  开盘小结
      summaryDataHead: [{
        label: '客户预估成功率区间',
        prop: 'section',
        width: 80
      }, {
        label: '此区间客户总人数(人)',
        prop: 'user_count',
        width: 85
      }, {
        label: '此区间客户选房人数(人)',
        prop: 'order_count',
        width: 90
      }, {
        label: '此区间客户转化率',
        prop: 'avg',
        width: 75
      }],
      // 住宅各户型 e-chart
      chartDataHouse: [{
        value: 1048,
        name: '11'
      }, {
        value: 735,
        name: '22'
      }, {
        value: 580,
        name: '33'
      }, {
        value: 484,
        name: '44'
      }],
      // 别墅各户型 e-chart
      chartDataVilla: [{
        value: 1048,
        name: '55'
      }, {
        value: 100,
        name: '66'
      }, {
        value: 580,
        name: '77'
      }, {
        value: 484,
        name: '88'
      }],
      // 客户选房时长统计
      customChooseTime: [50, 50, 70, 35],
      customChooseTime_name: [],
      // 各楼层销售总量 X Y 轴数据
      YData: [],
      XData: [],
      buildName: [],
      buildYDataOne: [],
      buildYDataTwo: [],
      cateName: [],
      cateYDataOne: [],
      cateYDataTwo: [],
      // 落位与实际销售情况的对比 数据
      buildChart: [],
      cateChart: [],
      // ----------------- 接口 数据 ----------
      event: {},
      // 基础数据
      projectData: {},
      // 基础数据下 的 project
      projectData_project: {},
      // 活动流程 数据
      flowData: {},
      // 开盘销售&客户选房详情
      roomData: {},
      // 各楼层销售总量(只统计住宅建筑类型)
      floorData: [],
      // 顾问排名
      adminRank: [],
      // 团队排名
      teamRank: [],
      // 客户选房时间统计
      selectData: {},
      // 项目信息
      project: {},
      // 开盘环节
      projectRule: {
        pay: {},
        selectNum: {},
        sign: {},
        signNum: {},
        signUp: {},
        userCount: '',
        selfSelect: {}
      },
      // 未认购客户特性
      unsoldUser: {},
      // 已认购客户与其意向的适配度
      collectFit: {},
      // 未售房源特性
      unsoldData: {},
      // 落位与实际销售情况的对比
      SalesStatus: {
        SUcount: '',
        build: [],
        cate: [],
        event: [],
        success: []
      },
      // 用户总数
      userCount: '',
      // 推荐房源数据
      collectData: {},
      // 未售房源特性 -数据组装
      unsoldDataCopy: [],
      // 未认购客户的特性 -数据组装
      unsoldUserCopy: [],
      // ------------外部js-----------------
      // 判空
      isNullNum: null,
      isNullPrice: null,
      tenThousandTrans: null,
      tenThousandTransCopy: null,
      isArray: null,
      info: {
        advance: '',
        //推进过程小结
        iteration: '',
        //系统迭代建议
        flowpath: '' //开盘流程建议,
      },
      xtRecommend: 0,
      isroomOrcar: 0,
      //开启了几种类型,//0住宅车位都开启,1只开启住宅,2只开启车位
      UserAge: [],
      //
      userAgexData: [],
      //柱状图x轴数据
      CityCount: [],
      //客户按市分布占比饼图数据
      RegionCount: [],
      //客户按区分布占比饼图数据
      order_type: '',
      //认购统计维度
      showDimension: [] //报告维度展示
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(['customSetting'])), mapState('activityReport', {
    // event: state => state.activityReport.event //正式活动信息
  })), {}, {
    averagePrice: function averagePrice() {
      var str = '';
      if (this.project.cate_car === 1 && this.project.cate_room !== 1) {
        //只开启车位时
        if (+this.projectData2.cate2.sum_price && this.projectData2.cate2.order_count) {
          //车位平均价 等于 认购金额除以认购数量
          str = Math.floor(+this.projectData2.cate2.sum_price / this.projectData2.cate2.order_count);
        } else {
          str = '未提供';
        }
      } else {
        str = Math.floor(+this.projectData2.cate1.avg_price);
      }
      return str == 0 ? '未提供' : str;
    }
  }),
  created: function created() {
    var option = this.$route.query;
    if (option.info) {
      this.info = JSON.parse(option.info);
    }
    this.showDimension = JSON.parse(option.showDimension);
    this.order_type = option.order_type;
    this.xtRecommend = option.xtRecommend;
    console.log(this.xtRecommend);
    // 获取开盘报告数据
    this.getActivityReportData(option.order_type);
    // --------------------------
    this.isNullNum = isNullNum;
    this.isNullPrice = isNullPrice;
    this.tenThousandTrans = tenThousandTrans;
    this.tenThousandTransCopy = tenThousandTransCopy;
    this.isArray = isArray;
  },
  methods: _objectSpread(_objectSpread({}, mapActions('activityReport', ['changeActivityReport'])), {}, {
    showProject: function showProject(modules) {
      var flag = false;
      var num = 0;
      for (var i = 0; i < this.showDimension.length; i++) {
        if (modules == this.showDimension[i]) {
          flag = true;
          num = '0' + (i + 1);
          break;
        }
      }
      return {
        show: flag,
        num: num
      };
    },
    getActivityReportData: function getActivityReportData(order_type) {
      var _this = this;
      activityReport({
        order_type: order_type
      }).then(function (res) {
        console.log(res.data, 'pdf数据');
        var _res$data = res.data,
          _res$data$project = _res$data.project,
          cate_room = _res$data$project.cate_room,
          cate_car = _res$data$project.cate_car,
          UserAge = _res$data.UserAge,
          CityCount = _res$data.CityCount,
          RegionCount = _res$data.RegionCount;
        // 成交客户年龄段占比图标数据
        if (UserAge && UserAge.length) {
          _this.UserAge = [];
          _this.userAgexData = UserAge.map(function (item) {
            return item.age_group;
          });
          _this.userAgexDataDataOne = UserAge.map(function (item) {
            return item.user_count;
          });
          UserAge.map(function (item) {
            return _this.UserAge.push({
              value: item.user_count,
              name: item.age_group
            });
          });
        }
        if (CityCount && CityCount.length) {
          //成交客户按市分布
          _this.CityCount = [];
          CityCount.sort(function (a, b) {
            return b.count - a.count;
          });
          var newCityCount = CityCount.splice(0, 10);
          console.log(newCityCount, 'newCityCount');
          newCityCount.map(function (item) {
            return _this.CityCount.push({
              value: item.count,
              name: item.addr
            });
          });
        }
        if (RegionCount && RegionCount.length) {
          //成交客户按区域分布
          _this.RegionCount = [];
          RegionCount.sort(function (a, b) {
            return b.count - a.count;
          });
          var newRegionCount = RegionCount.splice(0, 10); //筛选出前10
          newRegionCount.map(function (item) {
            return _this.RegionCount.push({
              value: item.count,
              name: item.addr
            });
          });
        }
        // 正式活动信息
        _this.event = res.data.event;
        // 基础数据
        _this.projectData = JSON.parse(JSON.stringify(res.data.projectData));
        _this.projectData2 = JSON.parse(JSON.stringify(res.data.projectData));
        _this.isroomOrcar = cate_room == 1 && cate_car == 1 ? 0 : cate_room == 1 && cate_car != 1 ? 1 : 2; //开启的类型 车位和房源
        // 基础数据下的project
        _this.projectData_project = _this.projectData.projcet;
        // 活动流程 数据
        _this.flowData = res.data.flowData;
        // 开盘销售&客户选房详情
        if (res.data.roomData) {
          // 过滤推案数为0的数据
          for (var key in res.data.roomData) {
            var _res$data$roomData$ke;
            if ((_res$data$roomData$ke = res.data.roomData[key]) !== null && _res$data$roomData$ke !== void 0 && _res$data$roomData$ke.length) {
              res.data.roomData[key] = res.data.roomData[key].filter(function (item) {
                return item.room_count;
              });
            }
          }
        }
        _this.roomData = res.data.roomData;
        // 各楼层销售总量(只统计住宅建筑类型)
        _this.floorData = res.data.floorData;
        // 销售 顾问排名

        _this.adminRank = res.data.adminRank;
        // 销售 团队排名
        if (cate_room == 1 && cate_car == 1) {
          if (res.data.teamRank && res.data.teamRank.length) {
            res.data.teamRank.map(function (item) {
              if (item.user_count) {
                // let num = item.cate2_count / item.cate1_count
                var num = item.sync_count / item.user_count;
                item.carProportion = Math.round(num * 100) + '%';
              } else {
                item.carProportion = '无';
              }
            });
          }
          if (res.data.adminRank && res.data.adminRank.length) {
            res.data.adminRank.map(function (item) {
              if (item.user_count) {
                // let num = item.cate2_count / item.cate1_count
                var num = item.sync_count / item.user_count;
                item.carProportion = Math.round(num * 100) + '%';
              } else {
                item.carProportion = '无';
              }
            });
          }
        }
        _this.teamRank = res.data.teamRank;
        // 客户选房时间统计
        _this.selectData = res.data.selectData;
        // 项目信息
        _this.project = res.data.project;
        // 开盘环节
        _this.projectRule = res.data.projectRule;
        // 未认购客户特性
        _this.unsoldUser = res.data.unsoldUser;
        // 已认购客户与其意向的适配度
        _this.collectFit = res.data.collectFit;
        // 未售房源特性
        _this.unsoldData = res.data.unsoldData;
        // 落位与实际销售情况的对比
        _this.SalesStatus = res.data.SalesStatus;
        // 用户总数
        _this.userCount = res.data.userCount;
        // 推荐房源数据
        _this.collectData = res.data.collectData;

        // 组装数据 各楼层销售总量 数据
        _this.histogram(res.data.floorData);
        // 组装数据 住宅别墅 各户型 销售占比
        _this.getProportion(res.data.roomData);
        // 组装数据 客户选房时长统计
        _this.getCustomChooseTime(res.data.selectData);
        // 组装数据 开盘销售&客户选房详情
        _this.getRoomData(res.data.roomData);
        // 组装数据 落位与实际销售情况的对比
        _this.getSalesStatus(res.data.SalesStatus);
        // 开盘小结 未售房源的特性
        _this.getUnsoldData(res.data.unsoldData);
        // 未认购客户的特性 数据组装
        _this.getUnsoldUser(res.data.unsoldUser);

        // 存入vuex
        // this.changeActivityReport(res.data)

        // 别墅改车位
        _this.villaSaleDataHeadTwo[0].label = _this.customSetting.villa_setting.house_type;
        _this.villaSaleDataHeadTwo[1].label = _this.customSetting.villa_setting.house_type + '面积(㎡)';
        _this.villaSaleDataHeadTwo[2].label = _this.customSetting.villa_setting.house_type + '单价(元/㎡)';
        _this.villaSaleDataHeadTwo.splice(2, 1); //车位销售详情删除类型单价列
        // this.villaSaleDataHeadOne = [
        //   { label: '楼栋', prop: 'unit_count', width: 65 },
        //   { label: '楼层', prop: 'floor_count', width: 65 },
        //   { label: '区域', prop: 'name', width: 65 },
        //   { label: '推案数量', prop: 'room_count', width: 65 },
        //   { label: '销售数量', prop: 'order_count', width: 65 },
        //   { label: '去化率', prop: 'Removalrate', width: 65 },
        //   { label: '成交均价(元/㎡)', prop: 'avg_price', width: 65 }
        // ]
        // 所有数据处理完成后，再渲染节点，避免触发多次渲染回调
        _this.reqFinish = true;
      });
    },
    /**
     * 只要执行到 echart.setOption，就视为渲染完成
     * 如果使用监听，则无数据时无法触发chart渲染回调
     * 而且鼠标放入图表时，会再次触发渲染回调
     * 因此，暂时只能采用这种不严谨的写法
     */
    setRenderState: function setRenderState(name) {
      var _this2 = this;
      var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      // 存储渲染状态
      this.renderState[name] = state;
      clearTimeout(this.renderTimer1);
      this.renderTimer1 = setTimeout(function () {
        // 1s后再检查避免提前绘制
        _this2.checkRenderState();
      }, 1500);
    },
    // 检查渲染状态
    checkRenderState: function checkRenderState(second) {
      var _this3 = this;
      clearTimeout(this.renderTimer);
      this.renderTimer = setTimeout(function () {
        // 若全部渲染完成，就开始绘制PDF
        if (!Object.values(_this3.renderState).includes(false)) {
          _this3.$nextTick(function () {
            _this3.loading = false;
            _this3.download();
            console.log('all rendering is completed, start drawing.');
          });
        } else {
          // 已经是第二次，就停止渲染并打印警告
          if (second) console.warn('rendering exception, have charts are not rendered!');
          // 开始第二次检查（容错处理）
          else _this3.checkRenderState(true);
        }
      }, 300);
    },
    // 基础数据 表格数据
    basicData: function basicData(d1, d2) {
      var newArr = [];
      if (this.project.cate_room === 1) {
        if (+d1.avg_price) {
          d1.avg_price = String(Math.floor(+d1.avg_price / 10000 * 100) / 100) == 0 ? '0.00' : String(Math.floor(+d1.avg_price / 10000 * 100) / 100) + '万元/㎡';
        }
        newArr.push(_objectSpread({
          type: '住宅'
        }, d1));
      }
      if (this.project.cate_car === 1) {
        if (+d2.sum_price && d2.order_count) {
          //车位平均价 等于 认购金额除以认购数量
          d2.avg_price = Math.floor(+d2.sum_price / d2.order_count / 10000 * 100) / 100 + '万元/个';
        } else {
          d2.avg_price = '0.00';
        }
        // if(+d2.avg_price){
        //   d2.avg_price  =String(Math.floor( +d2.avg_price/10000*100) / 100) ==0?'0.00':String(Math.floor( +d2.avg_price/10000*100) / 100)+'万元/个'
        // }
        newArr.push(_objectSpread({
          type: this.customSetting.villa_setting.house_name
        }, d2));
      }
      return newArr;
    },
    // 各楼层销售总量 数据
    histogram: function histogram(data) {
      var x_d = [];
      var y_d = [];
      data && data.forEach(function (item) {
        x_d.push(item.count);
        y_d.push(item.name);
      });
      this.XData = x_d.reverse();
      this.YData = y_d.reverse();
    },
    // 获取 百分比
    getPercentage: function getPercentage(top, bottom) {
      if (top && bottom) {
        if (Number(bottom) != 0) {
          return Math.round(Number(top) / Number(bottom) * 100);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    // 获取成数
    getPercentage_c: function getPercentage_c(top, bottom) {
      if (Number(bottom) != 0) {
        var index = Math.round(Number(top) / Number(bottom) * 10);
        switch (index) {
          case 0:
            return '零成';
          case 1:
            return '一成';
          case 2:
            return '二成';
          case 3:
            return '三成';
          case 4:
            return '四成';
          case 5:
            return '一半';
          case 6:
            return '六成';
          case 7:
            return '七成';
          case 8:
            return '八成';
          case 9:
            return '九成';
          case 10:
            return '十成';
        }
      } else {
        return '零成';
      }
    },
    // 获取销售占比
    getProportion: function getProportion(data) {
      var houseData = data.cate1;
      var villaData = data.cate2;
      // 住宅 别墅 销售占比数据
      var chartDataHouse = [];
      var chartDataVilla = [];
      houseData && houseData.forEach(function (item) {
        chartDataHouse.push({
          value: item.order_count,
          name: item.name
        });
      });
      villaData && villaData.forEach(function (item) {
        chartDataVilla.push({
          value: item.order_count,
          name: item.name
        });
      });
      this.chartDataHouse = chartDataHouse;
      this.chartDataVilla = chartDataVilla;
    },
    // 获取客户选房时长统计数据
    getCustomChooseTime: function getCustomChooseTime(data) {
      var customChooseTime = [];
      var customChooseTime_name = [];
      var sortList = data.sortList;
      sortList && sortList.forEach(function (item) {
        customChooseTime.push(data[item]);
      });
      customChooseTime_name = sortList;
      this.customChooseTime = customChooseTime.slice(0, 4);
      this.customChooseTime_name = customChooseTime_name.slice(0, 4);
    },
    // 开盘销售&客户选房详情
    getRoomData: function getRoomData(data) {
      for (var key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          var element = data[key];
          element && element.forEach(function (item) {
            // 组装价格区间
            if (item.min_price && item.max_price) {
              if (item.min_price == item.max_price) {
                // 如果最大价格等于最小价格 取一个值
                item.price_interval = item.max_price;
              } else {
                item.price_interval = item.min_price + '~' + item.max_price;
              }
            } else {
              item.price_interval = '';
            }
            // 组装面积区间
            if (item.min_num && item.max_num) {
              if (item.min_num == item.max_num) {
                item.area_interval = item.max_num;
              } else {
                item.area_interval = item.min_num + '~' + item.max_num;
              }
            } else {
              item.area_interval = '';
            }
            if (item.room_count && item.room_count != 0) {
              item.Removalrate = Math.round(item.order_count / item.room_count * 100) + '%';
            } else {
              item.Removalrate = '0%';
            }
          });
        }
      }
      // 如果订单数量存在，但是金额为0，那么就是未提供数据导致
      for (var i = 1; i < 3; i++) {
        var _this$roomData;
        if ((_this$roomData = this.roomData["build" + i]) !== null && _this$roomData !== void 0 && _this$roomData.length) {
          this.roomData["build" + i].forEach(function (item) {
            if (!+item.avg_price && +item.order_count) {
              item.avg_price = '未提供';
            }
            if (!item.name) {
              item.name = '未提供';
            }
            if (!item.unit_count) {
              item.unit_count = '未提供';
            }
            if (!item.floor_count) {
              item.floor_count = '未提供';
            }
            if (!item.sync_count) {
              item.sync_count = '0%';
            } else if (item.sync_count) {
              item.sync_count = item.sync_count + '%';
            }
          });
          if ('cate' + i == 'cate1' && this.roomData["cate" + i]) {
            var is_tag = this.roomData["cate" + i].find(function (datas) {
              return datas.tag;
            });
            if (!is_tag) {
              //如果全部没有户型结表格不显示
              this.houseSaleDataHeadTwo.splice(1, 1);
            }
          }
          if ('build' + i == 'build2' && this.roomData["build" + i]) {
            var is_floor_name = this.roomData["build" + i].find(function (datas) {
              return datas.floor_name;
            });
            var is_unit_name = this.roomData["build" + i].find(function (datas) {
              return datas.unit_name;
            });
            var is_build_name = this.roomData["build" + i].find(function (datas) {
              return datas.build_name;
            });
            if (!is_build_name) {
              var dleIndex = this.villaSaleDataHeadOne.findIndex(function (datas) {
                return datas.prop == 'build_name';
              });
              this.villaSaleDataHeadOne.splice(dleIndex, 1);
            }
            if (!is_floor_name) {
              var _dleIndex = this.villaSaleDataHeadOne.findIndex(function (datas) {
                return datas.prop == 'floor_name';
              });
              this.villaSaleDataHeadOne.splice(_dleIndex, 1);
            }
            if (!is_unit_name) {
              var _dleIndex2 = this.villaSaleDataHeadOne.findIndex(function (datas) {
                return datas.prop == 'unit_name';
              });
              this.villaSaleDataHeadOne.splice(_dleIndex2, 1);
            }
          }
          this.roomData["cate" + i].forEach(function (item) {
            if (!+item.avg_price && +item.order_count) {
              item.avg_price = '未提供';
            }
            if (!item.name) {
              item.name = '未提供';
            }
            if (!item.area_interval) {
              item.area_interval = '未提供';
            }
            if (!item.price_interval || !+item.price_interval) {
              item.price_interval = '未提供';
            }
            if (!item.sync_count) {
              item.sync_count = '0%';
            } else if (item.sync_count) {
              item.sync_count = item.sync_count + '%';
            }
          });
        }
      }
    },
    // 计算时间
    getTime: function getTime(end_time, start_time) {
      var time = end_time - start_time;
      var hours = Math.round(time / 60);
      return hours;
    },
    // 时间处理
    getTimeFomat: function getTimeFomat(time) {
      if (!time) {
        return {
          time: 0,
          isS: true
        };
      }
      if (Number(time) < 60) {
        return {
          time: time,
          isS: true
        };
      } else {
        var min = Math.round(time / 60);
        return {
          time: min,
          isS: false
        };
      }
    },
    // 计算分钟
    getMin: function getMin(time) {
      if (time != undefined && time != 0) {
        var min = Number(time) / 60;
        return parseInt(min);
      } else {
        return 0;
      }
    },
    // 组装数据 落位与实际销售情况的对比
    getSalesStatus: function getSalesStatus(data) {
      var _this4 = this;
      data.success && data.success.forEach(function (item) {
        if (item.i == 0) {
          item.section = '20%以下';
        }
        if (item.i == 1) {
          item.section = '20%-60%';
        }
        if (item.i == 2) {
          item.section = '60%-80%';
        }
        if (item.i == 3) {
          item.section = '80%以上';
        }
        item.avg = _this4.getPercentage(item.order_count, item.user_count) + '%';
      });
      data.success && data.success.sort(function (a, b) {
        return a.i - b.i;
      });

      // 落位与实际销售情况的对比  表格1
      var buildName = []; // 户型名称
      var buildYDataOne = []; // 落位率
      var buildYDataTwo = []; // 开盘去化率

      var cateName = []; // 户型名称
      var cateYDataOne = []; // 落位率
      var cateYDataTwo = []; // 开盘去化率
      data.build && data.build.forEach(function (item) {
        var y_one = _this4.getPercentage(item.one_count, item.room_count);
        var y_two = _this4.getPercentage(item.order_count, item.room_count);
        buildName.push(item.name);
        buildYDataOne.push(y_one);
        buildYDataTwo.push(y_two);
      });
      data.cate && data.cate.forEach(function (item) {
        var y_one = _this4.getPercentage(item.one_count, item.room_count);
        var y_two = _this4.getPercentage(item.order_count, item.room_count);
        cateName.push(item.name);
        cateYDataOne.push(y_one);
        cateYDataTwo.push(y_two);
      });
      this.buildName = buildName; // 户型名称
      this.buildYDataOne = buildYDataOne; // 落位率
      this.buildYDataTwo = buildYDataTwo; // 开盘去化率

      this.cateName = cateName; // 户型名称
      this.cateYDataOne = cateYDataOne; // 落位率
      this.cateYDataTwo = cateYDataTwo; // 开盘去化率

      // 落为对比 表1 表二
      var chartOne = [];
      var chartTwo = [];
      // 分割数组
      var n_buildName = this.arrSlice(buildName, 4);
      var n_buildYDataOne = this.arrSlice(buildYDataOne, 4);
      var n_buildYDataTwo = this.arrSlice(buildYDataTwo, 4);
      var n_cateName = this.arrSlice(cateName, 4);
      var n_cateYDataOne = this.arrSlice(cateYDataOne, 4);
      var n_cateYDataTwo = this.arrSlice(cateYDataTwo, 4);
      n_buildName && n_buildName.forEach(function (item, i) {
        chartOne.push({
          buildName: item,
          buildYDataOne: n_buildYDataOne[i],
          buildYDataTwo: n_buildYDataTwo[i]
        });
      });
      n_cateName && n_cateName.forEach(function (item, i) {
        chartTwo.push({
          cateName: item,
          cateYDataOne: n_cateYDataOne[i],
          cateYDataTwo: n_cateYDataTwo[i]
        });
      });
      this.buildChart = chartOne;
      this.cateChart = chartTwo;
      console.log(this.cateChart, 'cateChart');
    },
    // 生成并下载PDF
    download: function download() {
      // PDF命名规则：项目名称-开盘报告-年月日时分秒
      var name = this.project.name;
      // let fileName = `${name}-开盘报告(${(this.projectRule.info.buy_type === 2 ? '协助' : '自助')})-${moment().format('YYYYMMDDHHmmss')}`
      var fileName = "".concat(name, "-\u5F00\u76D8\u8BE6\u7EC6\u62A5\u544A");
      htmlToPdf.downloadPDF(document.getElementById('pdf_node'), fileName);
    },
    // 数组分割
    arrSlice: function arrSlice(arr, num) {
      if (num == 0) {
        return arr;
      }
      var newArr = [];
      for (var i = 0; i < arr.length; i += num) {
        newArr.push(arr.slice(i, i + num));
      }
      return newArr;
    },
    // 开盘小结 未售房源的特性 未认购客户的特性 数据组装
    getUnsoldData: function getUnsoldData(unsoldData) {
      var arr = [];
      var isUnsoldCate = unsoldData.unsoldCate && unsoldData.unsoldCate.length > 0; // 未售户型
      var isUnsoldBuild1 = unsoldData.unsoldBuild1 && unsoldData.unsoldBuild1.length > 0; // 未售楼栋
      var isUnsoldBuild2 = unsoldData.unsoldBuild2 && unsoldData.unsoldBuild2.length > 0; // 未售区域
      var isUnsoldPrice1 = false;
      var isUnsoldPrice2 = false;
      var priceArr_one = [];
      var priceArr_two = [];
      var cateAndBuild = [];
      // 未认购楼层
      if (unsoldData.floorList && unsoldData.floorList.length > 0) {
        arr.push("\u9879\u76EE\u5F00\u76D8\u6D3B\u52A8\u672A\u8BA4\u8D2D".concat(this.customSetting.custom_data.house_res_report, "\u7684\u697C\u5C42\u4E3B\u8981\u96C6\u4E2D").concat(unsoldData.floorList.join('、')));
      }
      // 未认购户型
      if (isUnsoldCate) {
        var cateArr = [];
        if (isUnsoldCate) {
          cateArr.push("".concat(this.customSetting.custom_data.house_type_report, "\u4E3B\u8981\u4E3A").concat(unsoldData.unsoldCate.join('、')));
        }
        if (isUnsoldBuild1 && unsoldData.unsoldBuild1.join('')) {
          cateArr.push("\u697C\u680B\u4E3A".concat(unsoldData.unsoldBuild1.join('、')));
        }
        if (isUnsoldBuild2 && unsoldData.unsoldBuild2.join('')) {
          cateArr.push("\u533A\u57DF\u4E3A".concat(unsoldData.unsoldBuild2.join('、')));
        }
        arr.push(cateArr.join(';'));
      }

      // 住宅
      if (unsoldData.unsoldPrice1 && Object.hasOwnProperty(unsoldData.unsoldPrice1) && Object.keys(unsoldData.unsoldPrice1).length > 0) {
        // 未售区间-住宅
        isUnsoldPrice1 = true;
        for (var key in unsoldData.unsoldPrice1) {
          priceArr_one.push("\u5355\u4EF7".concat(key, "\u6709").concat(unsoldData.unsoldPrice1[key], "\u5957\u672A\u8BA4\u8D2D"));
        }
        var text_copy_one = "".concat(isUnsoldPrice1 ? "\u4F4F\u5B85\uFF1A\u9879\u76EE\u5F00\u76D8\u6D3B\u52A8\u672A\u8BA4\u8D2D\u7684\u623F\u6E90".concat(priceArr_one.join('、')) : '');
        cateAndBuild.push(text_copy_one);
      }

      // 别墅
      if (unsoldData.unsoldPrice2 && Object.hasOwnProperty(unsoldData.unsoldPrice2) && Object.keys(unsoldData.unsoldPrice2).length > 0) {
        // 未售区间-别墅
        isUnsoldPrice2 = true;
        for (var _key in unsoldData.unsoldPrice2) {
          priceArr_two.push("".concat(_key));
        }
        var text_copy_two = "".concat(isUnsoldPrice2 ? "".concat(this.customSetting.villa_setting.house_name, "\uFF1A\u9879\u76EE\u5F00\u76D8\u6D3B\u52A8\u672A\u8BA4\u8D2D\u7684").concat(this.customSetting.custom_data.house_res_report, "\u5355\u4EF7\u96C6\u4E2D\u5728").concat(priceArr_two.join('、')) : '');
        cateAndBuild.push(text_copy_two);
      }
      if (cateAndBuild.length != 0) {
        arr.push(cateAndBuild);
      }
      this.unsoldDataCopy = arr;
    },
    // 未认购客户的特性 数据组装
    getUnsoldUser: function getUnsoldUser(unsoldUser) {
      var arr = [];
      if (unsoldUser.all && unsoldUser.all != 0) {
        var text = "\u672A\u8BA4\u8D2D\u5BA2\u6237\u4E2D\u6709".concat(this.getPercentage(unsoldUser.all, unsoldUser.userCount), "%,\u7B2C\u4E00\u610F\u5411\u544A\u7F44\uFF0C\u6240\u4EE5\u672A\u8BA4\u8D2D\u5176\u4ED6").concat(this.customSetting.custom_data.house_res_report, "\u3002");
        arr.push(text);
      }
      if (unsoldUser.like && unsoldUser.like != 0) {
        var _text = "\u672A\u8BA4\u8D2D\u5BA2\u6237\u4E2D\u6709".concat(this.getPercentage(unsoldUser.like, unsoldUser.userCount), "%,\u610F\u5411\u5355\u5168\u90E8\u544A\u7F44\uFF0C\u6240\u4EE5\u672A\u8BA4\u8D2D\u5176\u4ED6").concat(this.customSetting.custom_data.house_res_report, "\u3002");
        arr.push(_text);
      }
      if (unsoldUser.one && unsoldUser.one != 0) {
        var _text2 = "\u672A\u8BA4\u8D2D\u5BA2\u6237\u4E2D\u6709".concat(this.getPercentage(unsoldUser.one, unsoldUser.userCount), "%,\u7B26\u5408\u610F\u5411\u6237\u578B\u548C\u697C\u5C42\u7684\u623F\u6E90\u90FD\u5DF2\u544A\u7F44\uFF0C\u6240\u4EE5\u672A\u8BA4\u8D2D\u5176\u4ED6\u623F\u6E90\u3002");
        arr.push(_text2);
      }
      if (unsoldUser.price && unsoldUser.price != 0) {
        var _text3 = "\u672A\u8BA4\u8D2D\u5BA2\u6237\u4E2D\u6709".concat(this.getPercentage(unsoldUser.price, unsoldUser.userCount), "%,\u5728\u6B63\u5F0F\u6D3B\u52A8\u4E2D\u5E76\u672A\u62A2\u8D2D").concat(this.customSetting.custom_data.house_res_report, "\uFF0C\u53EF\u80FD\u662F\u4EF7\u683C\u7B49\u56E0\u7D20\u672A\u8FBE\u9884\u671F\u3002");
        arr.push(_text3);
      }
      this.unsoldUserCopy = arr;
    },
    // 开盘签到时段
    getTimeDay: function getTimeDay(sign) {
      var startTime = sign.startTime;
      var endTime = sign.endTime;
      var oneDay = 24 * 60 * 60;
      // console.log({
      //   'startTime': moment(startTime * 1000).format('MM-DD HH:mm'),
      //   'endTime': moment(endTime * 1000).format('MM-DD HH:mm')
      // })
      if (endTime - startTime >= oneDay) {
        return moment(startTime * 1000).format('MM/DD HH:mm') + '~' + moment(endTime * 1000).format('MM/DD HH:mm');
      } else {
        return moment(startTime * 1000).format('HH:mm') + '~' + moment(endTime * 1000).format('HH:mm');
      }
    }
  })
};