import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { setBoxContentStyle } from '../handleStyle';
export default {
  name: 'ItemBox',
  props: {
    // 序号  text
    num: {
      type: [String, Number],
      default: 0
    },
    // 是否显示序号
    isShowNum: {
      type: [Boolean, Number],
      default: true
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    editFormatList: function editFormatList(state) {
      return state.editFormat.list;
    }
  })), {}, {
    boxContentStyle: function boxContentStyle() {
      return setBoxContentStyle(this.editFormatList.host_json);
    }
  })
};