import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import utils from '@/utils/utils';
export default {
  name: 'TreeTable',
  props: {
    // 权限
    auth: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 是否禁用
    isDisable: {
      type: Boolean,
      default: false
    },
    // 区分 开盘助手！   后台
    type: {
      type: String,
      default: 'admin'
    }
  },
  data: function data() {
    return {
      hasChildren: true,
      // 是否全选
      isCheckAll: false,
      numbers: 0,
      // 是否手机端
      isPC: utils.isPC()
    };
  },
  methods: {
    /**
     * @description 右侧选择
     * @param {IsMenu == 2}查看权限特殊标识
     * @description
     * */
    checkboxChangeRight: function checkboxChangeRight(row, item) {
      var isChecked = item.isChecked;
      // IsMenu == 2  参看权限
      if (item.IsMenu == 2) {
        item.isDisabled = false;
        item.isChecked = isChecked;
      } else {
        ischeckAndIsDisbale(row);
      }
      function ischeckAndIsDisbale(row) {
        if (isChecked) {
          // 当当前勾选为true 查看权限必定为true
          row && row.AuthList && row.AuthList.forEach(function (element) {
            if (element.IsMenu == 2) {
              element.isChecked = true;
              element.isDisabled = true;
            }
          });
        } else {
          // 如果 除开查看权限所有都为false 取消查看权限的🈲用
          var booleanArr = new Set();
          row && row.AuthList && row.AuthList.forEach(function (element) {
            if (element.IsMenu != 2) {
              booleanArr.add(element.isChecked);
            }
          });
          if (booleanArr.size > 1) {
            // true false 都存在
            row && row.AuthList && row.AuthList.forEach(function (element) {
              if (element.IsMenu == 2) {
                element.isChecked = true;
                element.isDisabled = true;
              }
            });
          } else {
            // 全部为false
            row && row.AuthList && row.AuthList.forEach(function (element) {
              if (element.IsMenu == 2) {
                element.isDisabled = false;
              }
            });
          }
        }
      }

      // 每次都重置一下
      this.numbers = 0;
      this.$emit('handleNumber', this.getNumber(this.auth), this.type);
    },
    //   获取选中数量
    getNumber: function getNumber(data) {
      var _this = this;
      data.forEach(function (item) {
        item.AuthList && item.AuthList.forEach(function (authItem) {
          if (authItem.isChecked) {
            _this.numbers++;
          }
        });
        if (item.Children && item.Children.length > 0) {
          _this.getNumber(item.Children);
        }
      });
      return this.numbers;
    },
    // 点击全选所有模块
    checkAllModule: function checkAllModule(e) {
      var isChecked = e;
      this.auth && this.auth.forEach(function (item) {
        checkAll(item);
      });
      function checkAll(auth) {
        auth.isChecked = isChecked;
        // 判断当前是否是单权限
        var isSingleAuth = false;
        if (auth.AuthList && auth.AuthList.length == 1) {
          isSingleAuth = true;
        }
        // 改变右侧表格中的checkbox
        auth.AuthList && auth.AuthList.forEach(function (authItem) {
          authItem.isChecked = isChecked;
          if (isChecked) {
            if (authItem.IsMenu == 2 && isSingleAuth == false) {
              authItem.isDisabled = true;
            } else {
              authItem.isDisabled = false;
            }
          } else {
            if (authItem.IsMenu == 2) {
              authItem.isDisabled = false;
            }
          }
        });
        if (auth.Children) {
          auth.Children.forEach(function (element) {
            // 递归处理数据
            checkAll(element);
          });
        }
      }
      this.numbers = 0;
      this.$emit('handleNumber', this.getNumber(this.auth), this.type);
    },
    // 点击全选当前模块
    checkAllModuleCurrent: function checkAllModuleCurrent(e, item, type) {
      var isChecked = e;
      changeAll(item);
      function changeAll(rowData) {
        // 改变checkbox状态
        rowData.isChecked = isChecked;
        // 判断当前是否是单权限
        var isSingleAuth = false;
        if (rowData.AuthList && rowData.AuthList.length == 1) {
          isSingleAuth = true;
        }
        // 改变右侧表格中的checkbox
        rowData.AuthList && rowData.AuthList.forEach(function (authItem) {
          authItem.isChecked = isChecked;
          if (isChecked) {
            if (authItem.IsMenu == 2 && isSingleAuth == false) {
              authItem.isDisabled = true;
            } else {
              authItem.isDisabled = false;
            }
          } else {
            if (authItem.IsMenu == 2) {
              authItem.isDisabled = false;
            }
          }
        });
        // 选择子模块
        if (type != 0) {
          return;
        }
        if (rowData.Children) {
          rowData.Children.forEach(function (element) {
            // 递归处理数据
            changeAll(element);
          });
        }
      }
      this.numbers = 0;
      this.$emit('handleNumber', this.getNumber(this.auth), this.type);
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (row.Num == 0) {
        return 'hiddlen-row-all';
      }
    }
  }
};