import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Api_index from '@/api/index';
import { adminParameter } from '@/api/public';
import { getEventData, getVillaEventData } from '@/api/roomCar';
import eventSelect from '@/components/eventSelect';
import dataInfo_mixin from '../mixins/dataInfo_mixin';
import moment from 'moment';
export default {
  components: {
    eventSelect: eventSelect
  },
  mixins: [dataInfo_mixin],
  props: {
    room: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      parameter: {},
      // 项目配置
      eventList: [],
      // 项目活动列表
      eventData: {},
      // 活动数据
      moment: moment // 时间格式化工具
    };
  },
  mounted: function mounted() {
    var _this = this;
    Api_index.projectLists().then(function (res) {
      var _res$data;
      _this.eventList = ((_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.event) || [];
    });
    adminParameter().then(function (res) {
      _this.parameter = _objectSpread({}, res.data);
    });
  },
  methods: {
    // 场次选择回调
    selectRoundHandle: function selectRoundHandle(e) {
      var _this2 = this;
      var params = {
        id: this.room.id,
        event_id: e.event.id,
        round_id: e.round.id
      };
      if (this.room.cate == 1) {
        getEventData(params).then(function (res) {
          _this2.resHandle(res.data.data);
        });
      } else if (this.room.cate == 2) {
        getVillaEventData(params).then(function (res) {
          _this2.resHandle(res.data.data);
        });
      }
    },
    // 返回数据处理
    resHandle: function resHandle(info) {
      if (!info) return;
      info.archive_list = info.archive_list || [];
      info.contract_list = info.contract_list || [];
      info.order_list = info.order_list || [];
      info.pay_list = info.pay_list || [];
      info.printing_list = info.printing_list || [];
      var list = this.parameter.list;
      this.roomStatus.forEach(function (item) {
        if (item.key === 'order_status') {
          // 认购状态
          item.enable = true;
          if (info.order_status == 0) {
            item.tips = '未认购';
            item.isDone = false;
          } else if (info.order_status == 1) {
            item.tips = '真实认购';
            item.isDone = true;
          } else if (info.order_status == 2) {
            item.tips = '未认购仅变红';
            item.isDone = false;
          }
        } else if (item.key === 'pay_status') {
          // 定金
          item.enable = true;
          if (info.pay_status === 0) {
            item.tips = '未交定';
            item.isDone = false;
          } else if (info.pay_status === 1) {
            item.tips = '已交完';
            item.isDone = true;
          } else if (info.pay_status === 2) {
            item.tips = '已交部分';
            item.isDone = true;
          }
        } else if (item.key === 'printing_status') {
          // 打印认购书
          item.enable = true;
          if (info.printing_status == 0) {
            item.tips = '未打印';
            item.isDone = false;
          } else if (info.printing_status == 1) {
            item.tips = '已打印';
            item.isDone = true;
          }
        } else if (item.key === 'contract_status') {
          // 签约
          item.enable = list.sign_up == 1;
          if (info.contract_status == 0) {
            item.tips = '未签约';
            item.isDone = false;
          } else if (info.contract_status == 1) {
            item.tips = '已签约';
            item.isDone = true;
          }
        } else if (item.key === 'archive_status') {
          // 资料存档
          item.enable = true;
          if (info.archive_status == 0) {
            item.tips = '未存档';
            item.isDone = false;
          } else if (info.archive_status == 1) {
            item.tips = '已存档';
            item.isDone = true;
          }
        }
      });
      this.eventData = _objectSpread({}, info);
    }
  }
};