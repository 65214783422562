import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//

import linkName from '@/components/LinkName/index.vue';
export default {
  name: 'SwitchBtn',
  components: {
    linkName: linkName
  },
  props: {
    isShowSchedule: {
      type: Boolean,
      default: true
    },
    linkName: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      index: 0
    };
  },
  methods: {
    btnChange: function btnChange(index) {
      this.index = index;
      this.$emit('tabClick', this.index);
    }
  }
};