import _toConsumableArray from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { debounce } from '@/utils/debounce';
export default {
  name: 'NewTable',
  props: {
    dialogData: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    // 单条数据
    rowData: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      // left 选中数量
      selectedNum: 0,
      // right 选中数量
      rightSelectedNum: 0,
      form: {
        not_search: '',
        not_level: '',
        round_search: '',
        round_level: '',
        round_is_order: 0,
        not_is_order: 0
      },
      leftChangeArray: [],
      rightChangeArray: []
    };
  },
  methods: {
    // 右侧分类
    changeLeftAssort: function changeLeftAssort(value) {
      this.$emit('handleSearchData', this.form);
    },
    // 添加人员点击行选中
    handleTableRow: debounce(function (row) {
      this.$refs.tableLeft.toggleRowSelection(row); // 设置复选框为未选状态
    }, 200, true),
    // 获取关联选中id
    handleChangeLeft: function handleChangeLeft(row) {
      var ids = [];
      row.forEach(function (item) {
        ids.push(item.id);
      });
      this.LeftIds = ids;
      this.leftChangeArray = row;
      this.selectedNum = row.length;
    },
    // 获取已关联关联选中id
    handleChangeRight: function handleChangeRight(row) {
      var ids = [];
      row.forEach(function (item) {
        ids.push(item.id);
      });
      this.rightIds = ids;
      this.rightChangeArray = row;
      this.rightSelectedNum = row.length;
    },
    // 获取right 选中的id
    handleTableRightRow: debounce(function (row) {
      this.$refs.tableRight.toggleRowSelection(row); // 设置复选框为未选状态
    }, 200, true),
    // 添加到关联
    handleAssociate: function handleAssociate() {
      if (this.leftChangeArray.length <= 0) {
        this.$message({
          type: 'warning',
          message: '你还未选中要关联的数据'
        });
        return;
      }
      if (this.dialogData.round_user_list == null) {
        this.dialogData.round_user_list = this.leftChangeArray;
      } else {
        var _this$dialogData$roun;
        (_this$dialogData$roun = this.dialogData.round_user_list).unshift.apply(_this$dialogData$roun, _toConsumableArray(this.leftChangeArray));
      }
      for (var j = 0; j < this.LeftIds.length; j++) {
        for (var i = 0; i < this.dialogData.not_user_list.length; i++) {
          if (this.LeftIds[j] === this.dialogData.not_user_list[i].id) {
            this.dialogData.not_user_list.splice(i, 1);
            break;
          }
        }
      }

      // 已关联的数量
      this.dialogData.rightLength = this.dialogData.round_user_list.length;
      // 待关联数量
      this.dialogData.leftLength = this.dialogData.not_user_list.length;
      this.$emit('change');
    },
    // 取消关联
    handleAssociateRight: function handleAssociateRight() {
      if (this.rightChangeArray.length <= 0) {
        this.$message({
          type: 'warning',
          message: '你还未选中要关联的数据'
        });
        return;
      }
      if (this.dialogData.not_user_list == null) {
        this.dialogData.not_user_list = this.rightChangeArray;
      } else {
        var _this$dialogData$not_;
        (_this$dialogData$not_ = this.dialogData.not_user_list).unshift.apply(_this$dialogData$not_, _toConsumableArray(this.rightChangeArray));
      }
      for (var j = 0; j < this.rightIds.length; j++) {
        for (var i = 0; i < this.dialogData.round_user_list.length; i++) {
          if (this.rightIds[j] === this.dialogData.round_user_list[i].id) {
            this.dialogData.round_user_list.splice(i, 1);
            break;
          }
        }
      }

      // 已关联的数量
      this.dialogData.rightLength = this.dialogData.round_user_list.length;
      // 待关联数量
      this.dialogData.leftLength = this.dialogData.not_user_list.length;
      this.$emit('change');
    }
  }
};