import { render, staticRenderFns } from "./customTable.vue?vue&type=template&id=33b5d1d0"
import script from "./customTable.vue?vue&type=script&lang=js"
export * from "./customTable.vue?vue&type=script&lang=js"
import style0 from "./customTable.vue?vue&type=style&index=0&id=33b5d1d0&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/bs/repository/project_32/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33b5d1d0')) {
      api.createRecord('33b5d1d0', component.options)
    } else {
      api.reload('33b5d1d0', component.options)
    }
    module.hot.accept("./customTable.vue?vue&type=template&id=33b5d1d0", function () {
      api.rerender('33b5d1d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pledge/components/customTable.vue"
export default component.exports