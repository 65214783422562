import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "/www/wwwroot/bs/repository/project_32/node_modules/core-js/modules/es.array.iterator.js";
import "/www/wwwroot/bs/repository/project_32/node_modules/core-js/modules/es.promise.js";
import "/www/wwwroot/bs/repository/project_32/node_modules/core-js/modules/es.object.assign.js";
import "/www/wwwroot/bs/repository/project_32/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.reflect.apply.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Vue from 'vue';
import 'default-passive-events';
import Cookies from 'js-cookie';
import '@/assets/css/icon/iconfont.css';
import 'fengmap/build/toolBarStyle.css';
import '@/assets/icon/iconfont/iconfont.css';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets
import Element from 'element-ui';
import './styles/element-variables.scss'; // 推荐node版本14,16
import 'element-ui/lib/theme-chalk/index.css'; // 坑一

import '@/styles/index.scss'; // global css
import App from './App';
import store from './store';
import router from './router';
import component from "./components/index";
import _i18n from './lang'; // internationalization
import './icons'; // icon
import './permission'; // permission control
import './utils/error-log'; // error log
import { getProject, getToken } from '@/utils/auth';
import * as filters from './filters'; // global filters
// 时间错 转换
import moment from 'moment';
// 滚动组件
import scroll from 'vue-seamless-scroll';
import numberChange from '@/utils/numberChange';

// 表格优化包
import ELBigdataTable from 'vue-elementui-bigdata-table1';

// import './settingCustom'
import oss from '@/utils/oss';
import Vpermission from '@/directive/permission/index'; // 权限判断指令

Vue.use(ELBigdataTable);
import { NoticeBar } from 'vue-easy-notice-bar';
Vue.use(NoticeBar);
Vue.filter('dateFormat', function (dateStr) {
  var pattern = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD HH:mm:ss';
  if (!dateStr) {
    return '';
  } else {
    return moment(dateStr).format(pattern);
  }
});
Vue.use(numberChange);
function RepairProps(cmp) {
  (cmp.mixins || []).forEach(function (mixin) {
    if (mixin.props && mixin.props.placement) {
      var defaultValue = mixin.props.placement.default;
      mixin.data = new Proxy(mixin.data, {
        apply: function apply(target, thisArg, argArray) {
          var res = Reflect.apply(target, thisArg, argArray);
          return _objectSpread(_objectSpread({}, res || {}), {}, {
            placement: defaultValue
          });
        }
      });
      delete mixin.props.placement;
    }
    if (mixin.mixins && mixin.mixins.length > 0) {
      RepairProps(mixin);
    }
  });
}
RepairProps(Element.DatePicker);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
// 	const {mockXHR} = require('../mock')
// 	mockXHR()
// }

// 获取项目数组
Vue.prototype.getProject = getProject;
Vue.prototype.getToken = getToken;

// 测试
// Vue.prototype.imgUrl = 'https://yxk3-oss.oss-cn-chengdu.aliyuncs.com/'
// Vue.prototype.reqUrl = 'https://yxkcs1.yun-xk.com/pc'
// Vue.prototype.host = 'https://yxkcs1.yun-xk.com/pc'
// Vue.prototype.upload = 'https://yxkcs1.yun-xk.com/pc/upload/img'
// Vue.prototype.wsUrl = 'wss://yxkcs1.yun-xk.com/ws'

// 切换地址时注意切换  utils/request  里面的地址

// 正式
// Vue.prototype.imgUrl = 'https://yxk3-oss.oss-cn-chengdu.aliyuncs.com/'
// Vue.prototype.reqUrl = 'https://kp.yun-xk.com/pc'
// Vue.prototype.host = 'https://kp.yun-xk.com/pc'
// Vue.prototype.upload = 'https://kp.yun-xk.com/pc/upload/img'
// Vue.prototype.wsUrl = 'wss://kp.yun-xk.com/ws'

// 封装
Vue.prototype.imgUrl = process.env.VUE_APP_IMG_URL;
Vue.prototype.reqUrl = process.env.VUE_APP_BASE_API;
Vue.prototype.wordURL = process.env.VUE_APP_UPLOAD_URL;
Vue.prototype.videoURL = process.env.VUE_APP_UPLOAD_VIDEO; // 视频上传地址
Vue.prototype.host = process.env.VUE_APP_HOST;
Vue.prototype.upload = process.env.VUE_APP_UPLOAD;
Vue.prototype.wsUrl = process.env.VUE_APP_WS_URL;
Vue.prototype.driveUrl = process.env.VUE_APP_DRIVE_URL; //身份证识别驱动下载地址
Vue.prototype.sizes = [10, 20, 30, 100, 200, 300, 500, 1000, 2000];
Vue.prototype.layouts = 'total, prev, pager, next, sizes, jumper';
Vue.prototype.replaceImgshow = oss.replaceImgshow;
Vue.use(Element, {
  size: Cookies.get('size') || 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.use(scroll);

// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});

/* 定义指令*/
//  全局处理 字段 null undefined
Vue.directive('isNull', {
  bind: function bind(el, binding) {
    if (binding.value == null || binding.value == undefined || binding.value == 'undefined') {
      el.innerText = '';
    } else {
      el.innerText = binding.value;
    }
  },
  update: function update(el, binding) {
    if (binding.value == null || binding.value == undefined || binding.value == 'undefined') {
      el.innerText = '';
    } else {
      el.innerText = binding.value;
    }
  }
});
// 全局按钮防止双击指令
Vue.directive('preventReClick', {
  inserted: function inserted(el, binding) {
    el.addEventListener('click', function () {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(function () {
          el.disabled = false;
        }, binding.value || 1500);
      }
    });
  }
});
Vue.directive('permission', Vpermission);
Vue.config.productionTip = false;

// 全局组件注册
component.install();
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});
export default Vue;