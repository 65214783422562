import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import API from '@/api/price';
import CellGroup from '@/components/cell-group';
import CellItem from '@/components/cell-item';
export default {
  name: 'PriceDefault',
  data: function data() {
    return {
      audit_event: {},
      pay: {},
      return_info: {},
      user: {},
      priceStatus: ['未付款', '已付款', '部分付款']
    };
  },
  computed: {
    urlParams: {
      get: function get() {
        return this.$route.query;
      }
    }
  },
  created: function created() {
    this.getDetails();
  },
  methods: {
    // 获取详情
    getDetails: function getDetails() {
      var _this = this;
      var params = _objectSpread({}, this.urlParams);
      API.detail(params).then(function (res) {
        var _res$data$info = res.data.info,
          audit_event = _res$data$info.audit_event,
          pay = _res$data$info.pay,
          return_info = _res$data$info.return_info,
          user = _res$data$info.user;
        _this.audit_event = audit_event || {};
        _this.pay = pay || {};
        _this.return_info = return_info || {};
        _this.user = user || {};
      });
    },
    // 查看用户信息
    lookUserInfo: function lookUserInfo() {
      this.$router.push({
        name: 'RegisterUserInfo',
        query: {
          id: this.user.id
        }
      });
    }
  },
  components: {
    CellGroup: CellGroup,
    CellItem: CellItem
  }
};