import { render, staticRenderFns } from "./postList.vue?vue&type=template&id=4c7c05e3&scoped=true"
import script from "./postList.vue?vue&type=script&lang=js"
export * from "./postList.vue?vue&type=script&lang=js"
import style0 from "./postList.vue?vue&type=style&index=0&id=4c7c05e3&lang=scss"
import style1 from "./postList.vue?vue&type=style&index=1&id=4c7c05e3&scoped=true&lang=scss"
import style2 from "./postList.vue?vue&type=style&index=2&id=4c7c05e3&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c7c05e3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/bs/repository/project_32/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4c7c05e3')) {
      api.createRecord('4c7c05e3', component.options)
    } else {
      api.reload('4c7c05e3', component.options)
    }
    module.hot.accept("./postList.vue?vue&type=template&id=4c7c05e3&scoped=true", function () {
      api.rerender('4c7c05e3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/staffManage/components/postList.vue"
export default component.exports