import { render, staticRenderFns } from "./currentTime.vue?vue&type=template&id=b9552b48&scoped=true"
import script from "./currentTime.vue?vue&type=script&lang=js"
export * from "./currentTime.vue?vue&type=script&lang=js"
import style0 from "./currentTime.vue?vue&type=style&index=0&id=b9552b48&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9552b48",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/bs/repository/project_32/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b9552b48')) {
      api.createRecord('b9552b48', component.options)
    } else {
      api.reload('b9552b48', component.options)
    }
    module.hot.accept("./currentTime.vue?vue&type=template&id=b9552b48&scoped=true", function () {
      api.rerender('b9552b48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/screenRight/components/currentTime.vue"
export default component.exports