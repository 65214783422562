import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import roundsBtn from './roundsBtn.vue';
import NoticeNav from '@/views/editFormat/components/NoticeNav.vue';
import { mapState } from 'vuex';
export default {
  name: 'ProjectBottom',
  components: {
    roundsBtn: roundsBtn,
    NoticeNav: NoticeNav
  },
  props: {
    // 底部文字
    tipText: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 按钮是否居中
    flexPos: {
      type: String,
      default: 'space-between'
    },
    // 左右边距
    sMargin: {
      type: Number,
      default: 140
    },
    isShowTip: {
      type: Boolean,
      default: true
    }
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    editFormatList: function editFormatList(state) {
      return state.editFormat.list;
    }
  })), {}, {
    // 显示数组列表
    showTextList: function showTextList() {
      var _this$tipText;
      if (!this.tipText || ((_this$tipText = this.tipText) === null || _this$tipText === void 0 ? void 0 : _this$tipText.length) === 0) return [];
      var arr = [];
      // 取得父级中的设置
      var _this$$parent = this.$parent,
        rand = _this$$parent.rand,
        roundNumber = _this$$parent.roundNumber;
      for (var i = 0; i < this.tipText.length; i++) {
        var items = this.tipText[i];
        var content = '';
        for (var _i = 0; _i < ((_items$list = items.list) === null || _items$list === void 0 ? void 0 : _items$list.length); _i++) {
          var _items$list;
          var item = items === null || items === void 0 ? void 0 : items.list[_i];
          // 显示名字
          if (rand.show_name !== 0) {
            // 名称全显示 或 脱敏显示
            var name = rand.show_name === 2 ? item.name : this.nickName(item.name);
            var elses = '';
            if (rand.show_audit_order === 1) elses += "".concat(item.code, ",");
            if (rand.show_tel === 1) elses += "".concat(this.getTel(item.tel), ",");
            if (rand.show_card > 0) elses += "".concat(this.getCard(item.card, rand.show_card), ",");

            // 用户名套其他信息
            content += elses !== '' ? "".concat(name, "(").concat(elses, ");") : "".concat(name, ";");
          }
          // 不显示名字但显示诚意单号
          else if (rand.show_audit_order === 1) {
            var _name = item.code;
            var _elses = '';
            if (rand.show_tel === 1) _elses += "".concat(this.getTel(item.tel), ",");
            if (rand.show_card > 0) _elses += "".concat(this.getCard(item.card, rand.show_card), ",");

            // 诚意单号套其他信息
            content += _elses !== '' ? "".concat(_name, "(").concat(_elses, ");") : "".concat(_name, ";");
          }
          // 以上两种都不存在
          else {
            var _name2 = item.tel;
            var _elses2 = '';
            if (rand.show_card > 0) _elses2 += "".concat(this.getCard(item.card, rand.show_card), ",");

            // 手机号套身份证号
            content += _elses2 !== '' ? "".concat(_name2, "(").concat(_elses2, ");") : "".concat(_name2, ";");
          }
        }
        content = content.replace(/\,{2,}/ig, ','); // 替换连续逗号
        content = content.replace(/\(\,/ig, '('); // 删除无数据但是给拼了个的逗号
        content = content.replace(/\,\)/ig, ')'); // 删除最后的逗号
        content = content.replace(/\(\)/ig, ''); // 删除里面没内容的小括号

        arr.push({
          title: "\u8BF7\u7B2C".concat(items.round + roundNumber, "\u5BA2\u6237\u51C6\u5907:"),
          content: content
        });
      }
      return arr;
    }
  }),
  data: function data() {
    return {
      timer: null,
      trans: 0,
      isMove: false
    };
  },
  methods: {
    nickName: function nickName(name) {
      if (!name) return '';
      if (name.length < 3) return name[0] + '*';
      if (name.length > 2) return name[0] + new Array(name.length - 2).fill('*').join('') + name[name.length - 1];
    },
    //  手机正则
    getTel: function getTel(tel) {
      if (!tel) return '';
      var reg = /^(\d{3})\d{4}(\d{4})$/;
      return tel.replace(reg, '$1****$2');
    },
    // 身份证
    getCard: function getCard(card, show_card) {
      // 隐藏身份证中间
      if (show_card === 1) {
        if (card.length > 15) {
          return card.replace(/^(.{4})(?:\d+)(.{4})$/, '$1******$2');
        } else {
          return card.replace(/^(.{1})(?:\d+)(.{1})$/, '$1******$2');
        }
      }
      // 只显示最后六个号码
      else {
        return card.substring(card.length - 6);
      }
    }
  },
  updated: function updated() {
    var _this = this;
    this.$nextTick(function () {
      if (_this.isShowTip) {
        return;
        clearTimeout(_this.timer);
        _this.timer = setTimeout(function () {
          for (var i = 0; i < _this.showTextList.length; i++) {
            var t_el = _this.$refs["tip-swiper-content-".concat(i)][0];
            var t_w = t_el.offsetWidth;
            _this.trans = t_w;
            _this.isMove = false;
            if (t_w > _this.$refs["tip-".concat(i)][0].offsetWidth - 320) {
              _this.isMove = true;
            }
          }
        }, 500);
      }
    });
  }
};