import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vanSwitch from '@/components/switch';
import kindEditor from '@/components/kindEditor';
import formData from './components/formEdit.vue';
import inputText from '@/components/inputText';
import CloseProject from "./components/CloneProject.vue";
import linkSetup from "./components/linkSetup.vue";
import fundsForm from "./components/fundsForm.vue";
import { areaList, formList, auditEvent, saveAuditEvent, addTitle, editTitle, delTitle, listSort, syncSystem, adminSetting, editPlateSave, areaPleadgeList, formPleadgeList, delPleadgeTitle, formGetParent, formGetHide, down, raifromList, addideform, delideform, raiareaPleadgeList, raidelPleadgeTitle } from '@/api/sincerityRegister';
import Oss from "@/utils/oss";
export default {
  name: 'SincerityRegister',
  components: {
    vanSwitch: vanSwitch,
    kindEditor: kindEditor,
    formData: formData,
    inputText: inputText,
    CloseProject: CloseProject,
    linkSetup: linkSetup,
    fundsForm: fundsForm
  },
  data: function data() {
    return {
      times: 0,
      showClone: false,
      sign_start_time: '',
      sign_end_time: '',
      price_start_time: '',
      price_end_time: '',
      //  认筹区域列表
      pleadge: [],
      // 认筹区域第一个id
      areaPLeadgeID: 0,
      start_time: '',
      end_time: '',
      // 表格计算
      appHeight: '',
      topHeight: '',
      // switch 按钮数据
      switchList: [{
        title: '基本信息',
        id: 0,
        num: ''
      }, {
        title: '报名表单配置',
        id: 1,
        num: ''
      }, {
        title: '认筹表单配置',
        id: 2,
        num: ''
      }, {
        title: '升筹表单配置',
        id: 3,
        num: ''
      }],
      // swit 按钮默认值
      btnIndex: 0,
      // 表格切换
      chooseRadio: 0,
      // 修改还是保存
      type: true,
      //  所选时间
      timeSelect: [],
      // 新增题目
      addNewTitleDialogVisible: false,
      // 表单配置二级标题
      area: [],
      // 报名当前选中表格类型
      reg_selectType: 0,
      // 认筹当前选中表格类型
      ide_selectType: 0,
      // 二级标题校验
      newAreaForm: {
        area: []
      },
      // 表格数据
      formListTable: [],
      // 基本信息
      auditEventInfo: {
        notice_text: '',
        content: '',
        collect_range: 0
      },
      // 登录背景图
      login_image: '',
      // 增加题目form
      addTitleForm: {
        // 名称
        name: '',
        // 表单类型
        form_type: 'radio',
        // 区域id
        display_area_id: 0,
        // 提示语句
        placeholder: '单选/多选',
        // 图片最少限制
        img_number: 1,
        // 是否需要限制
        is_verification: 0,
        // 配置选项
        children: [{
          name: '',
          select_value: 0
        }, {
          name: '',
          select_value: 0
        }],
        // 是否必填
        is_required: 0,
        // 父级题目id
        parent_pid: 0,
        // 父级题目选项集id
        parent_id: [],
        // 隐藏id
        hide_id: 0,
        parent_cause_show: 1,
        is_document: 0,
        is_parent_pid: false,
        // 特殊说明
        special_value: ""
        // is_special:0
      },
      addTitleFormRules: {
        name: [{
          required: true,
          message: '题目名称为必填项',
          trigger: 'blur'
        }]
      },
      // 0 增加题目 1修改题目
      addType: 0,
      // 增加 修改题目 文字
      addNewTitleText: '增加题目',
      // 图片预览弹框
      imgPreviewDialogVisible: false,
      // 是否隐藏审核附表
      hiddenProperty: true,
      // 是否隐藏上传按钮
      hiddenUp: false,
      // 图片文件
      fileList: [],
      // saas配置
      saasSetting: {
        audit: {}
      },
      // 编辑板块弹框
      isEditPlate: false,
      //  认筹表格数据
      pleadgeFormList: [],
      ide_start_time: '',
      ide_end_time: '',
      audit: false,
      is_register: false,
      is_identify: false,
      is_identify_sign: false,
      is_identify_price: false,
      // 父级表单
      rarentList: {
        form: [],
        option: []
      },
      hideList: [],
      isM: false,
      // 是手机 默认false
      cellectList: [{
        label: 1,
        text: '已提交报名'
      }, {
        label: 0,
        text: '已通过报名'
      }, {
        label: 2,
        text: '已通过认筹'
      },
      // { label: 3, text: '已支付认筹金' },
      {
        label: 4,
        text: '已签约'
      }, {
        label: 5,
        text: '已派号'
      }],
      list: {},
      auditconfig: {},
      //  升筹区域列表
      raipleadge: [],
      // 升筹区域第一个id
      raiareaPLeadgeID: 0,
      //  深筹表格数据
      raipleadgeFormList: [],
      // 认筹当前选中表格类型
      raiide_selectType: 0
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    // 获取saas端配置
    this.getSaasSetting();
    this.watchScreen();
    this.appHeight = this.$refs.app.offsetHeight;
    this.topHeight = this.$refs.apptop.offsetHeight;
    window.onresize = function () {
      _this.appHeight = _this.$refs.app.offsetHeight;
      _this.topHeight = _this.$refs.apptop.offsetHeight;
    };

    // 活动配置权限
    this.getAuditEvent();
    this.getAreaList();
  },
  methods: {
    // 刷新
    re: function re() {
      // 活动配置权限
      this.getAuditEvent();
      this.getAreaList();
    },
    watchScreen: function watchScreen() {
      var _this2 = this;
      var doc = document.getElementById('app');
      this.isM = doc.offsetWidth <= 470;
      window.addEventListener('resize', function () {
        _this2.isM = doc.offsetWidth <= 470;
      });
    },
    exportReview: function exportReview() {
      var _this3 = this;
      down().then(function (result) {
        var url = _this3.host + result.data.path;
        var dom = document.createElement('a');
        dom.style.display = 'none';
        dom.href = url;
        document.body.appendChild(dom);
        dom.click();
        document.body.removeChild(dom);
      });
    },
    handleOnlineSign: function handleOnlineSign(e) {
      this.auditEventInfo.sign_time_min = e;
    },
    // 刷新 tab
    handleTabs: function handleTabs(e) {
      this.getFormPledgeList(e);
    },
    handleraiTabs: function handleraiTabs(e) {
      this.getraiFormPledgeList(e);
    },
    // 认筹table删除
    handleDelTable: function handleDelTable(id) {
      var _this4 = this;
      var params = {
        id: id
      };
      this.$confirm('是否确认删除当前题目？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        delPleadgeTitle(params).then(function (res) {
          _this4.$message({
            type: 'success',
            message: res.data.msg
          });
          _this4.getFormPledgeList(_this4.areaPLeadgeID);
        });
      }).catch(function () {
        return;
      });
    },
    // 升筹table删除
    handleraiDelTable: function handleraiDelTable(id) {
      var _this5 = this;
      var params = {
        id: id
      };
      this.$confirm('是否确认删除当前题目？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log(params, 'params');
        raidelPleadgeTitle(params).then(function (res) {
          _this5.$message({
            type: 'success',
            message: res.data.msg
          });
          _this5.getraiFormPledgeList(_this5.areaPLeadgeID);
        });
      }).catch(function () {
        return;
      });
    },
    // 验证
    handleEndTime: function handleEndTime(start, end) {
      return;
      if (this[start] > this[end]) {
        this.$message.warning('结束时间必须大于开始时间');
        this[end] = this[start] > new Date().getTime() ? this[start] + 1000 * 60 * 60 : new Date().getTime();
      }
    },
    // 按钮切换
    bindSwitch: function bindSwitch(index) {
      this.btnIndex = index;
    },
    // 二级按钮切换
    chooseRadioChange: function chooseRadioChange(e) {
      this.addTitleForm.display_area_id = e;
      this.getFormList(e);
    },
    clone: function clone() {
      this.showClone = true;
    },
    // 修改
    editMes: function editMes() {
      if (this.type) {
        this.type = !this.type;
      } else {
        this.saveBaseMes();
      }
    },
    // 同步认筹系统
    submitSyncSystem: function submitSyncSystem() {
      var _this6 = this;
      syncSystem().then(function (res) {
        _this6.$message.success(res.data.msg);
        _this6.re();
      });
    },
    // 隐藏无子集的icon
    getRowClassName: function getRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (!row.children) {
        return 'row-expand-cover';
      }
    },
    // 移动
    moveItem: function moveItem(index, row, type) {
      var _this7 = this;
      // index 当前位置 row 当前数据
      switch (type) {
        case 0:
          // 上
          if (index == 0) {
            this.$message({
              type: 'warning',
              message: '已经是第一个了'
            });
            return;
          } else {
            var index1 = index;
            var index2 = index - 1;
            move.call(this, index1, index2);
          }
          break;
        case 1:
          // 下
          if (index == this.formListTable.length - 1) {
            this.$message({
              type: 'warning',
              message: '已经是最后一个了'
            });
            return;
          } else {
            var _index = index;
            var _index2 = index + 1;
            move.call(this, _index, _index2);
          }
          break;
        case 2:
          // 移到最前面
          if (index == 0) {
            this.$message({
              type: 'warning',
              message: '已经是第一个了'
            });
            return;
          } else {
            var arr = this.formListTable;
            var _index3 = index;
            var _index4 = 0;
            arr.unshift.apply(arr, _toConsumableArray(arr.splice(_index3, 1)));
          }
          break;
        case 3:
          // 移到最后面
          if (index == this.formListTable.length - 1) {
            this.$message({
              type: 'warning',
              message: '已经是最后一个了'
            });
            return;
          } else {
            var _index5 = index;
            var _index6 = this.formListTable.length;
            move.call(this, _index5, _index6);
          }
          break;
        default:
          break;
      }

      // 2个元素交换位置
      function move(index1, index2) {
        var arr = this.formListTable;
        arr.splice.apply(arr, [index1, 1].concat(_toConsumableArray(arr.splice(index2, 1, arr[index1]))));
        this.formListTable = arr;
      }
      var sortParams = {
        form: []
      };
      this.formListTable && this.formListTable.forEach(function (item, index) {
        sortParams.form.push({
          id: item.id,
          sort: index + 1
        });
      });
      listSort(sortParams).then(function (res) {
        _this7.$message({
          type: 'success',
          message: '保存成功'
        });
      }).catch(function () {});
    },
    verifyMinNumber: function verifyMinNumber(e) {
      var _this$addTitleForm = this.addTitleForm,
        img_number = _this$addTitleForm.img_number,
        min_number = _this$addTitleForm.min_number;
      if (img_number < min_number) {
        this.addTitleForm.img_number = img_number + 1;
        this.queueMsg('最大意向不能小于最小意向');
        return;
      }
    },
    // 队列消息 防止消息一起出现 出现遮盖的问题
    queueMsg: function queueMsg(msg) {
      var _this8 = this;
      var status = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'error';
      var d = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
      setTimeout(function () {
        return _this8.$message[status](msg);
      }, d);
    },
    // 增加题目
    addNewTitle: function addNewTitle(type, row) {
      // 意向房源
      if (row.owner_type === 70) row.form_type = 'popup';
      this.addType = type;
      if (row != 0) {
        this.addNewTitleText = '修改题目';
        this.doGetParent();
        row.is_parent_pid = row.parent_pid !== 0;
        this.addTitleForm = JSON.parse(JSON.stringify(row));
      } else {
        this.doGetParent();
        this.addNewTitleText = '增加题目';
        this.resetForm();
      }
      this.addNewTitleDialogVisible = true;
    },
    // 获取父级题目
    doGetParent: function doGetParent() {
      var _this9 = this;
      formGetHide().then(function (res) {
        _this9.hideList = res.data.list || [];
      });
      this.rarentList = {
        form: [],
        option: []
      };
      // 点当前版块类型  0：普通，1：产权人
      var type = 0;
      for (var i = 0; i < this.area.length; i++) {
        if (this.chooseRadio === this.area[i].id) {
          type = this.area[i].type === 1 ? 1 : 0;
        }
      }
      formGetParent({
        is_owner: type
      }).then(function (res) {
        _this9.rarentList = {
          form: res.data.list || [],
          option: []
        };
        for (var _i = 0; _i < _this9.rarentList.form.length; _i++) {
          if (_this9.addTitleForm.parent_pid === _this9.rarentList.form[_i].id) {
            _this9.rarentList.option = _this9.rarentList.form[_i].son;
            break;
          }
        }
      });
    },
    onParent_pidChange: function onParent_pidChange(e) {
      for (var i = 0; i < this.rarentList.form.length; i++) {
        if (this.addTitleForm.parent_pid === this.rarentList.form[i].id) {
          this.rarentList.option = this.rarentList.form[i].son;
          this.addTitleForm.parent_id = "";
          break;
        }
      }
    },
    // 改变类型
    textTypeChange: function textTypeChange(str) {
      // 表单类型 (text)文本 (file)图片 (number)数字 (radio)单选 (select)多选
      switch (str) {
        case 'text':
          return '文本';
        case 'file':
          return '图片';
        case 'number':
          return '数字';
        case 'radio':
          return '单选';
        case 'select':
          return '多选';
        case 'hide':
          return '隐藏表单';
        case 'popup':
          return '弹窗';
        default:
          return str;
      }
    },
    // 文件上传
    imgupSuccess: function imgupSuccess(e) {
      this.login_image = e.Data.url;
      this.hiddenUp = true;
    },
    // 添加选项
    addNewOptions: function addNewOptions() {
      if (this.addTitleForm.children) {
        this.addTitleForm.children.push({
          name: '',
          select_value: 0
        });
      } else {
        var data = _objectSpread({}, this.addTitleForm);
        data.children = [{
          name: '',
          select_value: 0
        }];
        this.addTitleForm = data;
      }
    },
    // 删除选项
    delNewOptions: function delNewOptions(item) {
      var _this10 = this;
      if (this.addType == 1) {
        var params = {
          id: item.id
        };
        delTitle(params).then(function (res) {
          _this10.$message({
            type: 'success',
            message: res.data.msg
          });
          var index = _this10.addTitleForm.children.indexOf(item);
          if (index !== -1) {
            _this10.addTitleForm.children.splice(index, 1);
          }
          _this10.getFormList(_this10.chooseRadio);
        });
      } else {
        var index = this.addTitleForm.children.indexOf(item);
        if (index !== -1) {
          this.addTitleForm.children.splice(index, 1);
        }
      }
    },
    // 保存提交
    saveBaseMes: function saveBaseMes() {
      var _this11 = this;
      if (this.is_identify_sign && (!this.auditEventInfo.sign_time_min || +this.auditEventInfo.sign_time_min <= 0) && this.auditEventInfo.is_sign_time == 1) {
        this.$message({
          type: 'warning',
          message: '请填写签约时长！'
        });
        return;
      }
      if (!this.$refs.linksetup.verify()) {
        return;
      }
      var objTime = {};
      for (var key in this.auditEventInfo) {
        if (key.includes('_time') && (key.includes('_start') || key.includes('_end'))) {
          objTime[key] = this.auditEventInfo[key] / 1000;
        }
      }
      objTime.start_time = this.auditEventInfo.start_time / 1000;
      objTime.end_time = this.auditEventInfo.end_time / 1000;
      var params = _objectSpread(_objectSpread(_objectSpread({}, this.auditEventInfo), {}, {
        notice_text: this.$refs['kindEditor1'].outContent,
        content: this.$refs['kindEditor2'].outContent,
        inform_text: this.$refs['kindEditor3'].outContent,
        login_image: this.login_image,
        sign_time_min: +this.auditEventInfo.sign_time_min,
        bus_ide_price: +this.auditEventInfo.bus_ide_price,
        car_ide_price: +this.auditEventInfo.car_ide_price,
        room_ide_price: +this.auditEventInfo.room_ide_price
      }, objTime), {}, {
        // 是否显示编号
        reg_no_show: this.auditEventInfo.reg_no_show || 0,
        ide_no_show: this.auditEventInfo.ide_no_show || this.auditEventInfo.ide_no_show === 0 ? this.auditEventInfo.ide_no_show : 1
      });
      // 登记驳回重复提交时间
      if (this.auditEventInfo.reg_repeat_hour < 0) {
        this.auditEventInfo.reg_repeat_hour = 0;
      }
      this.auditEventInfo.reg_repeat_hour = parseInt(this.auditEventInfo.reg_repeat_hour);
      if (this.auditEventInfo.reg_repeat_min < 0) {
        this.auditEventInfo.reg_repeat_min = 0;
      }
      this.auditEventInfo.reg_repeat_min = parseInt(this.auditEventInfo.reg_repeat_min);
      if (this.auditEventInfo.reg_repeat_min >= 60) {
        this.auditEventInfo.reg_repeat_hour += parseInt(this.auditEventInfo.reg_repeat_min / 60);
        this.auditEventInfo.reg_repeat_min = this.auditEventInfo.reg_repeat_min % 60;
      }
      // 认筹驳回重复提交时间
      if (this.auditEventInfo.ide_repeat_hour < 0) {
        this.auditEventInfo.ide_repeat_hour = 0;
      }
      this.auditEventInfo.ide_repeat_hour = parseInt(this.auditEventInfo.ide_repeat_hour);
      if (this.auditEventInfo.ide_repeat_min < 0) {
        this.auditEventInfo.ide_repeat_min = 0;
      }
      this.auditEventInfo.ide_repeat_min = parseInt(this.auditEventInfo.ide_repeat_min);
      if (this.auditEventInfo.ide_repeat_min >= 60) {
        this.auditEventInfo.ide_repeat_hour += parseInt(this.auditEventInfo.ide_repeat_min / 60);
        this.auditEventInfo.ide_repeat_min = this.auditEventInfo.ide_repeat_min % 60;
      }
      params.reg_repeat_hour = this.auditEventInfo.reg_repeat_hour;
      params.reg_repeat_min = this.auditEventInfo.reg_repeat_min;
      params.ide_repeat_hour = this.auditEventInfo.ide_repeat_hour;
      params.ide_repeat_min = this.auditEventInfo.ide_repeat_min;
      if (this.is_identify) {
        // if (params.ide_start_time < params.start_time) {
        //   this.$message.warning('认筹开始不能早于报名开始时间')
        //   return
        // }
      }
      var sortParams = {
        form: []
      };
      this.formListTable && this.formListTable.forEach(function (item, index) {
        sortParams.form.push({
          id: item.id,
          sort: index + 1
        });
      });
      var p1 = new Promise(function (resolve, reject) {
        listSort(sortParams).then(function (res) {
          resolve();
        }).catch(function () {
          reject();
        });
      });
      var p2 = new Promise(function (resolve, reject) {
        saveAuditEvent(params).then(function (res) {
          _this11.type = !_this11.type;
          resolve();
        }).catch(function () {
          reject();
        });
      });
      Promise.all([p1, p2]).then(function () {
        _this11.$message({
          type: 'success',
          message: '保存成功'
        });
      });
    },
    // 添加题目保存
    addNewTitleSave: function addNewTitleSave() {
      var _this12 = this;
      var params = _objectSpread({}, this.addTitleForm);

      // 不关联父级字段
      if (!params.is_parent_pid) {
        params.parent_pid = 0;
        params.parent_id = [];
      }
      if (params.parent_pid && (!params.parent_id || !params.parent_id.length)) {
        this.$message({
          type: 'warning',
          message: '请选择父级题目选项！'
        });
        return;
      }
      if (params.form_type == 'radio' || params.form_type == 'select') {
        if (params.children && params.children.length < 2) {
          this.$message({
            type: 'warning',
            message: '至少有两个选项'
          });
          return;
        } else if (!params.children) {
          this.$message({
            type: 'warning',
            message: '至少有两个选项'
          });
          return;
        } else {
          params.children.forEach(function (item, i) {
            item.sort = i + 1;
          });
        }
      } else {
        if (params.children) {
          delete params.children;
        }
      }
      if (params.form_type === 'hide') {
        params.is_required = 1;
      }
      var p1 = new Promise(function (resolve, reject) {
        _this12.$refs['addTitleForm'].validate(function (valid) {
          if (valid) {
            resolve();
          } else {
            reject();
          }
        });
      });
      var p2 = new Promise(function (resolve, reject) {
        if (params.form_type == 'radio' || params.form_type == 'select') {
          _this12.$refs['addTitleFormChildren'].validate(function (valid) {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        } else {
          resolve();
        }
      });
      Promise.all([p1, p2]).then(function (res) {
        if (_this12.addType == 0) {
          // 增加题目
          params.display_area_id = _this12.chooseRadio;
          addTitle(params).then(function (res) {
            _this12.$message({
              type: 'success',
              message: res.data.msg
            });
            _this12.getFormList(_this12.chooseRadio);
            _this12.addNewTitleDialogVisible = false;
          });
        } else {
          // 修改题目
          editTitle(params).then(function (res) {
            _this12.$message({
              type: 'success',
              message: res.data.msg
            });
            _this12.getFormList(params.display_area_id);
            _this12.addNewTitleDialogVisible = false;
          });
        }
      });
    },
    // 题目类型改变
    titleTypeChange: function titleTypeChange(e) {
      if (this.addType === 0) {
        switch (e) {
          case 'radio':
            this.addTitleForm.placeholder = '单选/多选';
            break;
          case 'select':
            this.addTitleForm.placeholder = '单选/多选';
            break;
          case 'text':
            this.addTitleForm.placeholder = '';
            break;
          case 'number':
            this.addTitleForm.placeholder = '';
            break;
          case 'file':
            this.addTitleForm.placeholder = '请上传图片';
            break;
        }
      }
    },
    // resetForm 重置表单
    resetForm: function resetForm() {
      this.addTitleForm = {
        // 名称
        name: '',
        // 表单类型
        form_type: 'radio',
        // 区域id
        display_area_id: 0,
        // 提示语句
        placeholder: '单选/多选',
        // 图片最少限制
        img_number: 1,
        // 是否需要限制
        is_verification: 0,
        // 配置选项
        children: [{
          name: '',
          select_value: 0
        }, {
          name: '',
          select_value: 0
        }],
        // 是否必填
        is_required: 0,
        // 父级题目id
        parent_pid: 0,
        // 隐藏表单
        hide_id: 0,
        is_document: 0,
        is_parent_pid: false,
        // 父级题目选项集id
        parent_id: [],
        // 特殊说明
        special_value: ""
        // is_special:0
      };
    },
    // 删除
    delTitleItem: function delTitleItem(id) {
      var _this13 = this;
      var params = {
        id: id
      };
      this.$confirm('是否确认删除当前题目？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        delTitle(params).then(function (res) {
          _this13.$message({
            type: 'success',
            message: res.data.msg
          });
          _this13.getFormList(_this13.chooseRadio);
        });
      }).catch(function () {
        return;
      });
    },
    // 弹框关闭
    addNewTitleDialogClose: function addNewTitleDialogClose() {
      var _this$$refs$addTitleF, _this$$refs$addTitleF2;
      // 重置表单
      (_this$$refs$addTitleF = this.$refs['addTitleFormChildren']) === null || _this$$refs$addTitleF === void 0 || _this$$refs$addTitleF.resetFields();
      (_this$$refs$addTitleF2 = this.$refs['addTitleForm']) === null || _this$$refs$addTitleF2 === void 0 || _this$$refs$addTitleF2.resetFields();
    },
    // tableCheckboxChange
    tableCheckboxChange: function tableCheckboxChange(e, row) {
      var _this14 = this;
      var params = _objectSpread({}, row);
      editTitle(params).then(function (res) {
        _this14.$message({
          type: 'success',
          message: res.data.msg
        });
      });
    },
    // 预览图片
    previewImg: function previewImg() {
      this.imgPreviewDialogVisible = true;
    },
    // 删除图片
    handleRemove: function handleRemove() {
      this.fileList = [];
      this.hiddenUp = false;
    },
    // 预览图片
    handlePictureCardPreview: function handlePictureCardPreview() {
      this.imgPreviewDialogVisible = true;
    },
    handleUpload: function handleUpload(file) {
      Oss.onUploadFile(file, "public/pcimg/$id/".concat(new Date().getTime(), "-").concat(file.file.name), file.file.name);
    },
    // 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      // 限制大小
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传大小不能超过 2MB!');
      }
      return isLt2M;
    },
    // 选项排序
    btnArrow: function btnArrow(index, item, type) {
      var arr = this.addTitleForm;
      if (type === 0) {
        // 上
        if (index == 0) {
          this.$message({
            type: 'warning',
            message: '已经是第一个了'
          });
          return;
        } else {
          var index1 = index;
          var index2 = index - 1;
          move.call(this, index1, index2, arr.children);
        }
      }
      if (type === 1) {
        // 下
        if (index == arr.children.length - 1) {
          this.$message({
            type: 'warning',
            message: '已经是最后一个了'
          });
          return;
        } else {
          var _index7 = index;
          var _index8 = index + 1;
          move.call(this, _index7, _index8, arr.children);
        }
      }
      function move(index1, index2, arr) {
        arr.splice.apply(arr, [index1, 1].concat(_toConsumableArray(arr.splice(index2, 1, arr[index1]))));
        this.addTitleForm.children = arr;
      }
    },
    // 判断客户登记表是否禁用
    disableType: function disableType(row) {
      if (this.type) {
        return this.type;
      } else {
        if (row.form_type == '置业顾问') {
          if (this.saasSetting.audit.examine_rule == 2 && (this.saasSetting.audit.complex_audit_first_rule == 1 || this.saasSetting.audit.complex_audit_complex_rule == 1) || this.saasSetting.audit.examine_rule == 1 && this.saasSetting.audit.single_audit_rule == 1) {
            return true;
          }
        } else {
          if (row.owner_type == 1) {
            return true;
          } else {
            return this.type;
          }
        }
      }
    },
    // 删除板块
    delPlate: function delPlate(index, item) {
      var area = this.newAreaForm.area;
      area.splice(index, 1);
    },
    // 移动板块
    plateMove: function plateMove(index, item, type) {
      var arr = this.newAreaForm.area;
      if (type === 0) {
        // 上
        if (index == 0) {
          this.$message({
            type: 'warning',
            message: '已经是第一个了'
          });
          return;
        } else {
          var index1 = index;
          var index2 = index - 1;
          move.call(this, index1, index2, arr);
        }
      }
      if (type === 1) {
        // 下
        if (index == arr.length - 1) {
          this.$message({
            type: 'warning',
            message: '已经是最后一个了'
          });
          return;
        } else {
          var _index9 = index;
          var _index10 = index + 1;
          move.call(this, _index9, _index10, arr);
        }
      }
      function move(index1, index2, arr) {
        arr.splice.apply(arr, [index1, 1].concat(_toConsumableArray(arr.splice(index2, 1, arr[index1]))));
      }
    },
    // 添加板块
    addNewPlate: function addNewPlate() {
      var area = this.newAreaForm.area;
      area.push({
        area_name: '未命名',
        // 区域名称
        sort: 1 // 排序
      });
    },
    // 提交板块修改
    editPlateSubmit: function editPlateSubmit() {
      var _this15 = this;
      var params = {
        area: this.newAreaForm.area
      };
      params.area.forEach(function (item, i) {
        item.sort = i + 1;
      });
      this.$refs['newAreaForm'].validate(function (valid) {
        if (valid) {
          editPlateSave(params).then(function (res) {
            _this15.$message({
              type: 'success',
              message: res.data.msg
            });
            _this15.getAreaList();
            _this15.isEditPlate = false;
          });
        } else {
          return;
        }
      });
    },
    // 获取区域列表
    getAreaList: function getAreaList() {
      var _this16 = this;
      areaList().then(function (res) {
        var area = res.data.list.area;
        _this16.area = area;
        _this16.chooseRadio = area[0].id;
        _this16.addTitleForm.display_area_id = area[0].id;
        _this16.newAreaForm.area = JSON.parse(JSON.stringify(area));
        _this16.getFormList(area[0].id);
      });

      // 获取 认筹区域
      areaPleadgeList().then(function (res) {
        var area = res.data.list.area;
        if (area != null) {
          _this16.areaPLeadgeID = area[0].id;
          _this16.pleadge = area;
          _this16.getFormPledgeList(area[0].id);
        } else {
          _this16.pleadge = [];
        }
      });
      raiareaPleadgeList().then(function (res) {
        var area = res.data.list.area;
        if (area != null) {
          _this16.raiareaPLeadgeID = area[0].id;
          _this16.raipleadge = area;
          _this16.getraiFormPledgeList(area[0].id);
        } else {
          _this16.raipleadge = [];
        }
      });
    },
    hint: function hint(t) {
      var _this17 = this;
      return new Promise(function (resolve, reject) {
        _this17.$confirm(t, '提示', {
          showClose: true,
          type: 'warning',
          callback: function callback(action) {
            if (action === 'confirm') {
              resolve();
            } else {
              reject();
            }
          }
        });
      });
    },
    addRcinfo: function addRcinfo() {
      var _this18 = this;
      this.hint('是否确认增加').then(function (res) {
        addideform().then(function () {
          _this18.getAreaList();
        });
      });
    },
    closeRcinfo: function closeRcinfo() {
      var _this19 = this;
      this.hint('是否确认取消').then(function (res) {
        var l = _this19.$loading({
          lock: true,
          text: '处理中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        delideform().then(function (res) {
          _this19.$message.success(res.data.msg);
          _this19.getAreaList();
        }).finally(function () {
          l.close();
        });
      });
    },
    // 获取升筹表单
    getraiFormPledgeList: function getraiFormPledgeList(id) {
      var _this20 = this;
      var params = {
        area_id: id
      };

      // 产权人信息隐藏审核附表
      var area = this.area;
      var isProperty = false;
      for (var key in area) {
        if (Object.hasOwnProperty.call(area, key)) {
          if (area[key].id == id && area[key].type == 1) {
            isProperty = true;
          }
        }
      }
      if (isProperty) {
        this.hiddenProperty = false;
      } else {
        this.hiddenProperty = true;
      }
      raifromList(params).then(function (res) {
        for (var i = 0; i < _this20.raipleadge.length; i++) {
          if (id === _this20.raipleadge[i].id) {
            _this20.raiide_selectType = _this20.raipleadge[i].type;
          }
        }
        var formList = res.data.list.form;
        _this20.raipleadgeFormList = formList;
      });
    },
    // 获取认筹表单
    getFormPledgeList: function getFormPledgeList(id) {
      var _this21 = this;
      var params = {
        area_id: id
      };

      // 产权人信息隐藏审核附表
      var area = this.area;
      var isProperty = false;
      for (var key in area) {
        if (Object.hasOwnProperty.call(area, key)) {
          if (area[key].id == id && area[key].type == 1) {
            isProperty = true;
          }
        }
      }
      if (isProperty) {
        this.hiddenProperty = false;
      } else {
        this.hiddenProperty = true;
      }
      formPleadgeList(params).then(function (res) {
        for (var i = 0; i < _this21.pleadge.length; i++) {
          if (id === _this21.pleadge[i].id) {
            _this21.ide_selectType = _this21.pleadge[i].type;
          }
        }
        var formList = res.data.list.form;
        _this21.pleadgeFormList = formList;
      });
    },
    // 获取客户表单
    getFormList: function getFormList(id) {
      var _this22 = this;
      var params = {
        area_id: id
      };
      // 产权人信息隐藏审核附表
      var area = this.area;
      var isProperty = false;
      for (var key in area) {
        if (Object.hasOwnProperty.call(area, key)) {
          if (area[key].id == id && area[key].type == 1) {
            isProperty = true;
          }
        }
      }
      if (isProperty) {
        this.hiddenProperty = false;
      } else {
        this.hiddenProperty = true;
      }
      formList(params).then(function (res) {
        for (var i = 0; i < _this22.area.length; i++) {
          if (id === _this22.area[i].id) {
            _this22.reg_selectType = _this22.area[i].type;
          }
        }
        var formList = res.data.list.form;
        _this22.formListTable = formList;
      });
    },
    // 获取活动配置
    getAuditEvent: function getAuditEvent() {
      var _this23 = this;
      auditEvent().then(function (res) {
        var auditEventInfo = res.data.info;
        auditEventInfo.ide_no_name = auditEventInfo.ide_no_name || "号码牌";
        auditEventInfo.reg_no_name = auditEventInfo.reg_no_name || "报名编号";
        _this23.auditEventInfo = auditEventInfo;
        // 时间处理

        for (var key in auditEventInfo) {
          if (key.includes('_time') && (key.includes('_start') || key.includes('_end'))) {
            auditEventInfo[key] = auditEventInfo[key] ? _this23.time(auditEventInfo[key]) : 0;
          }
        }
        auditEventInfo.start_time = _this23.time(auditEventInfo.start_time);
        auditEventInfo.end_time = _this23.time(auditEventInfo.end_time);
        _this23.auditEventInfo = auditEventInfo;
        _this23.login_image = auditEventInfo.login_image || '';
        if (auditEventInfo.login_image && auditEventInfo.login_image != '') {
          _this23.fileList = [{
            url: _this23.replaceImgshow(auditEventInfo.login_image)
          }];
          _this23.hiddenUp = true;
        } else {
          _this23.fileList = [];
        }
      });
    },
    // 时间戳
    time: function time(_time) {
      if (_time) return parseInt(_time) * 1000;
      return '';
    },
    // 重新获取数据！ 刷新数据
    formList: function formList(e) {
      this.getFormPledgeList(e);
    },
    // saas 配置
    getSaasSetting: function getSaasSetting() {
      var _this24 = this;
      adminSetting().then(function (res) {
        _this24.saasSetting = res.data;
        var _res$data = res.data,
          list = _res$data.list,
          audit = _res$data.audit;
        _this24.auditconfig = audit;
        _this24.list = list;
        _this24.audit = list.is_display.includes('audit_in');
        _this24.is_register = list.is_display.includes('is_register');
        _this24.is_identify = list.is_display.includes('is_identify');
        _this24.is_raising_funds = list.is_display.includes('is_raising_funds');
        _this24.is_identify_sign = list.is_display.includes('is_identify_sign');
        // 认筹金
        _this24.is_identify_price = list.is_identify_price === 1;
        var sl = [{
          title: '基本信息',
          id: 0,
          num: ''
        }, {
          title: "".concat(audit.register_name, "\u8868\u5355\u914D\u7F6E"),
          id: 1,
          num: ''
        }, {
          title: "".concat(audit.identify_name, "\u8868\u5355\u914D\u7F6E"),
          id: 2,
          num: ''
        }, {
          title: "".concat(audit.raising_funds_name, "\u8868\u5355\u914D\u7F6E"),
          id: 3,
          num: ''
        }];
        var raideltInx = 3;
        // 开启认筹
        if (!_this24.is_identify) {
          sl.splice(2, 1);
          raideltInx = 2;
        }
        ;
        // 开启报名
        if (!_this24.is_register) {
          sl.splice(1, 1);
          raideltInx = 1;
        }
        // 开启升筹
        if (!_this24.is_raising_funds) sl.splice(raideltInx, 1);
        _this24.switchList = sl;
        _this24.$forceUpdate();
      });
    }
  }
};