import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//

import screenfull from 'screenfull';
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
  name: 'Index',
  props: {
    // 是否显示文字
    isShowText: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      isFullscreen: false
    };
  },
  computed: _objectSpread({}, mapState({
    type: function type(state) {
      return state.base.type;
    }
  })),
  mounted: function mounted() {
    this.init();
  },
  beforeDestroy: function beforeDestroy() {
    this.destroy();
  },
  created: function created() {
    this.isFullscreen = this.type;
  },
  methods: _objectSpread(_objectSpread({}, mapActions('base', ['editType'])), {}, {
    click: function click() {
      if (!screenfull.enabled) {
        this.$message({
          message: 'you browser can not work',
          type: 'warning'
        });
        return false;
      }
      screenfull.toggle();
    },
    change: function change() {
      this.isFullscreen = screenfull.isFullscreen;
      this.editType(screenfull.isFullscreen);
    },
    init: function init() {
      if (screenfull.enabled) {
        screenfull.on('change', this.change);
      }
    },
    destroy: function destroy() {
      if (screenfull.enabled) {
        screenfull.off('change', this.change);
      }
    }
  })
};