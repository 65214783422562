//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Link from '@/layout/components/Sidebar/Link.vue';
import linkName from '@/components/LinkName/index.vue';
export default {
  name: 'SwitchBtn',
  components: {
    Link: Link,
    linkName: linkName
  },
  props: {
    isShowSchedule: {
      type: Boolean,
      default: true
    },
    isShowUser: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      index: 0
    };
  },
  methods: {
    btnChange: function btnChange(index) {
      this.index = index;
      this.$emit('tabClick', this.index);
    }
  }
};