import _toConsumableArray from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import echarts from 'echarts';
export default {
  name: 'CustomerArc',
  props: {
    chartData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    name: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      // 四个图形 长度
      arcOne: '',
      arcTwo: '',
      arcThree: '',
      arcFour: '',
      // 百分比计算
      perArr: [],
      chartImg: ''
    };
  },
  watch: {
    chartData: function chartData() {
      this.initArc();
    }
  },
  mounted: function mounted() {
    this.initArc();
  },
  methods: {
    initArc: function initArc() {
      var _this = this;
      // 百分比数组
      var percentangeArr = [];
      // 总和
      var allNum = 0;
      // 计算总合
      this.chartData && this.chartData.forEach(function (item, i) {
        allNum += Number(item);
      });
      // 计算百分比总数
      this.chartData && this.chartData.forEach(function (item, i) {
        percentangeArr[i] = _this.getPercentage(item, allNum);
      });
      // 数组中最大值
      var maxNum = Math.max.apply(Math, percentangeArr);
      if (maxNum == 0) {
        //  3
        this.arcOne = 712;
        //  1
        this.arcTwo = 712;
        //  4
        this.arcThree = 712;
        //  2
        this.arcFour = 712;
      } else {
        //  3
        this.arcOne = percentangeArr[2] / maxNum * 712;
        //  1
        this.arcTwo = percentangeArr[0] / maxNum * 712;
        //  4
        this.arcThree = percentangeArr[3] / maxNum * 712;
        //  2
        this.arcFour = percentangeArr[1] / maxNum * 712;
      }
      this.perArr = percentangeArr;
      this.initRoseChart();
    },
    // 获取 百分比
    getPercentage: function getPercentage(top, bottom) {
      if (Number(bottom) != 0) {
        return (Number(top) / Number(bottom) * 100).toFixed(2);
      } else {
        return '0.00';
      }
    },
    // 初始化极点坐标图
    initRoseChart: function initRoseChart() {
      // 获取图表 dom 节点，并初始化 chart
      var chartDom = document.getElementById('rose_chart');
      var myChart = echarts.init(chartDom);
      var position = ['0%', '100%'];
      var maxRadius = 200;
      var colorList = this.setColorList();
      var arcLineList = this.setArcLine(position, maxRadius);
      var pieSeries = [];
      var option = {};
      this.chartData.forEach(function (num) {
        pieSeries.push({
          value: parseInt(num)
        });
      });

      // 添加 12 条空数据，用于控制图表显示区域为第一象限
      Array(12).fill(1).forEach(function (item) {
        pieSeries.push({
          value: item,
          itemStyle: {
            color: 'transparent'
          },
          labelLine: {
            show: false
          }
        });
      });
      option.series = [].concat(_toConsumableArray(arcLineList), [
      // 画圆弧
      {
        hoverAnimation: false,
        // 禁用悬浮动画
        type: 'pie',
        roseType: 'area',
        radius: ['0%', "".concat(maxRadius, "%")],
        startAngle: 90,
        center: position,
        label: {
          show: false
        },
        itemStyle: {
          normal: {
            color: function color(params) {
              return colorList[params.dataIndex];
            }
          }
        },
        data: pieSeries
      }]);
      myChart.setOption(option);
    },
    // 设置图表渐变颜色样式
    setColorList: function setColorList() {
      var styleList = [];
      var colorList = [{
        color0: 'rgba(201, 217, 255, 0.5)',
        color1: '#749DFF'
      }, {
        color0: 'rgba(255, 212, 201, 0.5)',
        color1: '#FF8B70'
      }, {
        color0: 'rgba(255, 242, 201, 0.5)',
        color1: '#FDD54A'
      }, {
        color0: 'rgba(193, 236, 255, 0.5)',
        color1: '#6CD1FE'
      }];
      colorList.forEach(function (item) {
        styleList.push({
          type: 'linear',
          x: 0,
          y: 1,
          x2: 1,
          y2: 0,
          globalCoord: false,
          // 缺省为 false
          colorStops: [{
            offset: 0,
            color: item.color0 // 0% 处的颜色
          }, {
            offset: 1,
            color: item.color1 // 100% 处的颜色
          }]
        });
      });
      return styleList;
    },
    // 设置图表中的圆弧样式
    setArcLine: function setArcLine(position, maxRadius) {
      var lineList = [maxRadius];
      Array(3).fill(0).forEach(function () {
        lineList.push(lineList[lineList.length - 1] - 40);
      });
      return lineList.map(function (rate, index) {
        return {
          hoverAnimation: false,
          // 禁用悬浮动画
          type: 'pie',
          startAngle: index > 0 ? 93 : 90,
          radius: [0, "".concat(rate, "%")],
          itemStyle: {
            borderCap: 'dashed',
            borderRadius: 5
          },
          label: {
            show: false
          },
          center: position,
          data: [{
            value: index > 0 ? 560 : 500,
            label: {
              show: false
            },
            itemStyle: {
              borderType: 'dashed',
              borderColor: '#C0C0C0',
              borderWidth: 1,
              color: 'transparent'
            }
          }, {
            value: 1500,
            itemStyle: {
              color: 'transparent'
            }
          }]
        };
      });
    }
  }
};