import _toConsumableArray from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.pad-end.js";
import "core-js/modules/es.string.pad-start.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NumList',
  props: {
    // 已占派号列表
    listObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 最大可选号
    maxNum: {
      type: Number,
      default: function _default() {
        return 1;
      }
    },
    // 最好开始号
    minNum: {
      type: Number,
      default: function _default() {
        return 0;
      }
    },
    // 是否隐藏尾数为4的
    is_show_four: {
      type: Number,
      default: function _default() {
        return 0;
      }
    },
    // 号码总数
    total: {
      type: Number,
      default: function _default() {
        return 0;
      }
    },
    // 随机数-用于更新
    random: {
      type: Number,
      default: function _default() {
        return 0;
      }
    },
    numTypes: {
      type: Number,
      default: function _default() {
        return 0;
      }
    },
    // 类型下标-用于控制滚动到顶部
    typeIndex: {
      type: Number,
      default: function _default() {
        return 0;
      }
    },
    // 当前选中号码
    nowNum: [Number, String],
    // 项目配置
    audit: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    oneGroupNum: {
      type: Number,
      default: function _default() {
        return 0;
      }
    }
  },
  data: function data() {
    return {
      rows: [],
      numState: {},
      // 一行个数
      oneRowNum: 10,
      // 旧的类型下标
      oldTypeIndex: 0
    };
  },
  watch: {
    // 列表状态监听
    listObj: function listObj() {
      this.initArr();
    },
    // 自动选号监听
    random: function random() {
      var num = this.maxNum + 1;
      // 自动选中最大可选号
      // this.selectNum(num)
      if (this.numTypes == 1) {
        this.selectNum(this.nowNum);
        this.scorllNum(this.nowNum);
      } else if (this.numTypes == 3) {
        this.scorllNum(this.nowNum);
      } else {
        this.selectNum(num);
        this.scorllNum(num);
      }
    }
  },
  methods: {
    // 初始化号码列表
    initArr: function initArr() {
      var start = this.minNum - 1;
      var numState = {};
      var oneRowNum = this.oneRowNum,
        total = this.total;
      if (total < 1) return;
      var rows = [];
      var rowLength = Math.ceil(total / oneRowNum);
      for (var i = 0; i < rowLength; i++) {
        var oneRow = [];
        for (var j = 0; j < oneRowNum; j++) {
          if (start < total) {
            start += 1;
            var is_show_four = true;
            if (this.is_show_four === 1) {
              var startArray = (start + "").split("");
              if (startArray[startArray.length - 1] === "4") {
                is_show_four = false;
              }
            }
            if (is_show_four) {
              var item = {
                num: start,
                name: this.setNumName(start)
              };
              oneRow.push(item);
              numState[start] = {
                r_idx: i,
                n_idx: j,
                name: item.name
              };
            }
          }
        }
        if (oneRow.length > 0 && oneRow.length < 10) {
          oneRow.push.apply(oneRow, _toConsumableArray(Array(oneRowNum - oneRow.length).fill({})));
        }
        if (oneRow.length) rows.push(oneRow);
      }
      this.rows = rows;
      this.numState = numState;

      // 切换类型后，就滚动到初始位置
      if (this.oldTypeIndex != this.typeIndex) {
        if (rows.length) this.scorllNum('top_node');
        this.oldTypeIndex = this.typeIndex;
      }
    },
    // 拼接号码外显名称
    setNumName: function setNumName(num) {
      var _this$audit = this.audit,
        paihao_rule = _this$audit.paihao_rule,
        no_1 = _this$audit.paihao_num1,
        no_2 = _this$audit.paihao_num2;

      // 分段序号
      if (paihao_rule == 1) {
        num -= 1;
        var num1 = parseInt(num / this.oneGroupNum) + 1;
        var num2 = parseInt(num % this.oneGroupNum) + 1;
        var max1 = parseInt('1'.padEnd(no_1, '0'));
        var max2 = parseInt('1'.padEnd(no_2, '0'));
        return "".concat(num1 < max1 ? num1.toString().padStart(no_1, '0') : num1, "-").concat(num2 < max2 ? num2.toString().padStart(no_2, '0') : num2);
      }
      // 普通序号
      else {
        return num;
      }
    },
    // 选中号码
    selectNum: function selectNum(num) {
      if (this.listObj[num] != undefined) {
        this.$emit('selectNum', {
          num: '',
          name: ''
        });
        this.$emit('reqNumDetail', num);
        return;
      }
      this.onlySelectNum(num);
    },
    // 仅选中号码
    onlySelectNum: function onlySelectNum(num) {
      if (num || num === 0) this.$emit('selectNum', {
        num: num,
        name: this.numState[num].name
      });
    },
    // 滚动到号码位置
    scorllNum: function scorllNum(num) {
      if (num == null) {
        return;
      }
      var nodeID = '';
      if (num == 'top_node') {
        nodeID = num;
      } else {
        var r_idx = this.numState[num].r_idx;
        if (r_idx <= 5) r_idx = 0;else r_idx -= 5;
        nodeID = r_idx;
      }
      var rowDom = document.getElementById("".concat(nodeID));
      if (rowDom) rowDom.scrollIntoView();
    }
  }
};