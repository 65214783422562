import _typeof from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { mainDefaultData } from './defaultSettingData';

// 添加单位
export function unit() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var u = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'px';
  function handleStr(str) {
    // 纯数字字符串 添加上像素
    if (/^\d*$/.test(str)) return "".concat(str || 0).concat(u);
    return str;
  }
  if (data instanceof Object) {
    Object.keys(data).forEach(function (key) {
      var val = data[key];
      if (['string', 'number'].includes(_typeof(val))) data[key] = handleStr(val);
    });
  } else if (['string', 'number'].includes(_typeof(data))) {
    data = handleStr(data);
  }
  return data;
}

/**
 *  获取设置
 */
function getCof() {
  var keys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var cof = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var newObj = {};
  keys.map(function (key) {
    newObj[key] = cof[key] || mainDefaultData.host_json[key];
  });
  return newObj;
}
export function setBoxContentStyle(cof) {
  var boxKEY = ['card_content_mode'];
  var boxCof = getCof(boxKEY, cof); // 单独获取box设置

  var style = {
    display: 'flex'
  };
  style["flexDirection"] = boxCof.card_content_mode;
  return style;
}