import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "auditState",
  props: {
    // 审核类型（0报名  1认筹
    auditType: {
      type: Number,
      default: 0
    },
    /**
        审核状态 
        999 未提交  0待审核
        1等待初审   -1初审未通过
        2初审通过   -2复审未通过
        3全部通过(单步审核通过) 
        -3(单步审核驳回) -4撤销
     */
    state: {
      type: Number,
      default: 0
    },
    showType: {
      type: String,
      default: 'block'
    }
  },
  computed: {
    showName: function showName() {
      return this.auditType === 0 ? "报名" : "认筹";
    }
  },
  filters: {
    showText: function showText(type, surname) {
      var name = '';
      if (type === 999 || type === -4 || type === null || typeof type === 'undefined') {
        name = '未提交';
      } else if (type > 0 && type < 3) {
        name = '审核中';
      } else if (type === 3) {
        name = '已通过';
      } else if (type < 0 && type != -4) {
        name = '已驳回';
      } else {
        return '未知';
      }
      return surname + name;
    }
  }
};