import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import { mainDefaultData } from '@/views/editFormat/defaultSettingData';
// 设置版式
var state = {
  list: _objectSpread({}, mainDefaultData)
};
var mutations = {
  // 设置list 数据
  SET_LIST: function SET_LIST(state, payload) {
    // 字符串解析
    if (typeof payload.host_json === 'string') {
      try {
        payload.host_json = JSON.parse(payload.host_json);
      } catch (error) {
        // 解析不出来给默认
        payload.host_json = mainDefaultData.host_json;
      }
    }
    // 无数据给默认
    else if (!payload.host_json) {
      payload.host_json = mainDefaultData.host_json;
    }
    state.list = payload;
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations
};