import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import store from '@/store';
import { getProject, getToken, removeToken } from '@/utils/auth';

// create an axios instance
var service = axios.create({
  // 二阶段接口
  // baseURL: 'https://yxkcs1.yun-xk.com/pc', // url = base url + request url
  // baseURL: "https://kp.yun-xk.com/pc", // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['token'] = getToken();
    config.headers['project'] = JSON.parse(getProject()).project_id;
    config.headers['admintel'] = JSON.parse(getProject()).tel;
    config.headers['adminid'] = JSON.parse(getProject()).id;
  }
  return config;
}, function (error) {
  // do something with request error
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response;

  // if the custom code is not 20000, it is judged as an error.
  if (res.status !== 200) {
    Message({
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    });

    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.status === 50008 || res.status === 50012 || res.status === 50014) {
      // to re-login
      MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
        confirmButtonText: 'Re-Login',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        store.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    } else {}
    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  console.log(error);
  if (error.response.status === 401) {
    // 清楚 Vuex  权限
    store.dispatch('user/resetToken').then(function () {
      location.reload();
    });
  } else if (error.response.status === 404) {
    Message({
      message: '亲，系统维护中，请稍候再试哟~',
      type: 'error',
      duration: 5 * 1000
    });
  } else {
    Message({
      message: error.response.data.msg,
      type: 'error',
      duration: 5 * 1000
    });
  }
  return Promise.reject(error);
});
export default service;