/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-04-23 11:08,
 */

var state = {
  isMobile: false,
  type: false
};
var mutations = {
  SET_IS_MOBILE: function SET_IS_MOBILE(state, is) {
    state.isMobile = is;
    console.log(state.isMobile);
  },
  EDIT_TYPE: function EDIT_TYPE(state, type) {
    state.type = type;
  }
};
var actions = {
  editType: function editType(_ref, type) {
    var commit = _ref.commit;
    commit('EDIT_TYPE', type);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};