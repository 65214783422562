import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Api from '@/api/index';
export default {
  name: 'Index',
  props: {},
  computed: _objectSpread({}, mapGetters(['roles', 'customSetting'])),
  data: function data() {
    return {
      progressList: [{
        id: 3,
        title: '房源设置',
        subTitle: '导入房源',
        status: false,
        name: 'House'
      }, {
        id: 2,
        title: '图文维护',
        subTitle: '完善图文资料',
        status: false,
        name: 'Graphic'
      }, {
        id: 5,
        title: '员工设置',
        subTitle: '导入员工账号',
        status: false,
        name: 'Staff',
        is_sms: false,
        sms_count: 0
      }, {
        id: 4,
        title: '开盘客户',
        subTitle: '导入客户',
        status: false,
        name: 'Index'
      }, {
        id: 1,
        title: '计划维护',
        subTitle: '完善活动计划',
        status: false,
        name: 'Activity'
      }],
      sendLoading: false // 发送按钮loading
    };
  },
  mounted: function mounted() {
    if (this.roles.includes('index/index') || this.roles.includes('admin')) {
      this.getOpenConfig();
    }
  },
  methods: {
    // 获取开盘配置引导
    getOpenConfig: function getOpenConfig() {
      var _this = this;
      Api.getOpenConfig().then(function (res) {
        if (res.status === 200) {
          var _res$data$list = res.data.list,
            list = _res$data$list === void 0 ? {} : _res$data$list;
          _this.progressList.forEach(function (item) {
            if (item.id === 1) {
              // 完善活动计划
              item.status = list.is_event;
            } else if (item.id === 2) {
              // 完善图文资料
              item.status = list.is_image;
            } else if (item.id === 3) {
              item.title = _this.customSetting.title;
              item.subTitle = '导入' + _this.customSetting.title;
              // 导入房源
              item.status = list.is_room;
              item.name = list.room_type === 'villa' ? 'Building' : 'House';
            } else if (item.id === 4) {
              // 导入客户
              item.status = list.is_user;
            } else if (item.id === 5) {
              // 导入员工账号（分配岗位和团队）
              item.status = list.is_admin;
              item.is_sms = list.is_sms;
              item.sms_count = list.sms_count;
            }
          });
        }
      });
    },
    // 设置
    setting: function setting(item) {
      this.$router.push({
        name: item.name
      });
    },
    // 发送
    sendMes: function sendMes() {
      var _this2 = this;
      this.sendLoading = true;
      Api.smsPassord().then(function (res) {
        _this2.sendLoading = false;
        if (res.status === 200) {
          _this2.$message({
            message: res.data.Msg || '已发送短信！',
            type: 'success'
          });
        }
        _this2.getOpenConfig();
      });
    }
  }
};