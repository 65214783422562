import { render, staticRenderFns } from "./reviewDetails.vue?vue&type=template&id=0c05f1ec&scoped=true"
import script from "./reviewDetails.vue?vue&type=script&lang=js"
export * from "./reviewDetails.vue?vue&type=script&lang=js"
import style0 from "./reviewDetails.vue?vue&type=style&index=0&id=0c05f1ec&lang=css"
import style1 from "./reviewDetails.vue?vue&type=style&index=1&id=0c05f1ec&scoped=true&lang=scss"
import style2 from "./reviewDetails.vue?vue&type=style&index=2&id=0c05f1ec&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c05f1ec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/bs/repository/project_32/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0c05f1ec')) {
      api.createRecord('0c05f1ec', component.options)
    } else {
      api.reload('0c05f1ec', component.options)
    }
    module.hot.accept("./reviewDetails.vue?vue&type=template&id=0c05f1ec&scoped=true", function () {
      api.rerender('0c05f1ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/registerUser/review/reviewDetails.vue"
export default component.exports