import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import reportTitle from './components/reportTitle'; // 大标题
import reportSubtitle from './components/reportSubtitle'; // 小标题
import centerTitle from './components/centerTitle'; // 中心标题
import listItem from './components/listItem'; // 开盘小结 列表
import banner from './components/banner'; // 顶部banner
import card from './components/card'; // 卡片
import customTable from './components/customTable'; // 表格 自定义头部颜色
import customTablecopy from './components/customTableCopy'; // 表格 普通表格
import pieChart from './components/pieChart'; // 饼图
import histogramChart from './components/histogramChart'; // 横向柱状图
import processItem from './components/processItem'; // 时间轴
import customerArc from './components/customerArc'; // 扇形图表
import columnarChart from './components/columnarChart'; // 柱状图 对比
import footerCustom from './components/footer'; // 页面底部
import { activityReportRoom } from '@/api/activityReportRoom'; // 开盘报告接口
import { activityReport } from '@/api/activityReport'; // 开盘报告详情-接口
import { adminParameter } from '@/api/public';
import { isNullNum, isNullPrice, tenThousandTrans, tenThousandTransCopy, isArray } from './util'; // 判空
import { mapState, mapActions, mapGetters } from 'vuex';
import htmlToPdf from '@/utils/htmlToPdf';
import moment from 'moment';
export default {
  name: 'ActiveReport',
  components: {
    reportTitle: reportTitle,
    reportSubtitle: reportSubtitle,
    centerTitle: centerTitle,
    listItem: listItem,
    banner: banner,
    card: card,
    customTable: customTable,
    customTablecopy: customTablecopy,
    pieChart: pieChart,
    histogramChart: histogramChart,
    processItem: processItem,
    customerArc: customerArc,
    columnarChart: columnarChart,
    footerCustom: footerCustom
  },
  data: function data() {
    return {
      // 数据请求完成（数据请求完成后，再渲染节点，避免触发不必要的渲染）
      reqFinish: false,
      // 渲染状态（全部图表渲染完成后，再绘制PDF）
      renderState: {},
      // 是否显示加载遮罩
      loading: true,
      // 基础数据 tabele 头部
      basicDataHead: [{
        label: '类型',
        prop: 'type',
        width: 60
      }, {
        label: '推案数量',
        prop: 'room_count',
        width: 80
      }, {
        label: '认购数量',
        prop: 'order_count',
        width: 80
      }, {
        label: '认购金额(万元)',
        prop: 'sum_price',
        width: 145
      }, {
        label: '成交均价',
        prop: 'avg_price',
        width: 125
      }],
      // 住宅销售详情 table 头部
      houseSaleDataHeadOne: [{
        label: '楼栋',
        prop: 'name'
      }, {
        label: '单元数',
        prop: 'unit_count'
      }, {
        label: '推案数量',
        prop: 'room_count',
        count: true
      }, {
        label: '认购数量',
        prop: 'order_count',
        count: true
      }, {
        label: '去化率',
        prop: 'Removalrate',
        count: true
      }, {
        label: '车位//同购比',
        prop: 'sync_count',
        width: 70
      }, {
        label: '成交均价//(万元/㎡)',
        prop: 'avg_price',
        width: 127,
        count: true
      }],
      houseSaleDataHeadTwo: [{
        label: '户型',
        prop: 'name',
        width: 65
      }, {
        label: '户型结构',
        prop: 'tag'
      }, {
        label: '户型面积(㎡)',
        prop: 'area_interval',
        width: 106
      }, {
        label: '推案数量',
        prop: 'room_count',
        count: true
      }, {
        label: '认购数量',
        prop: 'order_count',
        count: true
      }, {
        label: '去化率',
        prop: 'Removalrate',
        count: true
      }, {
        label: '车位//同购比',
        prop: 'sync_count',
        width: 70
      },
      // 符号"//" 表头可换行
      {
        label: '成交均价//(万元/㎡)',
        prop: 'avg_price',
        width: 100,
        count: true
      }],
      // 别墅销售详情 table 头部
      villaSaleDataHeadOne: [{
        label: '楼层',
        prop: 'build_name',
        width: 60
      }, {
        label: '区域',
        prop: 'floor_name',
        width: 85
      }, {
        label: '层级',
        prop: 'unit_name'
      }, {
        label: '推案数量',
        prop: 'room_count',
        count: true
      }, {
        label: '销售数量',
        prop: 'order_count',
        count: true
      }, {
        label: '去化率',
        prop: 'Removalrate',
        count: true
      }, {
        label: '成交均价(万元/个)',
        prop: 'avg_price',
        width: 127,
        count: true
      }],
      villaSaleDataHeadTwo: [{
        label: '户型',
        prop: 'name',
        width: 65
      }, {
        label: '户型面积(㎡)',
        prop: 'area_interval',
        width: 106
      }, {
        label: '户型单价(元/㎡)',
        prop: 'price_interval',
        width: 127
      }, {
        label: '推案数量',
        prop: 'room_count',
        count: true
      }, {
        label: '认购数量',
        prop: 'order_count',
        count: true
      }, {
        label: '去化率',
        prop: 'Removalrate',
        count: true
      }, {
        label: '成交均价(万元/个)',
        prop: 'avg_price',
        width: 127,
        count: true
      }],
      // 项目信息
      project: {},
      // 开盘环节
      projectRule: {
        pay: {},
        selectNum: {},
        sign: {},
        signNum: {},
        signUp: {},
        userCount: '',
        selfSelect: {}
      },
      // ------------外部js-----------------
      // 判空
      isNullNum: null,
      isNullPrice: null,
      tenThousandTrans: null,
      tenThousandTransCopy: null,
      isArray: null,
      info: {
        summarize: '' //开盘总结
      },
      isroomOrcar: 0,
      //开启了几种类型,//0住宅车位都开启,1只开启住宅,2只开启车位
      order_type: '',
      //认购统计维度
      type_show: '',
      //消控图展示
      roomtable: [],
      //住宅消控图
      cartable: [],
      //车位消控图
      // 基础数据
      projectData: {},
      projectData2: {
        cate1: {
          avg_price: ''
        },
        cate2: {
          avg_price: ''
        }
      },
      // 基础数据下 的 project
      projectData_project: {},
      // 推荐房源数据
      collectData: {}
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapGetters(['customSetting'])), mapState('activityReport', {
    // event: state => state.activityReport.event //正式活动信息
  })), {}, {
    averagePrice: function averagePrice() {
      var str = '';
      if (this.project.cate_car === 1 && this.project.cate_room !== 1) {
        var _this$projectData2$ca;
        //只开启车位时
        if (+((_this$projectData2$ca = this.projectData2.cate2) === null || _this$projectData2$ca === void 0 ? void 0 : _this$projectData2$ca.sum_price) && this.projectData2.cate2.order_count) {
          //车位平均价 等于 认购金额除以认购数量
          str = Math.floor(+this.projectData2.cate2.sum_price / this.projectData2.cate2.order_count);
        } else {
          str = '未提供';
        }
      } else {
        var _this$projectData2$ca2;
        str = Math.floor(+((_this$projectData2$ca2 = this.projectData2.cate1) === null || _this$projectData2$ca2 === void 0 ? void 0 : _this$projectData2$ca2.avg_price));
      }
      return str == 0 ? '未提供' : str;
    }
  }),
  created: function created() {
    var _this = this;
    var option = this.$route.query;
    if (option.info) {
      this.info = JSON.parse(option.info);
    }
    this.order_type = option.order_type;
    this.type_show = option.type_show;
    // 获取开盘报告数据
    this.getActivityReportData(option.order_type).then(function () {
      _this.$nextTick(function () {
        _this.loading = false;
        _this.download();
      });
    });
    // --------------------------
    this.isNullNum = isNullNum;
    this.isNullPrice = isNullPrice;
    this.tenThousandTrans = tenThousandTrans;
    this.tenThousandTransCopy = tenThousandTransCopy;
    this.isArray = isArray;

    // 获取公共参数
    adminParameter().then(function (res) {
      _this.project = res.data.list;
      console.log(_this.project, 'this.project ');
    });
  },
  methods: _objectSpread(_objectSpread({}, mapActions('activityReport', ['changeActivityReport'])), {}, {
    getActivityReportData: function getActivityReportData(order_type) {
      var _this2 = this;
      return new Promise(function (resolve) {
        activityReportRoom({
          order_type: order_type
        }).then(function (res) {
          // this.loading = false
          var _res$data = res.data,
            _res$data$build_list = _res$data.build_list,
            build1 = _res$data$build_list.build1,
            build2 = _res$data$build_list.build2,
            projectData = _res$data.projectData;
          var dolroom = [];
          var dolcar = [];
          if (build1 && build1.length) {
            //重新组装数据
            build1.map(function (item) {
              var _item$unit;
              if ((_item$unit = item.unit) !== null && _item$unit !== void 0 && _item$unit.length) {
                item.unit.map(function (items) {
                  var obj = {
                    buildingName: item.name,
                    unitName: items.name,
                    floor: items.floor,
                    count: items.count
                  };
                  dolroom.push(obj);
                });
              } else {
                var obj = {
                  buildingName: item.name,
                  floor: item.floor,
                  count: item.count
                };
                dolroom.push(obj);
              }
            });
          }
          if (build2 && build2.length) {
            //重新组装数据
            build2.map(function (item) {
              var _item$unit2;
              if ((_item$unit2 = item.unit) !== null && _item$unit2 !== void 0 && _item$unit2.length) {
                item.unit.map(function (items) {
                  if (items !== null && items !== void 0 && items.floor.length) {
                    items.floor.map(function (datas) {
                      var obj = {
                        buildingName: item.name,
                        unitName: items.name,
                        floor: items.floor,
                        count: datas.count,
                        floorName: datas.name,
                        room: datas.room
                      };
                      dolcar.push(obj);
                    });
                  }
                });
              } else {
                var obj = {
                  buildingName: item.name,
                  floor: item.floor,
                  count: item.count
                };
                dolcar.push(obj);
              }
            });
          }
          _this2.roomtable = dolroom;
          _this2.cartable = dolcar;
          if (res.data.roomData) {
            // 过滤推案数为0的数据
            for (var key in res.data.roomData) {
              var _res$data$roomData$ke;
              if ((_res$data$roomData$ke = res.data.roomData[key]) !== null && _res$data$roomData$ke !== void 0 && _res$data$roomData$ke.length) {
                res.data.roomData[key] = res.data.roomData[key].filter(function (item) {
                  return item.room_count;
                });
              }
            }
          }
          // 组装数据 开盘销售&客户选房详情
          // 别墅改车位
          _this2.villaSaleDataHeadTwo[0].label = _this2.customSetting.villa_setting.house_type;
          _this2.villaSaleDataHeadTwo[1].label = _this2.customSetting.villa_setting.house_type + '面积(㎡)';
          _this2.villaSaleDataHeadTwo[2].label = _this2.customSetting.villa_setting.house_type + '单价(元/㎡)';
          _this2.villaSaleDataHeadTwo.splice(2, 1); //车位销售详情删除类型单价列
          _this2.roomData = res.data.roomData;
          _this2.getRoomData(res.data.roomData);
          _this2.reqFinish = true;
        });
        activityReport({
          order_type: order_type
        }).then(function (res) {
          var _res$data$project = res.data.project,
            cate_room = _res$data$project.cate_room,
            cate_car = _res$data$project.cate_car;
          // 基础数据
          _this2.projectData = JSON.parse(JSON.stringify(res.data.projectData));
          _this2.projectData2 = JSON.parse(JSON.stringify(res.data.projectData));
          _this2.isroomOrcar = cate_room == 1 && cate_car == 1 ? 0 : cate_room == 1 && cate_car != 1 ? 1 : 2; //开启的类型 车位和房源
          if (_this2.isroomOrcar) {
            //只开启了一种认购 则删除车位同购比
            _this2.houseSaleDataHeadOne.splice(5, 1); //车位销售详情删除类型单价列
            _this2.houseSaleDataHeadTwo.splice(6, 1);
            if (_this2.houseSaleDataHeadTwo[6]) {
              _this2.houseSaleDataHeadTwo[6].width = 127;
            }
          }
          // 基础数据下的project
          _this2.projectData_project = _this2.projectData.projcet;
          // 推荐房源数据
          _this2.collectData = res.data.collectData;
          resolve(true);
        });
      });
    },
    // 获取 百分比
    getPercentage: function getPercentage(top, bottom) {
      if (top && bottom) {
        if (Number(bottom) != 0) {
          return Math.round(Number(top) / Number(bottom) * 100);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    priceCount: function priceCount(number) {
      var pric = Number(number);
      if (pric < 10000) {
        var num1 = parseFloat(pric / 10000);
        return num1 ? Math.round(num1 * 100) / 100 : num1;
      } else {
        var _num = parseFloat(pric / 10000);
        return Math.round(_num);
      }
    },
    getRoomData: function getRoomData(data) {
      for (var key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          var element = data[key];
          element && element.forEach(function (item) {
            // 组装价格区间
            if (item.min_price && item.max_price) {
              if (item.min_price == item.max_price) {
                // 如果最大价格等于最小价格 取一个值
                item.price_interval = item.max_price;
              } else {
                item.price_interval = item.min_price + '~' + item.max_price;
              }
            } else {
              item.price_interval = '';
            }
            // 组装面积区间
            if (item.min_num && item.max_num) {
              if (item.min_num == item.max_num) {
                item.area_interval = item.max_num;
              } else {
                item.area_interval = item.min_num + '~' + item.max_num;
              }
            } else {
              item.area_interval = '';
            }
            if (item.room_count && item.room_count != 0) {
              item.Removalrate = Math.round(item.order_count / item.room_count * 100) + '%';
            } else {
              item.Removalrate = '0%';
            }
          });
        }
      }
      // 如果订单数量存在，但是金额为0，那么就是未提供数据导致
      for (var i = 1; i < 3; i++) {
        var _this$roomData;
        if ((_this$roomData = this.roomData["build" + i]) !== null && _this$roomData !== void 0 && _this$roomData.length) {
          this.roomData["build" + i].forEach(function (item) {
            if (!+item.avg_price && +item.order_count) {
              item.avg_price = '未提供';
            }
            if (!item.name) {
              item.name = '未提供';
            }
            if (!item.unit_count) {
              item.unit_count = '未提供';
            }
            if (!item.floor_count) {
              item.floor_count = '未提供';
            }
            if (!item.sync_count) {
              item.sync_count = '0%';
            } else if (item.sync_count) {
              item.sync_count = item.sync_count + '%';
            }
          });
          if ('cate' + i == 'cate1' && this.roomData["cate" + i]) {
            var is_tag = this.roomData["cate" + i].find(function (datas) {
              return datas.tag;
            });
            if (!is_tag) {
              //如果全部没有户型结表格不显示
              this.houseSaleDataHeadTwo.splice(1, 1);
            }
          }
          if ('build' + i == 'build2' && this.roomData["build" + i]) {
            var is_floor_name = this.roomData["build" + i].find(function (datas) {
              return datas.floor_name;
            });
            var is_unit_name = this.roomData["build" + i].find(function (datas) {
              return datas.unit_name;
            });
            var is_build_name = this.roomData["build" + i].find(function (datas) {
              return datas.build_name;
            });
            if (!is_build_name) {
              var dleIndex = this.villaSaleDataHeadOne.findIndex(function (datas) {
                return datas.prop == 'build_name';
              });
              this.villaSaleDataHeadOne.splice(dleIndex, 1);
            }
            if (!is_floor_name) {
              var _dleIndex = this.villaSaleDataHeadOne.findIndex(function (datas) {
                return datas.prop == 'floor_name';
              });
              this.villaSaleDataHeadOne.splice(_dleIndex, 1);
            }
            if (!is_unit_name) {
              var _dleIndex2 = this.villaSaleDataHeadOne.findIndex(function (datas) {
                return datas.prop == 'unit_name';
              });
              this.villaSaleDataHeadOne.splice(_dleIndex2, 1);
            }
          }
          this.roomData["cate" + i].forEach(function (item) {
            if (!+item.avg_price && +item.order_count) {
              item.avg_price = '未提供';
            }
            if (!item.name) {
              item.name = '未提供';
            }
            if (!item.area_interval) {
              item.area_interval = '未提供';
            }
            if (!item.price_interval || !+item.price_interval) {
              item.price_interval = '未提供';
            }
            if (!item.sync_count) {
              item.sync_count = '0%';
            } else if (item.sync_count) {
              item.sync_count = item.sync_count + '%';
            }
          });
        }
      }
    },
    // 基础数据 表格数据
    basicData: function basicData(d1, d2) {
      var newArr = [];
      if (this.project.cate_room === 1) {
        if (+(d1 === null || d1 === void 0 ? void 0 : d1.avg_price)) {
          d1.avg_price = String(Math.floor(+d1.avg_price / 10000 * 100) / 100) == 0 ? '0.00' : String(Math.floor(+d1.avg_price / 10000 * 100) / 100) + '万元/㎡';
        }
        newArr.push(_objectSpread({
          type: '住宅'
        }, d1));
      }
      if (this.project.cate_car === 1) {
        if (+(d2 === null || d2 === void 0 ? void 0 : d2.sum_price) && d2.order_count) {
          //车位平均价 等于 认购金额除以认购数量
          d2.avg_price = Math.floor(+d2.sum_price / d2.order_count / 10000 * 100) / 100 + '万元/个';
        } else {
          d2 ? d2.avg_price = '0.00' : '';
        }
        // if(+d2.avg_price){
        //   d2.avg_price  =String(Math.floor( +d2.avg_price/10000*100) / 100) ==0?'0.00':String(Math.floor( +d2.avg_price/10000*100) / 100)+'万元/个'
        // }
        newArr.push(_objectSpread({
          type: this.customSetting.villa_setting.house_name
        }, d2));
      }
      return newArr;
    },
    /**
     * 只要执行到 echart.setOption，就视为渲染完成
     * 如果使用监听，则无数据时无法触发chart渲染回调
     * 而且鼠标放入图表时，会再次触发渲染回调
     * 因此，暂时只能采用这种不严谨的写法
     */
    setRenderState: function setRenderState(name) {
      var _this3 = this;
      var state = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      // 存储渲染状态
      this.renderState[name] = state;
      clearTimeout(this.renderTimer1);
      this.renderTimer1 = setTimeout(function () {
        // 1s后再检查避免提前绘制
        _this3.checkRenderState();
      }, 1500);
    },
    // 检查渲染状态
    checkRenderState: function checkRenderState(second) {
      var _this4 = this;
      clearTimeout(this.renderTimer);
      this.renderTimer = setTimeout(function () {
        // 若全部渲染完成，就开始绘制PDF
        if (!Object.values(_this4.renderState).includes(false)) {
          _this4.$nextTick(function () {
            _this4.loading = false;
            _this4.download();
            console.log('all rendering is completed, start drawing.');
          });
        } else {
          // 已经是第二次，就停止渲染并打印警告
          if (second) console.warn('rendering exception, have charts are not rendered!');
          // 开始第二次检查（容错处理）
          else _this4.checkRenderState(true);
        }
      }, 300);
    },
    // 生成并下载PDF
    download: function download() {
      // PDF命名规则：项目名称-开盘报告-年月日时分秒
      var name = this.project.name;
      // let fileName = `${name}-开盘报告(${(this.project.buy_type === 2 ? '协助' : '自助')})-概况-${moment().format('YYYYMMDDHHmmss')}`
      var fileName = "".concat(name, "-\u5F00\u76D8\u6982\u51B5\u62A5\u544A");
      htmlToPdf.downloadPDF(document.getElementById('pdf_node'), fileName);
    },
    // 数组分割
    arrSlice: function arrSlice(arr, num) {
      if (num == 0) {
        return arr;
      }
      var newArr = [];
      for (var i = 0; i < arr.length; i += num) {
        newArr.push(arr.slice(i, i + num));
      }
      return newArr;
    }
  })
};