import request from '@/utils/request';

// 获取区域
var areaPleadgeList = function areaPleadgeList(params) {
  return request({
    url: '/identify/fromAreaList',
    method: 'get',
    params: params
  });
};

// 获取表单数据
var formPleadgeList = function formPleadgeList(params) {
  return request({
    url: '/identify/fromList',
    method: 'get',
    params: params
  });
};

// 添加题目
var addPleadgeTitle = function addPleadgeTitle(data) {
  return request({
    url: '/identify/addFrom',
    method: 'post',
    data: data
  });
};

// 删除题目
var delPleadgeTitle = function delPleadgeTitle(params) {
  return request({
    url: '/identify/del',
    method: 'get',
    params: params
  });
};

// 修改题目
var editPleadgeTitle = function editPleadgeTitle(data) {
  return request({
    url: '/identify/saveFrom',
    method: 'post',
    data: data
  });
};

// 修改版块
var editPleadgeSave = function editPleadgeSave(data) {
  return request({
    url: '/identify/formAreaSave',
    method: 'post',
    data: data
  });
};

// 单步 驳回 审核
var AuditIde0 = function AuditIde0(data) {
  return request({
    url: '/identifyAudit/AuditIde0',
    method: 'post',
    data: data
  });
};

// 初步 驳回 审核
var AuditIde1 = function AuditIde1(data) {
  return request({
    url: '/identifyAudit/AuditIde1',
    method: 'post',
    data: data
  });
};

// 复审 驳回 审核
var AuditIde2 = function AuditIde2(data) {
  return request({
    url: '/identifyAudit/AuditIde2',
    method: 'post',
    data: data
  });
};
// 再次驳回
var identifyAuditAgainRejected = function identifyAuditAgainRejected(data) {
  return request({
    url: '/identifyAudit/againRejected',
    method: 'post',
    data: data
  });
};
// 再次驳回并拒绝提交
var identifyAuditRejectAgain = function identifyAuditRejectAgain(data) {
  return request({
    url: '/identifyAudit/rejectAgain',
    method: 'post',
    data: data
  });
};

// 单步详情
var infoAuditIde0 = function infoAuditIde0(params) {
  return request({
    url: 'identifyAudit/AuditIde0',
    method: 'get',
    params: params
  });
};

// 初审详情
var infoAuditIde1 = function infoAuditIde1(params) {
  return request({
    url: 'identifyAudit/AuditIde1',
    method: 'get',
    params: params
  });
};

// 初审详情
var infoAuditIde2 = function infoAuditIde2(params) {
  return request({
    url: 'identifyAudit/AuditIde2',
    method: 'get',
    params: params
  });
};

// 保存附表
var AuditIdeSave = function AuditIdeSave(data) {
  return request({
    url: '/identifyAudit/AuditStatusSave',
    method: 'post',
    data: data
  });
};

//  ----- 客户表设置 -----

// 获取区域
var areaList = function areaList(params) {
  return request({
    url: '/form/fromAreaList',
    method: 'get',
    params: params
  });
};
// 获取表单数据
var formList = function formList(params) {
  return request({
    url: '/form/fromList',
    method: 'get',
    params: params
  });
};

// 查看活动配置
var auditEvent = function auditEvent(params) {
  return request({
    url: '/auditEvent/index',
    method: 'get',
    params: params
  });
};

// 保存活动配置
var saveAuditEvent = function saveAuditEvent(data) {
  return request({
    url: '/auditEvent/preserve',
    method: 'post',
    data: data
  });
};

// 添加题目
var addTitle = function addTitle(data) {
  return request({
    url: '/form/addFrom',
    method: 'post',
    data: data
  });
};

// 修改题目
var editTitle = function editTitle(data) {
  return request({
    url: '/form/saveFrom',
    method: 'post',
    data: data
  });
};

// 删除题目
var delTitle = function delTitle(params) {
  return request({
    url: '/form/del',
    method: 'get',
    params: params
  });
};

// 列表排序
var listSort = function listSort(data) {
  return request({
    url: '/form/sortFrom',
    method: 'post',
    data: data
  });
};
export function syncSystem(params) {
  return request({
    url: 'auditEvent/sync',
    method: 'GET',
    params: params
  });
}

// 列表排序
var listSortIdentify = function listSortIdentify(data) {
  return request({
    url: '/identify/sortFrom',
    method: 'post',
    data: data
  });
};

// 父级题目获取
var identifyGetParent = function identifyGetParent(params) {
  return request({
    url: 'identify/getParent',
    method: 'get',
    params: params
  });
};
// 获取隐藏表单
var identifyGetHide = function identifyGetHide(params) {
  return request({
    url: 'identify/getHide',
    method: 'get',
    params: params
  });
};

// 修改版块
var editPlateSave = function editPlateSave(data) {
  return request({
    url: '/form/formAreaSave',
    method: 'post',
    data: data
  });
};
// 获取父级表单
var formGetParent = function formGetParent(params) {
  return request({
    url: '/form/getParent',
    method: 'get',
    params: params
  });
};
// 获取隐藏表单
var formGetHide = function formGetHide(params) {
  return request({
    url: '/form/getHide',
    method: 'get',
    params: params
  });
};

// --------------------------------------
// 单步审核列表
var auditListAlone = function auditListAlone(params) {
  return request({
    url: 'audit/auditAloneList',
    method: 'get',
    params: params
  });
};

// 初审列表
var auditListFirst = function auditListFirst(params) {
  return request({
    url: '/audit/auditFirstList',
    method: 'get',
    params: params
  });
};

// 复审列表
var auditListSecond = function auditListSecond(params) {
  return request({
    url: '/audit/auditComplexList',
    method: 'get',
    params: params
  });
};

// 转入客户
var transferToCustomerSingle = function transferToCustomerSingle(params) {
  return request({
    url: '/audit/intoUser',
    method: 'get',
    params: params
  });
};

// 批量转入客户
var transferToCustomer = function transferToCustomer(data) {
  return request({
    url: '/audit/intoUserAll',
    method: 'post',
    data: data
  });
};

// 批量转入客户
export var sign = function sign(data) {
  return request({
    url: 'identifySign/sign',
    method: 'post',
    data: data
  });
};

// 导出数据
var exportAuditUser = function exportAuditUser(params) {
  return request({
    url: '/audit/export',
    method: 'get',
    params: params
  });
};

// 单步详情
var exportAuditStatus0 = function exportAuditStatus0(params) {
  return request({
    url: 'audit/AuditInfo0',
    method: 'get',
    params: params
  });
};

// 初审详情
var exportAuditStatus1 = function exportAuditStatus1(params) {
  return request({
    url: 'audit/AuditInfo1',
    method: 'get',
    params: params
  });
};

// 初审详情
var exportAuditStatus2 = function exportAuditStatus2(params) {
  return request({
    url: 'audit/AuditInfo2',
    method: 'get',
    params: params
  });
};

// 单步审核
var singleStepAudit = function singleStepAudit(data) {
  return request({
    url: '/audit/AuditStatus0',
    method: 'post',
    data: data
  });
};

// 初审审核
var preliminaryAudit = function preliminaryAudit(data) {
  return request({
    url: '/audit/AuditStatus1',
    method: 'post',
    data: data
  });
};

// 复审审核
var reviewAudit = function reviewAudit(data) {
  return request({
    url: '/audit/AuditStatus2',
    method: 'post',
    data: data
  });
};

// 批量审核
var batchReview = function batchReview(data) {
  return request({
    url: '/audit/AllAudit',
    method: 'post',
    data: data
  });
};

// 项目配置
var adminSetting = function adminSetting(params) {
  return request({
    url: '/auth/parameter',
    method: 'get',
    params: params
  });
};

// 修改表单
var AuditStatusEdit0 = function AuditStatusEdit0(data) {
  return request({
    url: '/audit/AuditStatusEdit0',
    method: 'post',
    data: data
  });
};
var AuditStatusEdit1 = function AuditStatusEdit1(data) {
  return request({
    url: '/audit/AuditStatusEdit1',
    method: 'post',
    data: data
  });
};
var AuditStatusEdit2 = function AuditStatusEdit2(data) {
  return request({
    url: '/audit/AuditStatusEdit2',
    method: 'post',
    data: data
  });
};

// 保存附表
var AuditStatusSave = function AuditStatusSave(data) {
  return request({
    url: '/audit/AuditStatusSave',
    method: 'post',
    data: data
  });
};

// 单步 驳回 审核
var AuditStatus0 = function AuditStatus0(data) {
  return request({
    url: '/audit/AuditStatus0',
    method: 'post',
    data: data
  });
};

// 初步 驳回 审核
var AuditStatus1 = function AuditStatus1(data) {
  return request({
    url: '/audit/AuditStatus1',
    method: 'post',
    data: data
  });
};

// 复审 驳回 审核
var AuditStatus2 = function AuditStatus2(data) {
  return request({
    url: '/audit/AuditStatus2',
    method: 'post',
    data: data
  });
};
// 再次驳回
var AgainRejected = function AgainRejected(data) {
  return request({
    url: '/audit/againRejected',
    method: 'post',
    data: data
  });
};
// 再次驳回并拒绝提交
var RejectAgain = function RejectAgain(data) {
  return request({
    url: '/audit/rejectAgain',
    method: 'post',
    data: data
  });
};

// 批量删除
var auditDelAll = function auditDelAll(data) {
  return request({
    url: '/audit/AllDel',
    method: 'post',
    data: data
  });
};

// 报名审核中允许再次提交
var acceptSubmitAgain = function acceptSubmitAgain(data) {
  return request({
    url: '/audit/AllowSubmit',
    method: 'post',
    data: data
  });
};

// 报名审核中允许再次提交
var identifyAuditSubmitAgain = function identifyAuditSubmitAgain(data) {
  return request({
    url: '/identifyAudit/AllowSubmit',
    method: 'post',
    data: data
  });
};
var iedAuditStatus0 = function iedAuditStatus0(params) {
  return request({
    url: 'identifyAudit/AuditInfo0',
    method: 'get',
    params: params
  });
};

// 初审详情
var iedAuditStatus1 = function iedAuditStatus1(params) {
  return request({
    url: 'identifyAudit/AuditInfo1',
    method: 'get',
    params: params
  });
};

// 初审详情
var iedAuditStatus2 = function iedAuditStatus2(params) {
  return request({
    url: 'identifyAudit/AuditInfo2',
    method: 'get',
    params: params
  });
};
function down() {
  return request({
    url: "auditEvent/down"
  });
}
export var copyForm = function copyForm(data) {
  return request({
    url: 'form/copyForm',
    method: 'POST',
    data: data
  });
};
export var getProject = function getProject(params) {
  return request({
    url: 'form/getProject',
    method: 'GET',
    params: params
  });
};
export var addideform = function addideform(data) {
  return request({
    url: 'form/addIdeForm',
    method: 'POST',
    data: data
  });
};
export var delideform = function delideform(data) {
  return request({
    url: 'form/delIdeForm',
    method: 'POST',
    data: data
  });
};
// 升筹相关接口
// 获取区域
var raiareaPleadgeList = function raiareaPleadgeList(params) {
  return request({
    url: '/rai/fromAreaList',
    method: 'get',
    params: params
  });
};
// 获取表单数据
var raifromList = function raifromList(params) {
  return request({
    url: '/rai/fromList',
    method: 'get',
    params: params
  });
};
// 添加题目
var raiaddPleadgeTitle = function raiaddPleadgeTitle(data) {
  return request({
    url: '/rai/addFrom',
    method: 'post',
    data: data
  });
};
// 修改题目
var raieditPleadgeTitle = function raieditPleadgeTitle(data) {
  return request({
    url: '/rai/saveFrom',
    method: 'post',
    data: data
  });
};
// 列表排序
var railistSortIdentify = function railistSortIdentify(data) {
  return request({
    url: '/rai/sortFrom',
    method: 'post',
    data: data
  });
};
// 删除题目
var raidelPleadgeTitle = function raidelPleadgeTitle(params) {
  return request({
    url: '/rai/del',
    method: 'get',
    params: params
  });
};
// 修改版块
var raieditPleadgeSave = function raieditPleadgeSave(data) {
  return request({
    url: '/rai/formAreaSave',
    method: 'post',
    data: data
  });
};
// 父级题目获取
var raiidentifyGetParent = function raiidentifyGetParent(params) {
  return request({
    url: '/rai/getParent',
    method: 'get',
    params: params
  });
};

// 获取隐藏表单
var raiidentifyGetHide = function raiidentifyGetHide(params) {
  return request({
    url: 'rai/getHide',
    method: 'get',
    params: params
  });
};
// 单步详情
var AuditRai0 = function AuditRai0(params) {
  return request({
    url: 'raisingFundsAudit/AuditIde0',
    method: 'get',
    params: params
  });
};
var AuditRai1 = function AuditRai1(data) {
  return request({
    url: '/raisingFundsAudit/AuditRai1',
    method: 'post',
    data: data
  });
};
// 复审 驳回 审核
var AuditRai2 = function AuditRai2(data) {
  return request({
    url: '/raisingFundsAudit/AuditRai2',
    method: 'post',
    data: data
  });
};
var exportRaiAuditInfo0 = function exportRaiAuditInfo0(params) {
  return request({
    url: 'raisingFundsAudit/AuditInfo0',
    method: 'get',
    params: params
  });
};
// 初审详情
var exportRaiAuditInfo1 = function exportRaiAuditInfo1(params) {
  return request({
    url: 'raisingFundsAudit/AuditInfo1',
    method: 'get',
    params: params
  });
};
var exportRaiAuditInfo2 = function exportRaiAuditInfo2(params) {
  return request({
    url: 'raisingFundsAudit/AuditInfo2',
    method: 'get',
    params: params
  });
};
// 再次驳回
var raiAuditAgainRejected = function raiAuditAgainRejected(data) {
  return request({
    url: '/raisingFundsAudit/againRejected',
    method: 'post',
    data: data
  });
};
// 再次驳回并拒绝提交
var raiAuditRejectAgain = function raiAuditRejectAgain(data) {
  return request({
    url: '/raisingFundsAudit/rejectAgain',
    method: 'post',
    data: data
  });
};
export { raiareaPleadgeList, raifromList, raiaddPleadgeTitle, raieditPleadgeTitle, railistSortIdentify, raidelPleadgeTitle, raieditPleadgeSave, raiidentifyGetParent, raiidentifyGetHide, AuditRai0, AuditRai1, AuditRai2, exportRaiAuditInfo0, exportRaiAuditInfo1, exportRaiAuditInfo2, raiAuditAgainRejected, raiAuditRejectAgain, iedAuditStatus0, iedAuditStatus1, iedAuditStatus2, listSortIdentify, identifyGetParent, identifyGetHide, AuditIdeSave, infoAuditIde0, infoAuditIde1, infoAuditIde2, areaPleadgeList, formPleadgeList, addPleadgeTitle, delPleadgeTitle, editPleadgeTitle, editPleadgeSave, areaList, formList, auditEvent, saveAuditEvent, addTitle, editTitle, delTitle, listSort, editPlateSave, formGetParent, formGetHide, auditListAlone, auditListFirst, auditListSecond, transferToCustomerSingle, transferToCustomer, exportAuditUser, exportAuditStatus0, exportAuditStatus1, exportAuditStatus2, singleStepAudit, preliminaryAudit, reviewAudit, batchReview, adminSetting, AuditStatusEdit0, AuditStatusEdit1, AuditStatusEdit2, AuditStatusSave, AuditStatus0, AuditStatus1, AuditStatus2, AuditIde0, AuditIde1, AuditIde2, auditDelAll, acceptSubmitAgain, identifyAuditSubmitAgain, AgainRejected, RejectAgain, identifyAuditAgainRejected, identifyAuditRejectAgain, down };