var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "sincerity-register container" },
    [
      _c("div", { ref: "apptop", staticClass: "top-fixed" }, [
        _c("div", { staticClass: "title-top-box" }, [
          _c(
            "div",
            { staticClass: "title-left" },
            [
              _c("van-switch", {
                attrs: { "switch-list": _vm.switchList },
                on: { bindSwitch: _vm.bindSwitch },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "auditevent/preserve"],
                      expression: "['admin', 'auditevent/preserve']",
                    },
                  ],
                  staticClass: "orange-btn",
                  attrs: { size: "small" },
                  on: { click: _vm.editMes },
                },
                [_vm._v(_vm._s(_vm.type ? "修改" : "保存"))]
              ),
              _c(
                "jian-fa",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "auditevent/sync"],
                      expression: "['admin', 'auditevent/sync']",
                    },
                  ],
                  staticClass: "greens-btn",
                  attrs: { tag: "el-button", size: "small" },
                  on: { click: _vm.submitSyncSystem },
                },
                [_vm._v(" 同步认筹方案 ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "auditevent/down"],
                      expression: "['admin', 'auditevent/down']",
                    },
                  ],
                  staticClass: "btnHollowGreen",
                  attrs: { size: "small" },
                  on: { click: _vm.exportReview },
                },
                [_vm._v(" 导出复核 ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "screen-table" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btnIndex == 0,
                  expression: "btnIndex == 0",
                },
              ],
              staticClass: "base-message",
            },
            [
              _c("linkSetup", {
                ref: "linksetup",
                attrs: {
                  type: _vm.type,
                  audit: _vm.auditconfig,
                  rule: _vm.list,
                  auditEventInfo: _vm.auditEventInfo,
                },
              }),
              _c(
                "el-form",
                {
                  attrs: {
                    "label-position": _vm.isM ? "left" : "right",
                    "label-width": "160px",
                  },
                },
                [
                  _vm.auditEventInfo.ide_batch_num
                    ? _c(
                        "jian-fa",
                        {
                          attrs: {
                            tag: "el-form-item",
                            label: "认筹批次编号:",
                          },
                        },
                        [
                          _c("div", { staticClass: "left-content" }, [
                            _c("div", { staticClass: "flex-row" }, [
                              _vm._v(_vm._s(_vm.auditEventInfo.ide_batch_num)),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "linktitle" }, [_vm._v("其他设置")]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "装户综合分析客户范围:" } },
                    [
                      _c("div", { staticClass: "left-content" }, [
                        _c("div", { staticClass: "flex-rows" }, [
                          _c("div", { staticClass: "link" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.auditconfig.register_name)),
                            ]),
                            _c(
                              "div",
                              { staticClass: "select-content" },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { disabled: _vm.type },
                                    model: {
                                      value:
                                        _vm.auditEventInfo.reg_collect_range,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.auditEventInfo,
                                          "reg_collect_range",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "auditEventInfo.reg_collect_range",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        staticStyle: {
                                          margin: "10px 0",
                                          display: "block",
                                        },
                                        attrs: { label: 0 },
                                      },
                                      [_vm._v("表单已提交 ")]
                                    ),
                                    _c(
                                      "el-radio",
                                      {
                                        staticStyle: {
                                          margin: "10px 0",
                                          display: "block",
                                        },
                                        attrs: { label: 1 },
                                      },
                                      [_vm._v("填单审核已通过 ")]
                                    ),
                                    _vm.auditconfig.is_register_price
                                      ? _c(
                                          "el-radio",
                                          {
                                            staticStyle: {
                                              margin: "10px 0",
                                              display: "block",
                                            },
                                            attrs: { label: 2 },
                                          },
                                          [_vm._v("已交款 ")]
                                        )
                                      : _vm._e(),
                                    _vm.auditconfig.is_register_sign
                                      ? _c(
                                          "el-radio",
                                          {
                                            staticStyle: {
                                              margin: "10px 0",
                                              display: "block",
                                            },
                                            attrs: { label: 3 },
                                          },
                                          [_vm._v("已签约 ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm.list.is_identify
                            ? _c("div", { staticClass: "link" }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(_vm._s(_vm.auditconfig.identify_name)),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "select-content" },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { disabled: _vm.type },
                                        model: {
                                          value:
                                            _vm.auditEventInfo
                                              .ide_collect_range,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.auditEventInfo,
                                              "ide_collect_range",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "auditEventInfo.ide_collect_range",
                                        },
                                      },
                                      [
                                        _vm.auditconfig.is_identify_form
                                          ? _c(
                                              "el-radio",
                                              {
                                                staticStyle: {
                                                  margin: "10px 0",
                                                  display: "block",
                                                },
                                                attrs: { label: 0 },
                                              },
                                              [_vm._v("表单已提交 ")]
                                            )
                                          : _vm._e(),
                                        _vm.auditconfig.is_identify_form
                                          ? _c(
                                              "el-radio",
                                              {
                                                staticStyle: {
                                                  margin: "10px 0",
                                                  display: "block",
                                                },
                                                attrs: { label: 1 },
                                              },
                                              [_vm._v("填单审核已通过 ")]
                                            )
                                          : _vm._e(),
                                        _vm.auditconfig.is_identify_price
                                          ? _c(
                                              "el-radio",
                                              {
                                                staticStyle: {
                                                  margin: "10px 0",
                                                  display: "block",
                                                },
                                                attrs: { label: 2 },
                                              },
                                              [_vm._v("已交款 ")]
                                            )
                                          : _vm._e(),
                                        _vm.auditconfig.is_identify_sign
                                          ? _c(
                                              "el-radio",
                                              {
                                                staticStyle: {
                                                  margin: "10px 0",
                                                  display: "block",
                                                },
                                                attrs: { label: 3 },
                                              },
                                              [_vm._v("已签约 ")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.list.is_raising_funds
                            ? _c("div", { staticClass: "link" }, [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(
                                    _vm._s(_vm.auditconfig.raising_funds_name)
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "select-content" },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { disabled: _vm.type },
                                        model: {
                                          value:
                                            _vm.auditEventInfo
                                              .rai_collect_range,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.auditEventInfo,
                                              "rai_collect_range",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "auditEventInfo.rai_collect_range",
                                        },
                                      },
                                      [
                                        _vm.auditconfig.is_raising_funds_form
                                          ? _c(
                                              "el-radio",
                                              {
                                                staticStyle: {
                                                  margin: "10px 0",
                                                  display: "block",
                                                },
                                                attrs: { label: 0 },
                                              },
                                              [_vm._v("表单已提交 ")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-radio",
                                          {
                                            staticStyle: {
                                              margin: "10px 0",
                                              display: "block",
                                            },
                                            attrs: { label: 1 },
                                          },
                                          [_vm._v("填单审核已通过 ")]
                                        ),
                                        _vm.auditconfig.is_raising_funds_price
                                          ? _c(
                                              "el-radio",
                                              {
                                                staticStyle: {
                                                  margin: "10px 0",
                                                  display: "block",
                                                },
                                                attrs: { label: 2 },
                                              },
                                              [_vm._v("已交款 ")]
                                            )
                                          : _vm._e(),
                                        _vm.auditconfig.is_raising_funds_sign
                                          ? _c(
                                              "el-radio",
                                              {
                                                staticStyle: {
                                                  margin: "10px 0",
                                                  display: "block",
                                                },
                                                attrs: { label: 3 },
                                              },
                                              [_vm._v("已签约 ")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "诚意登记端登录背景图:" } },
                    [
                      _c(
                        "div",
                        { staticClass: "img-upload left-content" },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.login_image != "" && _vm.type,
                                  expression: "login_image != '' && type ",
                                },
                              ],
                              staticClass: "img-show",
                            },
                            [
                              _vm.login_image
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.replaceImgshow(_vm.login_image),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "el-upload",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.type,
                                  expression: "!type",
                                },
                              ],
                              class: { hiddenUp: _vm.hiddenUp },
                              attrs: {
                                action: "",
                                "before-upload": _vm.beforeAvatarUpload,
                                "http-request": _vm.handleUpload,
                                "file-list": _vm.fileList,
                                limit: 1,
                                multiple: false,
                                "on-preview": _vm.handlePictureCardPreview,
                                "on-remove": _vm.handleRemove,
                                "on-success": _vm.imgupSuccess,
                                accept: ".png, .jpg",
                                "list-type": "picture-card",
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-plus" }),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _vm._v(
                                    "图片最佳尺寸为（1125*690）px，不大于2M"
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "linktitle" }, [_vm._v("文案设置")]),
                  _c("el-form-item", { attrs: { label: "报名须知名称:" } }, [
                    _c(
                      "div",
                      { staticClass: "left-content" },
                      [
                        _vm.type
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.auditEventInfo.name)),
                            ])
                          : _c("el-input", {
                              attrs: {
                                clearable: "",
                                maxlength: "16",
                                minlength: "0",
                                placeholder: "请输入内容",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.auditEventInfo.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.auditEventInfo, "name", $$v)
                                },
                                expression: "auditEventInfo.name",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "须知正文:" } }, [
                    _c(
                      "div",
                      { staticClass: "kind-editor-wrapper left-content" },
                      [
                        _c("kind-editor", {
                          ref: "kindEditor1",
                          attrs: {
                            id: "k-one",
                            content: _vm.auditEventInfo.notice_text,
                            readonly: _vm.type,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "准备资料名称:" } }, [
                    _c(
                      "div",
                      { staticClass: "left-content" },
                      [
                        _vm.type
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.auditEventInfo.content_name)),
                            ])
                          : _c("el-input", {
                              attrs: {
                                clearable: "",
                                maxlength: "16",
                                minlength: "0",
                                placeholder: "请输入内容",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.auditEventInfo.content_name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.auditEventInfo,
                                    "content_name",
                                    $$v
                                  )
                                },
                                expression: "auditEventInfo.content_name",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "客户准备资料:" } }, [
                    _c(
                      "div",
                      { staticClass: "kind-editor-wrapper left-content" },
                      [
                        _c("kind-editor", {
                          ref: "kindEditor2",
                          attrs: {
                            id: "k-two",
                            content: _vm.auditEventInfo.content,
                            readonly: _vm.type,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "公告正文:" } }, [
                    _c(
                      "div",
                      { staticClass: "kind-editor-wrapper left-content" },
                      [
                        _c("kind-editor", {
                          ref: "kindEditor3",
                          attrs: {
                            id: "k-three",
                            content: _vm.auditEventInfo.inform_text,
                            readonly: _vm.type,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btnIndex == 1,
                  expression: "btnIndex == 1",
                },
              ],
              staticClass: "radio-choose-box",
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { fill: "#22A271", "text-color": "#FFFFFF" },
                  on: { change: _vm.chooseRadioChange },
                  model: {
                    value: _vm.chooseRadio,
                    callback: function ($$v) {
                      _vm.chooseRadio = $$v
                    },
                    expression: "chooseRadio",
                  },
                },
                _vm._l(_vm.area, function (item) {
                  return _c(
                    "el-radio-button",
                    { key: item.id, attrs: { label: item.id } },
                    [_vm._v(_vm._s(item.area_name))]
                  )
                }),
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        content:
                          "项目一阶段为冻资，请点击此按钮，增加认筹信息填写",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "form/addideform"],
                              expression: "['admin', 'form/addideform']",
                            },
                          ],
                          staticClass: "btnHollowGreen",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.addRcinfo()
                            },
                          },
                        },
                        [_vm._v("增加认筹信息填写")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["admin", "form/delideform"],
                          expression: "['admin', 'form/delideform']",
                        },
                      ],
                      staticClass: "btnHollowGreen",
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.closeRcinfo()
                        },
                      },
                    },
                    [_vm._v("取消认筹信息填写")]
                  ),
                ],
                1
              ),
              false
                ? _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.type,
                              expression: "!type",
                            },
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "auditevent/preserve"],
                              expression: "['admin', 'auditevent/preserve']",
                            },
                          ],
                          staticClass: "btnHollowGreen",
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              _vm.isEditPlate = true
                              _vm.getAreaList()
                            },
                          },
                        },
                        [_vm._v("编辑板块")]
                      ),
                      !_vm.type
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["admin", "auditevent/preserve"],
                                  expression:
                                    "['admin', 'auditevent/preserve']",
                                },
                              ],
                              staticClass: "btnHollowGreen",
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.addNewTitle(0, 0)
                                },
                              },
                            },
                            [_vm._v("增加题目")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btnIndex == 1,
                  expression: "btnIndex == 1",
                },
                {
                  name: "permission",
                  rawName: "v-permission",
                  value: ["admin", "auditevent/preserve"],
                  expression: "['admin', 'auditevent/preserve']",
                },
              ],
              attrs: {
                data: _vm.formListTable,
                "header-cell-style": { background: "#fafafa" },
                "row-class-name": _vm.getRowClassName,
                "show-header": false,
                border: "",
                "highlight-current-row": "",
                size: "small",
              },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", type: "index" },
              }),
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "div",
                          { staticClass: "expand-content" },
                          [
                            _c(
                              "el-form",
                              {
                                staticClass: "demo-table-expand",
                                attrs: { inline: "", "label-position": "left" },
                              },
                              _vm._l(props.row.children, function (item, i) {
                                return _c("el-form-item", { key: i }, [
                                  _c("span", [_vm._v(_vm._s(item.name))]),
                                ])
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "name-wrapper" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.name) +
                                " [" +
                                _vm._s(
                                  _vm.textTypeChange(scope.row.form_type)
                                ) +
                                "]"
                            ),
                          ]),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    scope.row.placeholder &&
                                    scope.row.placeholder != "",
                                  expression:
                                    "scope.row.placeholder && scope.row.placeholder != '' ",
                                },
                              ],
                              staticClass: "name-wrapper-placehoder",
                            },
                            [_vm._v("(" + _vm._s(scope.row.placeholder) + ")")]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", prop: "fill_in_type", width: "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "check-box" }, [
                          _vm._v(" 客户登记表 "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btnIndex == 2,
                  expression: "btnIndex == 2",
                },
              ],
            },
            [
              _c("form-data", {
                attrs: {
                  pleadge: _vm.pleadge,
                  "area-id": _vm.areaPLeadgeID,
                  type: _vm.type,
                  "form-data": _vm.pleadgeFormList,
                  ide_selectType: _vm.ide_selectType,
                  isM: _vm.isM,
                },
                on: {
                  handleTabs: _vm.handleTabs,
                  handleEditPlate: _vm.getAreaList,
                  delTable: _vm.handleDelTable,
                  formList: _vm.formList,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.btnIndex == 3,
                  expression: "btnIndex == 3",
                },
              ],
            },
            [
              _c("funds-form", {
                attrs: {
                  pleadge: _vm.raipleadge,
                  "area-id": _vm.raiareaPLeadgeID,
                  type: _vm.type,
                  "form-data": _vm.raipleadgeFormList,
                  ide_selectType: _vm.raiide_selectType,
                  isM: _vm.isM,
                },
                on: {
                  handleTabs: _vm.handleraiTabs,
                  handleEditPlate: _vm.getAreaList,
                  delTable: _vm.handleraiDelTable,
                  formList: _vm.handleraiTabs,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "add-dialog",
          attrs: {
            "close-on-click-modal": false,
            title: _vm.addNewTitleText,
            visible: _vm.addNewTitleDialogVisible,
            height: "500px",
            width: _vm.isM ? "95%" : "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addNewTitleDialogVisible = $event
            },
            close: _vm.addNewTitleDialogClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c(
                "el-form",
                {
                  ref: "addTitleForm",
                  attrs: {
                    model: _vm.addTitleForm,
                    rules: _vm.addTitleFormRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "题目名称", prop: "name" } },
                    [
                      _c(
                        "div",
                        { staticClass: "right-input-box" },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: _vm.addTitleForm.is_system == 1,
                              clearable: "",
                              maxlength: "16",
                              minlength: "0",
                              placeholder: "请输入内容",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.addTitleForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.addTitleForm, "name", $$v)
                              },
                              expression: "addTitleForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.addTitleForm.is_system != 1 &&
                  _vm.addTitleForm.owner_type !== 70
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "题目类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "addNewTitle-radio",
                              on: { change: _vm.titleTypeChange },
                              model: {
                                value: _vm.addTitleForm.form_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addTitleForm, "form_type", $$v)
                                },
                                expression: "addTitleForm.form_type",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticClass: "typeRadio",
                                  attrs: { label: "radio" },
                                },
                                [_vm._v("单选下拉框")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  staticClass: "typeRadio",
                                  attrs: { label: "select" },
                                },
                                [_vm._v("多选下拉框")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  staticClass: "typeRadio",
                                  attrs: { label: "text" },
                                },
                                [_vm._v("文本填写框")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  staticClass: "typeRadio",
                                  attrs: { label: "number" },
                                },
                                [_vm._v("数字填写框")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  staticClass: "typeRadio",
                                  attrs: { label: "file" },
                                },
                                [_vm._v("图片上传")]
                              ),
                              _vm.reg_selectType === 0
                                ? _c(
                                    "el-radio",
                                    {
                                      staticClass: "typeRadio",
                                      attrs: { label: "hide" },
                                    },
                                    [_vm._v("隐藏表单")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("el-form-item", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.addTitleForm.form_type == "radio" ||
                          _vm.addTitleForm.form_type == "select",
                        expression:
                          "addTitleForm.form_type == 'radio' || addTitleForm.form_type == 'select' ",
                      },
                    ],
                    attrs: { label: "配置选项" },
                  }),
                  _vm.addTitleForm.owner_type !== 70
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.addTitleForm.form_type == "radio" ||
                                _vm.addTitleForm.form_type == "select",
                              expression:
                                "addTitleForm.form_type == 'radio' || addTitleForm.form_type == 'select' ",
                            },
                          ],
                          staticClass: "choose-option-wrapper",
                        },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "addTitleFormChildren",
                              attrs: {
                                "hide-required-asterisk": true,
                                model: _vm.addTitleForm,
                              },
                            },
                            [
                              _vm._l(
                                _vm.addTitleForm.children,
                                function (item, i) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        key: i,
                                        attrs: {
                                          prop: "children." + i + ".name",
                                          rules: [
                                            {
                                              required: true,
                                              message: "选项内容为必填项",
                                              trigger: "blur",
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "label-box",
                                            attrs: { slot: "label" },
                                            slot: "label",
                                          },
                                          [
                                            _c("svg-icon", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: item.is_system !== 1,
                                                  expression:
                                                    "item.is_system !== 1",
                                                },
                                              ],
                                              attrs: {
                                                "class-name": "greens",
                                                "icon-class": "reduce",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delNewOptions(item)
                                                },
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v("选项" + _vm._s(i + 1)),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "right-input-box" },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: item.is_system == 1,
                                                clearable: "",
                                                maxlength: "16",
                                                minlength: "0",
                                                placeholder: "请输入选项",
                                                "show-word-limit": "",
                                              },
                                              model: {
                                                value: item.name,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "name", $$v)
                                                },
                                                expression: "item.name",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "sort-wrapper" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "sort-arrow" },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        "class-name": "greens",
                                                        "icon-class":
                                                          "up_arrow",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.btnArrow(
                                                            i,
                                                            item,
                                                            0
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "sort-arrow" },
                                                  [
                                                    _c("svg-icon", {
                                                      attrs: {
                                                        "class-name": "greens",
                                                        "icon-class":
                                                          "down_arrow",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.btnArrow(
                                                            i,
                                                            item,
                                                            1
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm.addTitleForm.owner_type === 83
                                      ? _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              "label-width": "105px",
                                              label: "最大数量",
                                            },
                                          },
                                          [
                                            _c("el-input-number", {
                                              attrs: {
                                                min: 0,
                                                disabled: item.is_system == 1,
                                              },
                                              model: {
                                                value: item.max_number,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "max_number",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.max_number",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                }
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { "label-width": "124px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "label-box",
                                      attrs: { slot: "label" },
                                      on: { click: _vm.addNewOptions },
                                      slot: "label",
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "class-name": "greens",
                                          "icon-class": "add",
                                        },
                                      }),
                                      _c("span", [_vm._v("添加选项")]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addTitleForm.owner_type === 70
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "意向最大数量" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 1, max: 30, label: "数量上限" },
                            on: { change: _vm.verifyMinNumber },
                            model: {
                              value: _vm.addTitleForm.img_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.addTitleForm, "img_number", $$v)
                              },
                              expression: "addTitleForm.img_number",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addTitleForm.owner_type === 70
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "意向最小数量" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 1, max: 30, label: "数量上限" },
                            on: { change: _vm.verifyMinNumber },
                            model: {
                              value: _vm.addTitleForm.min_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.addTitleForm, "min_number", $$v)
                              },
                              expression: "addTitleForm.min_number",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.addTitleForm.form_type !== "hide",
                          expression: "addTitleForm.form_type !== 'hide'",
                        },
                      ],
                      attrs: { label: "提示语" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "right-input-box" },
                        [
                          _c("el-input", {
                            attrs: {
                              autosize: "",
                              clearable: "",
                              maxlength: "100",
                              minlength: "0",
                              placeholder: "请输入内容",
                              "show-word-limit": "",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.addTitleForm.placeholder,
                              callback: function ($$v) {
                                _vm.$set(_vm.addTitleForm, "placeholder", $$v)
                              },
                              expression: "addTitleForm.placeholder",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.addTitleForm.form_type !== "file" &&
                            _vm.addTitleForm.form_type !== "hide",
                          expression:
                            "addTitleForm.form_type !== 'file' && addTitleForm.form_type !== 'hide'",
                        },
                      ],
                      attrs: { label: "是否必填选项" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.addTitleForm.is_required,
                            callback: function ($$v) {
                              _vm.$set(_vm.addTitleForm, "is_required", $$v)
                            },
                            expression: "addTitleForm.is_required",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { staticClass: "typeRadio", attrs: { label: 0 } },
                            [_vm._v("必填")]
                          ),
                          _c(
                            "el-radio",
                            { staticClass: "typeRadio", attrs: { label: 1 } },
                            [_vm._v("非必填")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.addTitleForm.owner_type !== 70
                    ? _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.addTitleForm.form_type !== "file" &&
                                _vm.addTitleForm.form_type !== "hide",
                              expression:
                                "addTitleForm.form_type !== 'file' && addTitleForm.form_type !== 'hide'",
                            },
                          ],
                          staticStyle: { "text-align": "right" },
                          attrs: { label: "特殊说明" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              autosize: "",
                              clearable: "",
                              minlength: "0",
                              "show-word-limit": "",
                              type: "textarea",
                              placeholder: "若无特殊提示可留空",
                            },
                            model: {
                              value: _vm.addTitleForm.special_value,
                              callback: function ($$v) {
                                _vm.$set(_vm.addTitleForm, "special_value", $$v)
                              },
                              expression: "addTitleForm.special_value",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.addTitleForm.form_type === "file",
                          expression: "addTitleForm.form_type === 'file'",
                        },
                      ],
                      attrs: { label: "上传文件模式" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.addTitleForm.is_document,
                            callback: function ($$v) {
                              _vm.$set(_vm.addTitleForm, "is_document", $$v)
                            },
                            expression: "addTitleForm.is_document",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { staticClass: "typeRadio", attrs: { label: 1 } },
                            [_vm._v("启用")]
                          ),
                          _c(
                            "el-radio",
                            { staticClass: "typeRadio", attrs: { label: 0 } },
                            [_vm._v("不启用")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.addTitleForm.form_type === "file"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "最少上传数量" } },
                        [
                          _c("el-input-number", {
                            attrs: { max: 10, min: 1 },
                            model: {
                              value: _vm.addTitleForm.img_number,
                              callback: function ($$v) {
                                _vm.$set(_vm.addTitleForm, "img_number", $$v)
                              },
                              expression: "addTitleForm.img_number",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.addTitleForm.form_type === "text",
                          expression: "addTitleForm.form_type === 'text'",
                        },
                      ],
                      attrs: { label: "校验格式" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.addTitleForm.is_verification,
                            callback: function ($$v) {
                              _vm.$set(_vm.addTitleForm, "is_verification", $$v)
                            },
                            expression: "addTitleForm.is_verification",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { staticClass: "typeRadio", attrs: { label: 0 } },
                            [_vm._v("不校验")]
                          ),
                          _c(
                            "el-radio",
                            { staticClass: "typeRadio", attrs: { label: 2 } },
                            [_vm._v("身份证")]
                          ),
                          _c(
                            "el-radio",
                            { staticClass: "typeRadio", attrs: { label: 1 } },
                            [_vm._v("手机号校验")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.addTitleForm.owner_type !== 70
                    ? _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.addTitleForm.form_type !== "hide",
                              expression: "addTitleForm.form_type !== 'hide'",
                            },
                          ],
                          attrs: { label: "是否关联父级" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addTitleForm.is_parent_pid,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addTitleForm,
                                    "is_parent_pid",
                                    $$v
                                  )
                                },
                                expression: "addTitleForm.is_parent_pid",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticClass: "typeRadio",
                                  attrs: { label: true },
                                },
                                [_vm._v("是")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  staticClass: "typeRadio",
                                  attrs: { label: false },
                                },
                                [_vm._v("否")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.addTitleForm.is_parent_pid
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "父级题目名称", prop: "parent_pid" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "right-input-box" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  on: { change: _vm.onParent_pidChange },
                                  model: {
                                    value: _vm.addTitleForm.parent_pid,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addTitleForm,
                                        "parent_pid",
                                        $$v
                                      )
                                    },
                                    expression: "addTitleForm.parent_pid",
                                  },
                                },
                                [
                                  _c(
                                    "el-option",
                                    { attrs: { label: "请选择", value: 0 } },
                                    [_vm._v("请选择")]
                                  ),
                                  _vm._l(
                                    _vm.rarentList.form,
                                    function (item, index) {
                                      return _c(
                                        "el-option",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.addTitleForm.id !== item.id,
                                              expression:
                                                "addTitleForm.id !== item.id",
                                            },
                                          ],
                                          key: index,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.addTitleForm.is_parent_pid
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "父级题目选项", prop: "parent_id" } },
                        [
                          _c(
                            "div",
                            { staticClass: "right-input-box" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    multiple: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.addTitleForm.parent_id,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addTitleForm,
                                        "parent_id",
                                        $$v
                                      )
                                    },
                                    expression: "addTitleForm.parent_id",
                                  },
                                },
                                _vm._l(
                                  _vm.rarentList.option,
                                  function (item, index) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.addTitleForm.form_type !== "hide" &&
                  _vm.reg_selectType === 0 &&
                  _vm.addTitleForm.owner_type !== 70
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "隐藏表单", prop: "hide_id" } },
                        [
                          _c(
                            "div",
                            { staticClass: "right-input-box" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.addTitleForm.hide_id,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addTitleForm, "hide_id", $$v)
                                    },
                                    expression: "addTitleForm.hide_id",
                                  },
                                },
                                [
                                  _c(
                                    "el-option",
                                    { attrs: { label: "请选择", value: 0 } },
                                    [_vm._v("请选择")]
                                  ),
                                  _vm._l(_vm.hideList, function (item, index) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.id,
                                        },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addNewTitleDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addNewTitleSave },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.imgPreviewDialogVisible,
            title: "图片预览",
            width: _vm.isM ? "95%" : "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.imgPreviewDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "img-wrapper" }, [
            _c("img", {
              attrs: { src: _vm.replaceImgshow(_vm.login_image), alt: "" },
            }),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.imgPreviewDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            visible: _vm.isEditPlate,
            height: "500px",
            title: "编辑版块",
            width: _vm.isM ? "95%" : "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isEditPlate = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-content" },
            [
              _c(
                "el-form",
                {
                  ref: "newAreaForm",
                  attrs: {
                    "hide-required-asterisk": true,
                    model: _vm.newAreaForm,
                  },
                },
                [
                  _vm._l(_vm.newAreaForm.area, function (item, i) {
                    return _c(
                      "el-form-item",
                      {
                        key: i,
                        attrs: {
                          prop: "area." + i + ".area_name",
                          rules: [
                            {
                              required: true,
                              message: "选项内容为必填项",
                              trigger: "blur",
                            },
                          ],
                          "label-width": "50px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "label-box",
                            attrs: { slot: "label" },
                            slot: "label",
                          },
                          [
                            item.type != 1 && item.form_gf != true
                              ? _c("svg-icon", {
                                  attrs: {
                                    "class-name": "greens",
                                    "icon-class": "reduce",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.delPlate(i, item)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "right-input-box" },
                          [
                            _c("el-input", {
                              attrs: {
                                clearable: "",
                                maxlength: "16",
                                minlength: "0",
                                placeholder: "请输入板块名称",
                                "show-word-limit": "",
                              },
                              model: {
                                value: item.area_name,
                                callback: function ($$v) {
                                  _vm.$set(item, "area_name", $$v)
                                },
                                expression: "item.area_name",
                              },
                            }),
                            _c("div", { staticClass: "sort-wrapper" }, [
                              _c(
                                "span",
                                { staticClass: "sort-arrow" },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "class-name": "greens",
                                      "icon-class": "up_arrow",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.plateMove(i, item, 0)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "sort-arrow" },
                                [
                                  _c("svg-icon", {
                                    attrs: {
                                      "class-name": "greens",
                                      "icon-class": "down_arrow",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.plateMove(i, item, 1)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _c("el-form-item", { attrs: { "label-width": "150px" } }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.newAreaForm.area.length < 5,
                            expression: "newAreaForm.area.length < 5",
                          },
                        ],
                        staticClass: "label-box-plate",
                        on: { click: _vm.addNewPlate },
                      },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "class-name": "greens",
                            "icon-class": "add",
                          },
                        }),
                        _c("span", { staticClass: "add-new-plate" }, [
                          _vm._v("添加板块"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isEditPlate = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.editPlateSubmit },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("close-project", {
        attrs: { rule: _vm.list, audit: _vm.auditconfig },
        on: { copySuccess: _vm.re },
        model: {
          value: _vm.showClone,
          callback: function ($$v) {
            _vm.showClone = $$v
          },
          expression: "showClone",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }