import "core-js/modules/es.object.to-string.js";
/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-04-09 14:13,
 */

import request from '@/utils/request';
import store from '@/store';

//  获取项目配置
export function adminParameter(params) {
  return new Promise(function (resolve, reject) {
    request({
      url: 'auth/parameter',
      method: 'get',
      params: params
    }).then(function (response) {
      store.dispatch('config/set', response.data);
      resolve(response);
    }).catch(function (error) {
      reject(error);
    });
  });
}

// 获取 活动批次
export function projectConfigure(params) {
  return request({
    url: 'project/configure',
    method: 'get',
    params: params
  });
}

// 获取 正式或者测试
export function authGetEvent(params) {
  return request({
    url: '/auth/getEvent',
    method: 'get',
    params: params
  });
}
// 获取 OSSsts服务
export function uploadSts(params) {
  return request({
    url: '/upload/sts',
    method: 'get',
    params: params
  });
}
// 获取 OSSkey服务
export function uploadKey(data) {
  return request({
    url: '/upload/key',
    method: 'post',
    data: data
  });
}