import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url.to-json.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseTable from "./component/base-table.vue";
import BaseTableT from "./component/base-tableT.vue";
import CustomTable from "./component/custom-table.vue";
import printUpTemplate from "../tool/lottery/printUp/index";
// import { x, z } from "./temaplate.js";
import { mapGetters } from "vuex";
import { adminParameter } from '@/api/public.js';
import { prints, setHelpWord, setOrderWord, setIdeWord, getOrderVar, getIdeVar, signIndex, preserve, sealImg, signWord, setPriceWord, setRandPrint } from "@/api/template.js";
export default {
  name: "TemplatePrinting",
  components: {
    BaseTable: BaseTable,
    printUpTemplate: printUpTemplate,
    CustomTable: CustomTable,
    BaseTableT: BaseTableT
  },
  data: function data() {
    return {
      pageRadio: 1,
      pageNoPermission: true,
      help_type_list: [],
      help_word: {},
      help_word_type: 1,
      ide_type_list: [],
      ide_word: {},
      ide_word_type: 1,
      order_type_list: [],
      order_word: {},
      order_word_type: 1,
      price_word: {},
      word_rule: {},
      order_add_time: 0,
      // 认购书上传时间
      help_add_time: 0,
      // 流转单上传时间
      ide_add_time: 0,
      // 认筹单上传时间
      price_add_time: 0,
      // 定金上传时间
      x: [],
      z: [],
      yx: [],
      yz: [],
      loading: null,
      setting: {
        pc_help_print: 1,
        list: {}
      },
      // 在线签约
      company: {},
      lock: [],
      // 诚意签约
      ide_lock: [],
      fileList: [],
      // 显示图片
      dialogImageUrl: '',
      dialogVisible: false,
      searchKey: '',
      // 搜索关键词
      old: 0
    };
  },
  watch: {
    "word_rule.help_word_status": {
      handler: function handler(val, oldVal) {
        this.old = oldVal;
      },
      deep: true
    },
    "word_rule.order_word_status": {
      handler: function handler(val, oldVal) {
        this.old = oldVal;
      },
      deep: true
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["roles"])), {}, {
    is_identify_sign: function is_identify_sign() {
      var _this$setting;
      var arr = ((_this$setting = this.setting) === null || _this$setting === void 0 || (_this$setting = _this$setting.list) === null || _this$setting === void 0 ? void 0 : _this$setting.is_display) || [];
      return arr.includes('is_identify_sign');
    },
    showRightSearch: function showRightSearch() {
      // 要显示的标签页
      var show = [2, 3];
      return show.indexOf(this.pageRadio) !== -1;
    }
  }),
  created: function created() {
    // 先获权限
    this.getAuthority();
  },
  methods: {
    getAuthority: function getAuthority() {
      var _this = this;
      adminParameter().then(function (res) {
        _this.setting = res.data;
        _this.getPageData();
      }).catch(function (_) {
        _this.$message.error('页面配置获取失败，请刷新页面重试');
      });
    },
    /**
     * 获取页面数据
     */
    getPageData: function getPageData() {
      var _this2 = this;
      if (this.per(['admin', 'prints/index'])) {
        prints().then(function (res) {
          var data = res.data.data;
          _this2.handleData('help_type_list', data);
          _this2.handleData('ide_type_list', data);
          _this2.handleData('order_type_list', data);
          _this2.handleData('build_list', data);
          _this2.handleData('room_tag_list', data);
          _this2.handleData('room_cate_list', data);
          _this2.handleData('type_list', data);
          _this2.ide_word_type = data.word_rule.ide_word_type;
          _this2.help_word_type = data.word_rule.help_word_type;
          _this2.order_word_type = data.word_rule.order_word_type;
          Object.assign(_this2, data);
        });
      }
      if (this.per(['admin', 'prints/getidevar'])) {
        getIdeVar().then(function (resultIdv) {
          _this2.yz = resultIdv.data.data.list;
          _this2.z = resultIdv.data.data.list;
        });
      }

      // 有管理员权限或套打模板权限
      if (this.per(['admin', 'prints/index'])) this.pageRadio = 1;
      // 有认筹套打变量权限
      else if (this.per(['prints/getidevar'])) this.pageRadio = 3;
      // 只有选房套打变量权限
      else if (this.per(['prints/getordervar'])) this.pageRadio = 2;
      // 什么权限都没有 不去显示页面上的东西
      else this.pageNoPermission = false;
    },
    // 处理数据
    handleData: function handleData(key, data) {
      // 没有数据 给个空数组
      if (!data[key]) data[key] = [];

      // 处理一下数据结构 避免使用时报错
      for (var i = 0; i < data[key].length; i++) {
        var _c$word;
        var c = data[key][i];
        if (!(c !== null && c !== void 0 && (_c$word = c.word) !== null && _c$word !== void 0 && _c$word.word_name)) c.word = {
          word_name: "",
          word_url: ""
        };
      }
    },
    changePeage: function changePeage(e) {
      if (e !== 1) this.inputSearchKey(this.searchKey);
    },
    // 输入搜索关键词
    inputSearchKey: function inputSearchKey(e) {
      var searchList = [];
      if (this.pageRadio === 2) searchList = this.yx;else if (this.pageRadio === 3) searchList = this.yz;
      var search = [];
      searchList.forEach(function (item) {
        var _item$list;
        var list = (_item$list = item.list) === null || _item$list === void 0 ? void 0 : _item$list.filter(function (i) {
          var _i$name, _i$meaming;
          if ((_i$name = i.name) !== null && _i$name !== void 0 && _i$name.includes(e)) return i;else if ((_i$meaming = i.meaming) !== null && _i$meaming !== void 0 && _i$meaming.includes(e)) return i;
        });
        if (list.length) {
          search.push(_objectSpread(_objectSpread({}, item), {}, {
            list: list
          }));
        }
      });
      if (this.pageRadio === 2) this.x = search;else if (this.pageRadio === 3) this.z = search;
    },
    // 模板切换
    changeIsCate: function changeIsCate(e) {
      if (!e) this.lock = [this.lock[0]];
      if (!e) this.ide_lock = [this.ide_lock[0]];
    },
    /**
     * 多模板切换
     * @param {string} type
     * @param {Nunber} value
    */
    changeTemplate: function changeTemplate(type, value) {
      var _this3 = this;
      var obj = {
        ide_word_type: {
          list: 'ide_type_list',
          one: 'ide_word'
        },
        help_word_type: {
          list: 'help_type_list',
          one: 'help_word',
          keys: 'help_word_status'
        },
        order_word_type: {
          list: 'order_type_list',
          one: 'order_word',
          keys: 'order_word_status'
        }
      };
      // 要处理的数据
      var new_obj = obj[type];
      // 单双模板
      var haveWord = false;
      if (value) {
        haveWord = !!this[new_obj.list].find(function (item) {
          return item.word.word_url;
        });
      } else {
        haveWord = !!this[new_obj.one].word_url;
      }
      // 模板中有数据进行提示
      if (haveWord) {
        var hint = value ? '切换到单模板会删除多模板文件' : '切换到多模板会删除单模板文件';
        this.$alert(hint, '确认', {
          confirmButtonText: '确定',
          showCancelButton: true,
          cancelButtonText: '取消',
          callback: function callback(action) {
            if (action === 'confirm') {
              var key = value ? new_obj.list : new_obj.one;
              if (value) {
                _this3[key] = [];
                _this3.saveData(key);
              } else {
                _this3[key] = {};
                _this3.saveData(key);
              }
            } else if (action === 'cancel') {
              _this3[type] = _this3[type] ? 0 : 1;
            }
          }
        });
      } else {
        var key = value ? new_obj.list : new_obj.one;
        this.saveData(key);
      }
    },
    // 手动请求
    httpRequest: function httpRequest(params) {
      var _this4 = this;
      var l = this.$loading({
        lock: true,
        text: '上传图片中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var formdata = new FormData();
      formdata.append('file', params.file);
      sealImg(formdata).then(function (resultData) {
        var fileParam = {
          name: 'file',
          url: _this4.imgUrl + resultData.data
        };
        _this4.company.seal_url = resultData.data;
        _this4.fileList = [fileParam];
      }).finally(function () {
        l.close();
      });
    },
    // 手动上传word
    httpWordRequest: function httpWordRequest(params, type, index) {
      var _this5 = this;
      var l = this.$loading({
        lock: true,
        text: '上传文件中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var formdata = new FormData();
      formdata.append('file', params.file);
      signWord(formdata).then(function (resultData) {
        var _params$file, _params$file2;
        _this5[type][index].word_url = resultData.data;
        _this5[type][index].word_name = (_params$file = params.file) === null || _params$file === void 0 ? void 0 : _params$file.name;
        _this5[type][index].add_time = Math.ceil(((_params$file2 = params.file) === null || _params$file2 === void 0 ? void 0 : _params$file2.lastModified) / 1000);
      }).finally(function () {
        l.close();
      });
    },
    // 移除
    handleRemove: function handleRemove() {
      this.company.seal_url = '';
    },
    // 预览
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 设置签约配置
    setSignConfig: function setSignConfig() {
      var _this6 = this;
      var params = {
        company: this.company,
        lock: this.lock,
        audit_lock: this.ide_lock
      };
      preserve(params).then(function (resultConfig) {
        _this6.$message.success('保存成功');
      }).catch(function () {
        _this6.$message.error('保存失败');
      });
    },
    /**
    * 处理数据结构
    * @param {string} type 可选值 lock | ide_lock
    * @param {Array} data 数组
    * @param {string} operation 可选值 add | del
    * @param {string | number} index 下标
    */
    processingData: function processingData(type, data, operation, index) {
      // 没有数据先给个初始值
      if (!operation) {
        if (!data || !data.length) {
          var item = this.defaultData(type);
          data = [item];
        }
      }
      if (operation === 'add') this.add(this[type], index);
      if (operation === 'del') this.del(this[type], index);
      this.curentTypeName(data);
      return data;
    },
    /** 数字转换汉字 */toChineseBig: function toChineseBig(num) {
      // 将接收到的num转换为字符串
      var strNum = String(num);
      // 定义单位
      var unit = ['十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千'];
      // 结果中放一个符号，用来解决最后的零去不掉的问题
      var result = ['@'];
      // 单位下标
      var unitNo = 0;
      // 从后往前遍历接收到的数据，省略结束条件
      for (var i = strNum.length - 1;; i--) {
        // 调用转大写函数，将每一个数字转换成中文大写，一次放入一个到结果数组中
        result.unshift(numToChinese(strNum[i]));
        // 如果不大于0
        // 结束循环
        if (i <= 0) break;

        // 放入一个数字，放入一个单位
        result.unshift(unit[unitNo]);
        // 单位下标加1
        unitNo++;
      }
      // 将结果数组转换成字符串，并使用正则替换一些关键位置，让结果符合语法
      return result.join('').replace(/(零[千百十]){1,3}/g, '零').replace(/零{2,}/g, '零').replace(/零([万亿])/g, '$1').replace(/亿万/g, '亿').replace(/零*@/g, '');
      function numToChinese(n) {
        // var chineseBigNum = '零壹贰叁肆伍陆柒捌玖'
        var chineseBigNum = '零一二三四五六七八九';
        return chineseBigNum[n];
      }
    },
    /**
    * 处理当前业务下标名称
    * @description
    * @param {Array} 对象数组
    * @return {Array}
    */
    curentTypeName: function curentTypeName(array) {
      for (var i = 0; i < array.length; i++) {
        array[i].curentTypeName = this.toChineseBig(i + 1);
      }
      return array;
    },
    defaultData: function defaultData(type) {
      var callback = '';
      var defaultData = {
        "CreatedAt": 0,
        "UpdatedAt": 0,
        "DeletedAt": null,
        "id": 0,
        //id
        "project_id": 0,
        //项目id
        "cate": "1",
        //云销控变量分类
        // "company_required": 1, //是否自动公司签章1是自动签章 0是手动签章
        "keyword_company": "",
        //公司签章关键字
        "keyword_personal": "",
        //个人签署关键字组
        "is_single_shot": 1,
        "is_before_sign": 0,
        "is_single": 0,
        "is_formal_value": 0,
        "text_value": "测试活动，签署无效！",
        "gift_num": 0,
        callback: callback,
        //回调地址 写死默认值
        word_name: '',
        // 文档名称
        "word_url": "" //在线签约文档
      };
      return defaultData;
    },
    // 添加数据
    add: function add() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var newData = this.defaultData();
      data.push(newData);
      this.curentTypeName(data);
      return data;
    },
    // 删除数据
    del: function del() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      if (data.length == 1) {
        this.$message.error('不可以全部删除');
        return data;
      }
      data.splice(index, 1);
      return data;
    },
    per: function per(al) {
      var result = false;
      for (var i = 0; i < al.length; i++) {
        var ai = al[i];
        if (this.roles.includes(ai)) {
          result = true;
          break;
        }
      }
      return result;
    },
    /**
     * 文件改变
     * @param {object} resultChange 组件返回值
     * @param {string} key 值
     * @param {number} index 索引
     */
    handleLimit: function handleLimit(resultChange, key, index) {
      this.loading = this.$loading({
        lock: true,
        text: '努力上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      if (!index && index !== 0) {
        var o = {
          ide_word: 'ide_add_time',
          help_word: 'help_add_time',
          order_word: 'order_add_time',
          price_word: 'price_add_time'
        };
        this[o[key]] = Math.ceil(resultChange.raw.lastModified / 1000);
      }
      // 多模板
      else {
        this[key][index].word.add_time = Math.ceil(resultChange.raw.lastModified / 1000);
      }
      if (!index && index !== 0) this[key].word_name = resultChange.name;else this[key][index].word.word_name = resultChange.name;
    },
    /**
     * 文件上传成功
     * @param {object} resultSuc 成功返回链接
     * @param {string} key 值
     * @param {number} index 索引
     */
    handleAvatarSuccess: function handleAvatarSuccess(resultSuc, key, index) {
      if (!index && index !== 0) this[key].word_url = resultSuc.Data.url;else this[key][index].word.word_url = resultSuc.Data.url;
      this.saveData(key);
      this.$message.success(resultSuc.msg);
    },
    /**
     * 下载文件
     * @param {object} params 参数
     */
    downLoadFile: function downLoadFile(params) {
      var _this7 = this;
      var urls = params.word_url.split(",");
      var names = params.word_name.split(",");
      var _loop = function _loop() {
        var url = urls[i];
        // url 拼接成完整地址
        url = _this7.reqUrl + '/' + params.word_url;
        // #bug 后端返回 有链接没名字 为了避免下载 index.html 重命名
        var name = names[i] || "\u672A\u77E5\u540D\u79F0-".concat(i, ".docx");
        _this7.getBlob(url).then(function (blob) {
          _this7.saveAs(blob, name);
        });
      };
      for (var i = 0; i < urls.length; i++) {
        _loop();
      }
    },
    /**
     * 下载word文件
    */
    downloadWord: function downloadWord(file) {
      var _this8 = this;
      var url = this.reqUrl + '/' + file.word_url;
      this.getBlob(url).then(function (blob) {
        _this8.saveAs(blob, file.word_name || '未知名称.docx');
      });
    },
    // 获取文件流
    getBlob: function getBlob(url) {
      return new Promise(function (resolve) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
          if (xhr.status === 200) resolve(xhr.response);
        };
        xhr.send();
      });
    },
    // 保存文件
    saveAs: function saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement("a");
        var body = document.querySelector("body");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.style.display = "none";
        body.appendChild(link);
        link.click();
        body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }
    },
    /**
     * 切换模板数据
    */
    changeTemplateData: function changeTemplateData(key, keys) {
      var _this9 = this;
      // 校验模板里 是否有数据
      var haveWord = !!this[key].find(function (item) {
        return item.word.word_url;
      });
      var value = this.word_rule[keys];
      var type = {
        0: 'type_list',
        1: 'build_list',
        2: 'room_cate_list',
        3: 'room_tag_list'
      }[value];
      if (haveWord) {
        var _value;
        // 有数据
        var arr = ['按业态', '按楼栋', '户型', '分类'];
        var hint = (_value = ['切换到按业态，会删除$r的模板文件', '切换到按楼栋，会删除$r的模板文件', '切换到户型，会删除$r的模板文件', '切换到分类，会删除$r的模板文件'][value]) === null || _value === void 0 ? void 0 : _value.replace('$r', arr[this.old]);
        this.$alert(hint, '确认', {
          confirmButtonText: '确定',
          showCancelButton: true,
          cancelButtonText: '取消',
          callback: function callback(action) {
            if (action === 'confirm') {
              // 根据分类修改 数据
              _this9[key] = JSON.parse(JSON.stringify(_this9[type] || []));
              _this9.saveData(key);
            } else if (action === 'cancel') {
              _this9.word_rule[keys] = _this9.old;
            }
          }
        });
      } else {
        // 根据分类修改 数据
        this[key] = JSON.parse(JSON.stringify(this[type] || []));
        this.saveData(key);
      }
    },
    /**
     * 保存上传的地址
     * @param {String} key 数据key值
     */
    saveData: function saveData(key) {
      var _this10 = this;
      if (['help_type_list', 'help_word'].includes(key)) {
        // 保存流转单
        var params = {
          types: this.help_word_type,
          help_words: [],
          help_add_time: this.help_add_time,
          status: this.word_rule.help_word_status
        };
        if (params.types) {
          params.help_word = this.help_word;
        } else {
          for (var i = 0; i < this.help_type_list.length; i++) {
            params.help_words.push(_objectSpread(_objectSpread({}, this.help_type_list[i].word), {}, {
              id: this.help_type_list[i].id
            }));
          }
        }
        setHelpWord(params).finally(function () {
          _this10.closeLoad();
        });
      } else if (['ide_type_list', 'ide_word'].includes(key)) {
        // 保存认筹单
        var _params = {
          types: this.ide_word_type,
          ide_words: [],
          ide_add_time: this.ide_add_time
        };
        if (_params.types) {
          _params.ide_word = this.ide_word;
        } else {
          for (var _i = 0; _i < this.ide_type_list.length; _i++) {
            _params.ide_words.push(_objectSpread(_objectSpread({}, this.ide_type_list[_i].word), {}, {
              id: this.ide_type_list[_i].id
            }));
          }
        }
        setIdeWord(_params).finally(function () {
          _this10.closeLoad();
        });
      } else if (['order_type_list', 'order_word'].includes(key)) {
        // 保存认购单
        var _params2 = {
          types: this.order_word_type,
          order_words: [],
          status: this.word_rule.order_word_status,
          order_add_time: this.order_add_time
        };
        if (_params2.types) {
          _params2.order_word = this.order_word;
        } else {
          for (var _i2 = 0; _i2 < this.order_type_list.length; _i2++) {
            _params2.order_words.push(_objectSpread(_objectSpread({}, this.order_type_list[_i2].word), {}, {
              id: this.order_type_list[_i2].id
            }));
          }
        }
        setOrderWord(_params2).finally(function () {
          _this10.closeLoad();
        });
      } else if (['price_word'].includes(key)) {
        var _params3 = {
          price_word: this.price_word,
          price_add_time: this.price_add_time
        };
        setPriceWord(_params3).finally(function () {
          _this10.closeLoad();
        });
      }
    },
    closeLoad: function closeLoad() {
      var _this$loading;
      (_this$loading = this.loading) === null || _this$loading === void 0 || _this$loading.close();
      // 重新获取数据
      this.getPageData();
    },
    // 摇号记录打印模板预览
    onPrintUpPreview: function onPrintUpPreview(template_ID) {
      var path = this.$router.resolve({
        path: '/printUp',
        query: {
          template_ID: template_ID
        }
      });
      window.open("".concat(path.href), '_blank');
    },
    // 保存摇号记录打印模板
    onSetRandPrint: function onSetRandPrint() {
      var _this11 = this;
      this.loading = this.$loading({
        lock: true,
        text: '保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setRandPrint({
        rand_print_template_id: this.word_rule.rand_print_template_id
      }).then(function (res) {
        _this11.$message.success(res.data.msg);
        _this11.closeLoad();
      }).catch(function (res) {
        _this11.closeLoad();
      });
    }
  }
};