import _typeof from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/typeof.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getImageInfo } from "@/utils/else.js";
/**
 * @param ImageInfo 图片信息
 * @param conduct   节流
 * @param list      列表
 * @param move      移动信息
 * **/
export default {
  data: function data() {
    return {
      ImageInfo: {},
      conduct: false,
      list: [],
      move: {},
      oldVal: {},
      imgErrorInfo: {}
    };
  },
  props: {
    imgSrc: {
      type: String,
      default: ""
    },
    moveList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  mounted: function mounted() {
    this.getImgInfo();
  },
  methods: {
    // 原生内部的鼠标移动事件
    MonitorMouseMovement: function MonitorMouseMovement(e) {
      if (!this.conduct) return false; // 如果为false则终止函数的执行并返回函数的值
      var twox = e.clientX;
      var twoy = e.clientY;
      // 用获取到的鼠标指针的坐标 - (鼠标指针的坐标 - 偏移量) = 实际鼠标拖动的位置
      // 后面一定要加上px单位，因为本身获取是没有单位的
      var _this$move = this.move,
        x = _this$move.x,
        l = _this$move.l,
        y = _this$move.y,
        t = _this$move.t,
        target = _this$move.target;
      var twol = twox - (x - l);
      var twot = twoy - (y - t);
      target.style.left = twol + "px";
      target.style.top = twot + "px";
    },
    // 内部方块的移动事件
    onmousedown: function onmousedown(e, item, index) {
      this.conduct = true;
      e.target.style.cursor = "all-scroll"; // 设置鼠标样式为移动箭头
      // e.target.offsetLeft : 获取左和上的偏移量 这个属性是只读的，不能够赋值。
      // 返回当前元素距离父元素(body)左边的距离
      this.move = {
        x: e.clientX,
        y: e.clientY,
        l: e.target.offsetLeft,
        t: e.target.offsetTop,
        target: e.target,
        item: item,
        index: index
      };
      this.$emit("select", this.move);
    },
    // 鼠标抬起
    onmouseup: function onmouseup(e) {
      e.target.style.cursor = "pointer"; // 鼠标恢复默认样式
      this.conduct = false; // 松开键盘时停止鼠标移动事件
      this.$emit("stopMoveBlock", this.move);
    },
    // 获取图片信息
    getImgInfo: function getImgInfo() {
      var _this = this;
      getImageInfo(this.$refs.imgRef).then(function (info) {
        _this.ImageInfo = info;
        if (_typeof(info) === "object") {
          _this.$emit("imgSuc");
        }
      });
    },
    // 还原
    restore: function restore() {
      this.move.item = this.oldVal[this.move.index];
      this.$emit("stopMoveBlock", this.move);
    },
    imgError: function imgError(err) {
      this.imgErrorInfo = err;
      this.$emit("imgError", err);
    }
  },
  watch: {
    imgSrc: function imgSrc() {
      this.getImgInfo();
    },
    moveList: function moveList(newVal, oldVal) {
      console.log(oldVal);
      this.list = newVal;
      this.oldVal = oldVal;
    },
    immediate: true,
    deep: true
  }
};