/*******************************************************************************
* KindEditor - WYSIWYG HTML Editor for Internet
* Copyright (C) 2006-2011 kindsoft.net
*
* @author Roddy <luolonghao@gmail.com>
* @site http://www.kindsoft.net/
* @licence http://www.kindsoft.net/license.php
*******************************************************************************/

KindEditor.lang({
  source: 'HTML代码',
  preview: '预览',
  undo: '后退(Ctrl+Z)',
  redo: '前进(Ctrl+Y)',
  cut: '剪切(Ctrl+X)',
  copy: '复制(Ctrl+C)',
  paste: '粘贴(Ctrl+V)',
  plainpaste: '粘贴为无格式文本',
  wordpaste: '从Word粘贴',
  selectall: '全选(Ctrl+A)',
  justifyleft: '左对齐',
  justifycenter: '居中',
  justifyright: '右对齐',
  justifyfull: '两端对齐',
  insertorderedlist: '编号',
  insertunorderedlist: '项目符号',
  indent: '增加缩进',
  outdent: '减少缩进',
  subscript: '下标',
  superscript: '上标',
  formatblock: '段落',
  fontname: '字体',
  fontsize: '文字大小',
  forecolor: '文字颜色',
  hilitecolor: '文字背景',
  bold: '粗体(Ctrl+B)',
  italic: '斜体(Ctrl+I)',
  underline: '下划线(Ctrl+U)',
  strikethrough: '删除线',
  removeformat: '删除格式',
  image: '图片',
  multiimage: '批量图片上传',
  flash: 'Flash',
  media: '视音频',
  table: '表格',
  tablecell: '单元格',
  hr: '插入横线',
  emoticons: '插入表情',
  link: '超级链接',
  unlink: '取消超级链接',
  fullscreen: '全屏显示',
  about: '关于',
  print: '打印(Ctrl+P)',
  filemanager: '文件空间',
  code: '插入程序代码',
  map: 'Google地图',
  baidumap: '百度地图',
  lineheight: '行距',
  clearhtml: '清理HTML代码',
  pagebreak: '插入分页符',
  quickformat: '一键排版',
  insertfile: '插入文件',
  template: '插入模板',
  anchor: '锚点',
  yes: '确定',
  no: '取消',
  close: '关闭',
  editImage: '图片属性',
  deleteImage: '删除图片',
  editFlash: 'Flash属性',
  deleteFlash: '删除Flash',
  editMedia: '视音频属性',
  deleteMedia: '删除视音频',
  editLink: '超级链接属性',
  deleteLink: '取消超级链接',
  editAnchor: '锚点属性',
  deleteAnchor: '删除锚点',
  tableprop: '表格属性',
  tablecellprop: '单元格属性',
  tableinsert: '插入表格',
  tabledelete: '删除表格',
  tablecolinsertleft: '左侧插入列',
  tablecolinsertright: '右侧插入列',
  tablerowinsertabove: '上方插入行',
  tablerowinsertbelow: '下方插入行',
  tablerowmerge: '向下合并单元格',
  tablecolmerge: '向右合并单元格',
  tablerowsplit: '拆分行',
  tablecolsplit: '拆分列',
  tablecoldelete: '删除列',
  tablerowdelete: '删除行',
  noColor: '无颜色',
  pleaseSelectFile: '请选择文件。',
  invalidImg: "请输入有效的URL地址。\n只允许jpg,gif,bmp,png格式。",
  invalidMedia: "请输入有效的URL地址。\n只允许swf,flv,mp3,wav,wma,wmv,mid,avi,mpg,asf,rm,rmvb格式。",
  invalidWidth: "宽度必须为数字。",
  invalidHeight: "高度必须为数字。",
  invalidBorder: "边框必须为数字。",
  invalidUrl: "请输入有效的URL地址。",
  invalidRows: '行数为必选项，只允许输入大于0的数字。',
  invalidCols: '列数为必选项，只允许输入大于0的数字。',
  invalidPadding: '边距必须为数字。',
  invalidSpacing: '间距必须为数字。',
  invalidJson: '服务器发生故障。',
  uploadSuccess: '上传成功。',
  cutError: '您的浏览器安全设置不允许使用剪切操作，请使用快捷键(Ctrl+X)来完成。',
  copyError: '您的浏览器安全设置不允许使用复制操作，请使用快捷键(Ctrl+C)来完成。',
  pasteError: '您的浏览器安全设置不允许使用粘贴操作，请使用快捷键(Ctrl+V)来完成。',
  ajaxLoading: '加载中，请稍候 ...',
  uploadLoading: '上传中，请稍候 ...',
  uploadError: '上传错误',
  'plainpaste.comment': '请使用快捷键(Ctrl+V)把内容粘贴到下面的方框里。',
  'wordpaste.comment': '请使用快捷键(Ctrl+V)把内容粘贴到下面的方框里。',
  'code.pleaseInput': '请输入程序代码。',
  'link.url': 'URL',
  'link.linkType': '打开类型',
  'link.newWindow': '新窗口',
  'link.selfWindow': '当前窗口',
  'flash.url': 'URL',
  'flash.width': '宽度',
  'flash.height': '高度',
  'flash.upload': '上传',
  'flash.viewServer': '文件空间',
  'media.url': 'URL',
  'media.width': '宽度',
  'media.height': '高度',
  'media.autostart': '自动播放',
  'media.upload': '上传',
  'media.viewServer': '文件空间',
  'image.remoteImage': '网络图片',
  'image.localImage': '本地上传',
  'image.remoteUrl': '图片地址',
  'image.localUrl': '上传文件',
  'image.size': '图片大小',
  'image.width': '宽',
  'image.height': '高',
  'image.resetSize': '重置大小',
  'image.align': '对齐方式',
  'image.defaultAlign': '默认方式',
  'image.leftAlign': '左对齐',
  'image.rightAlign': '右对齐',
  'image.imgTitle': '图片说明',
  'image.upload': '浏览...',
  'image.viewServer': '图片空间',
  'multiimage.uploadDesc': '允许用户同时上传<%=uploadLimit%>张图片，单张图片容量不超过<%=sizeLimit%>',
  'multiimage.startUpload': '开始上传',
  'multiimage.clearAll': '全部清空',
  'multiimage.insertAll': '全部插入',
  'multiimage.queueLimitExceeded': '文件数量超过限制。',
  'multiimage.fileExceedsSizeLimit': '文件大小超过限制。',
  'multiimage.zeroByteFile': '无法上传空文件。',
  'multiimage.invalidFiletype': '文件类型不正确。',
  'multiimage.unknownError': '发生异常，无法上传。',
  'multiimage.pending': '等待上传',
  'multiimage.uploadError': '上传失败',
  'filemanager.emptyFolder': '空文件夹',
  'filemanager.moveup': '移到上一级文件夹',
  'filemanager.viewType': '显示方式：',
  'filemanager.viewImage': '缩略图',
  'filemanager.listImage': '详细信息',
  'filemanager.orderType': '排序方式：',
  'filemanager.fileName': '名称',
  'filemanager.fileSize': '大小',
  'filemanager.fileType': '类型',
  'insertfile.url': 'URL',
  'insertfile.title': '文件说明',
  'insertfile.upload': '上传',
  'insertfile.viewServer': '文件空间',
  'table.cells': '单元格数',
  'table.rows': '行数',
  'table.cols': '列数',
  'table.size': '大小',
  'table.width': '宽度',
  'table.height': '高度',
  'table.percent': '%',
  'table.px': 'px',
  'table.space': '边距间距',
  'table.padding': '边距',
  'table.spacing': '间距',
  'table.align': '对齐方式',
  'table.textAlign': '水平对齐',
  'table.verticalAlign': '垂直对齐',
  'table.alignDefault': '默认',
  'table.alignLeft': '左对齐',
  'table.alignCenter': '居中',
  'table.alignRight': '右对齐',
  'table.alignTop': '顶部',
  'table.alignMiddle': '中部',
  'table.alignBottom': '底部',
  'table.alignBaseline': '基线',
  'table.border': '边框',
  'table.borderWidth': '边框',
  'table.borderColor': '颜色',
  'table.backgroundColor': '背景颜色',
  'map.address': '地址: ',
  'map.search': '搜索',
  'baidumap.address': '地址: ',
  'baidumap.search': '搜索',
  'baidumap.insertDynamicMap': '插入动态地图',
  'anchor.name': '锚点名称',
  'formatblock.formatBlock': {
    h1: '标题 1',
    h2: '标题 2',
    h3: '标题 3',
    h4: '标题 4',
    p: '正 文'
  },
  'fontname.fontName': {
    'SimSun': '宋体',
    'NSimSun': '新宋体',
    'FangSong_GB2312': '仿宋_GB2312',
    'KaiTi_GB2312': '楷体_GB2312',
    'SimHei': '黑体',
    'Microsoft YaHei': '微软雅黑',
    'Arial': 'Arial',
    'Arial Black': 'Arial Black',
    'Times New Roman': 'Times New Roman',
    'Courier New': 'Courier New',
    'Tahoma': 'Tahoma',
    'Verdana': 'Verdana'
  },
  'lineheight.lineHeight': [{
    '1': '单倍行距'
  }, {
    '1.5': '1.5倍行距'
  }, {
    '2': '2倍行距'
  }, {
    '2.5': '2.5倍行距'
  }, {
    '3': '3倍行距'
  }],
  'template.selectTemplate': '可选模板',
  'template.replaceContent': '替换当前内容',
  'template.fileList': {
    '1.html': '图片和文字',
    '2.html': '表格',
    '3.html': '项目编号'
  }
}, 'zh-CN');
KindEditor.options.langType = 'zh-CN';