import _objectSpread from "/www/wwwroot/bs/repository/project_32/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { getProject, copyForm } from '@/api/sincerityRegister';
export default {
  model: {
    prop: 'value',
    event: 'changeShow'
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    rule: {
      type: Object
    },
    audit: {
      type: Object
    }
  },
  computed: _objectSpread({}, mapState('config', ['list'])),
  data: function data() {
    return {
      form: {
        types: 1,
        reg_copy: 0,
        ide_copy: 0,
        rai_copy: 0
      },
      rules: {
        old_id: [{
          required: true,
          message: '请选择项目',
          trigger: 'change'
        }],
        types: [{
          required: true,
          message: '请选择类型',
          trigger: 'change'
        }]
      },
      ppp: {
        list: [],
        old: {}
      }
    };
  },
  watch: {
    value: function value(e) {
      if (e) this.getProjectList();
    }
  },
  methods: {
    // 关闭显示
    closeShow: function closeShow() {
      this.$emit('changeShow', false);
    },
    //
    getProjectList: function getProjectList() {
      var _this = this;
      getProject().then(function (res) {
        _this.ppp = res.data.list;
      });
    },
    submitCopy: function submitCopy() {
      var _this2 = this;
      if (!this.form.reg_copy && !this.form.ide_copy && !this.form.rai_copy) {
        this.form.types = '';
      } else {
        this.form.types = 1;
      }
      this.$refs.ruleForm.validate(function (e) {
        if (e) {
          _this2.$emit('changeShow', false);
          var params = _objectSpread({}, _this2.form);
          copyForm(params).then(function (res) {
            _this2.$message.success(res.data.msg || '复制成功');
            _this2.$emit('copySuccess', res.data);
          }).catch(function (err) {
            _this2.$emit('copyFail', err);
          });
        }
      });
    }
  }
};