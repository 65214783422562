//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseTable',
  /**
   * props data
   * @param {object} data
   */
  props: {
    data: {
      type: Array,
      default: function _default() {}
    }
  },
  methods: {
    /**
     * copyText 复制文字
     * @param {string}  text
    */
    copyText: function copyText(text) {
      if (!text) {
        this.$message.error('复制失败');
        return;
      }
      // text是复制文本
      // 创建input元素
      var el = document.createElement('input');
      // 给input元素赋值需要复制的文本
      el.setAttribute('value', text);
      // 将input元素插入页面
      document.body.appendChild(el);
      // 选中input元素的文本
      el.select();
      // 复制内容到剪贴板
      document.execCommand('copy');
      // 删除input元素
      document.body.removeChild(el);
      this.$message.success('复制成功');
    }
  }
};