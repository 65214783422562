/**
 * @author: Rrq,
 * @mailbox: 15280973293@163.com,
 * @date: 2021-04-19 14:54,
 */

import request from '@/utils/request';
import qs from 'qs';

// 摇号列表接口
export function eventRandList(params) {
  return request({
    url: 'EventRand/index',
    method: 'get',
    params: params
  });
}

// 获取摇号记录页面
export function eventRandrandList(params) {
  return request({
    url: 'EventRand/randList',
    method: 'get',
    params: params
  });
}

// 获取分组
export function eventRandSelGroup(params) {
  return request({
    url: 'EventRand/selGroup',
    method: 'get',
    params: params
  });
}

// 结束本组摇号
export function eventRandClose(params) {
  return request({
    url: 'EventRand/close',
    method: 'get',
    params: params
  });
}

// 重置
export function eventRandReset(params) {
  return request({
    url: 'EventRand/reset',
    method: 'get',
    params: params
  });
}

// 获取打印轮次列表
export function PrintRoundList(data) {
  return request({
    url: 'EventRand/PrintRoundList',
    method: 'post',
    data: data
  });
}

// 开始打印
export function Print(data) {
  return request({
    url: 'EventRand/Print',
    method: 'post',
    data: data
  });
}

// 摇号预设列表
export function lotteryPresetList(params) {
  return request({
    url: 'EventRandUser/index',
    method: 'get',
    params: params
  });
}
export function isTestRandUser(data) {
  return request({
    url: 'EventRandUser/isTestRandUser',
    method: 'post',
    data: data
  });
}

// 根据登录手机号查询用户
export function lotteryTelUser(params) {
  return request({
    url: 'EventRandUser/telUser',
    method: 'get',
    params: params
  });
}

// 新增预设
export function eventRandAdd(data) {
  return request({
    url: 'EventRandUser/add',
    method: 'post',
    data: qs.stringify(data)
  });
}

// 修改预设
export function eventRandEdit(data) {
  return request({
    url: 'EventRandUser/edit',
    method: 'post',
    data: qs.stringify(data)
  });
}

// 删除客户预设
export function eventRandDel(params) {
  return request({
    url: 'EventRandUser/delUser',
    method: 'get',
    params: params
  });
}

// 清楚预设
export function lotteryPresetDel(params) {
  return request({
    url: 'EventRandUser/del',
    method: 'get',
    params: params
  });
}

// 根据登录手机号查询用户
export function userTelUser(params) {
  return request({
    url: 'EventRandUser/telUser',
    method: 'get',
    params: params
  });
}

// 预设名单
export function lotteryPresetRoster(params) {
  return request({
    url: 'EventRandUser/roster',
    method: 'get',
    params: params
  });
}

// 编辑摇号规则
export function eventRandSelRule(params) {
  return request({
    url: 'EventRand/selRule',
    method: 'get',
    params: params
  });
}

// 保存摇号规则
export function eventRandSetRule(data) {
  return request({
    url: 'EventRand/setRule',
    method: 'post',
    data: data
  });
}

// 摇号主页
export function eventRandIndex(params) {
  return request({
    url: 'EventRand/randIndex',
    method: 'get',
    params: params
  });
}

// --- 分组摇号 ---
// 查询轮次
export function queryTurn(params) {
  return request({
    url: 'EventRand/selLevelRand',
    method: 'get',
    params: params
  });
}

// 停止摇号
export function stopLottery(params) {
  return request({
    url: 'EventRand/stopLevelRand',
    method: 'get',
    params: params
  });
}

// 开始摇号
export function startLottery(params) {
  return request({
    url: 'EventRand/startLevelRand',
    method: 'get',
    params: params
  });
}

// --- 不分组 ---
// 查询轮次
export function noQueryTurn(params) {
  return request({
    url: 'EventRand/selRand',
    method: 'get',
    params: params
  });
}

// 停止摇号
export function noStopLottery(params) {
  return request({
    url: 'EventRand/stopRand',
    method: 'get',
    params: params
  });
}

// 开始摇号
export function noStartLottery(params) {
  return request({
    url: 'EventRand/startRand',
    method: 'get',
    params: params
  });
}

// --- 顺序 摇号 ---

// 开始摇号
export function orderStartLottery(params) {
  return request({
    url: 'EventRand/orderRand',
    method: 'get',
    params: params
  });
}
// 摇号轮播数据
export function orderLevelStartRand(params) {
  return request({
    url: 'EventRand/orderLevelStartRand',
    method: 'GET',
    params: params
  });
}
// 摇号轮播数据
export function orderStartRand(params) {
  return request({
    url: 'EventRand/orderStartRand',
    method: 'GET',
    params: params
  });
}

// 查询轮次
export function orderQueryTurn(params) {
  return request({
    url: 'EventRand/selOrderRand',
    method: 'get',
    params: params
  });
}

// 查询轮次
export function setPlateType(data) {
  return request({
    url: 'EventRand/setPlateType',
    method: 'post',
    data: data
  });
}

// 批量设置摇号规则默认值
export function selAllRule() {
  return request({
    url: 'EventRand/selAllRule',
    method: 'get'
  });
}

// 保存批量设置摇号规则默认值
export function setAllRule(data) {
  return request({
    url: 'EventRand/setAllRule',
    method: 'post',
    data: data
  });
}

// 导出摇号预设
export function orderQueryExport(params) {
  return request({
    url: 'EventRandUser/export',
    method: 'get',
    params: params
  });
}

// 导出摇号记录
export function EventRandExport(params) {
  return request({
    url: 'EventRand/export',
    method: 'get',
    params: params
  });
}

// 分组排号 开始排号
export function orderLevelRand(params) {
  return request({
    url: 'EventRand/orderLevelRand',
    method: 'get',
    params: params
  });
}

// 分组排号 查询排号
export function selLevelOrderRand(params) {
  return request({
    url: 'EventRand/selLevelOrderRand',
    method: 'get',
    params: params
  });
}

// 获取预设错误人数
export function getErrCount(params) {
  return request({
    url: 'EventRandUser/getErrCount',
    method: 'get',
    params: params
  });
}

//  点击开始摇号
export function getStartYao(params) {
  return request({
    url: 'EventRand/start',
    method: 'get',
    params: params
  });
}

// 作废摇号记录
export function ResetLog(data) {
  return request({
    url: 'EventRand/ResetLog',
    method: 'POST',
    data: data
  });
}

// 选房抽号列表
export function randRoomList(params) {
  return request({
    url: 'randRoom/list',
    method: 'get',
    params: params
  });
}

// 选房抽号搜索用户
export function randRoomUser(params) {
  return request({
    url: 'randRoom/user',
    method: 'get',
    params: params
  });
}

// 选房抽号开始
export function randRoomdStart(params) {
  return request({
    url: 'randRoom/randStart',
    method: 'get',
    params: params
  });
}

// 选房抽号结束
export function randRoomdStop(params) {
  return request({
    url: 'randRoom/randStop',
    method: 'get',
    params: params
  });
}
// 录入抽号主界面
export function entryIndex(data) {
  return request({
    url: 'EventRand/entryIndex',
    method: 'post',
    data: data
  });
}
// 录入抽号
export function entry(data) {
  return request({
    url: 'EventRand/entry',
    method: 'post',
    data: data
  });
}
// 获取录入分组客户
export function entryUser(data) {
  return request({
    url: 'EventRand/entryUser',
    method: 'post',
    data: data
  });
}

// 是否上链
export function isBaas(params) {
  return request({
    url: 'EventRand/isBaas',
    method: 'GET',
    params: params
  });
}